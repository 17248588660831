import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logoLight from './../../images/logo/logo_reflex_white.svg';
import logo from './../../images/logo/logo_reflex.svg';
import s from './header.module.scss';
import { Link } from 'react-router-dom';
import Icon from '../atoms/icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { locationApi } from 'api/location';

const dataSocial = [
  { href: 'https://linkedin.com/company/pfz-peuker/', icon: 'linkedin' },
  { href: 'tel:+49074619663397', icon: 'phone' },
  { href: 'mailto: info@pfz-cnc.com', icon: 'envelope' },
];
const data = [
  {
    title: 'PRODUCTS & SERVICES',
    href: '#services',
    icon: 'arrow',
    submenu: [
      {
        title: 'Milling process',
        href: '#services',
      },
      {
        title: 'Turned parts',
        href: '#services',
      },
      {
        title: 'Laser cutting',
        href: '#services',
      },
      // {
      //   title: 'Development',
      //   href: '#services',
      // },
    ],
  },
  {
    title: 'Company',
    href: '#about',
    icon: 'arrow',
    submenu: [
      {
        title: 'History',
        href: '#about',
      },
      // {
      //   title: 'FAQs',
      //   href: '#about',
      // },
    ],
  },
  { title: 'OUR TEAM', href: '#user' },
  { title: 'Quality header', href: '#quality' },
  { title: 'CAREER', href: '' },
  { title: 'Contact', href: '#contact' },
];

const theme = {
  dark: {
    text: s.darkThemeText,
    backGround: s.darkThemeBackground,
    icon: s.darkThemeIcon,
    iconAccent: s.darkThemeIconAccent,
    logo: logoLight,
    menuBackground: s.containerArrowBottomDark,
    textMenu: s.textArrowDark,
    iconMenu: s.iconMenuDark,
    title: s.title,
  },
  light: {
    text: s.lightThemeText,
    backGround: s.lightThemeBackground,
    icon: s.lightThemeIcon,
    iconAccent: s.lightThemeIconAccent,
    logo,
    menuBackground: s.containerArrowBottom,
    textMenu: s.textArrow,
    iconMenu: s.iconMenu,
    title: s.titleDark,
  },
};

const Header = ({ isOpenMenu, setIsOpenMenu, setIsLogoLoaded }) => {
  const { t, i18n } = useTranslation();
  const [currentTheme, setCurrentTheme] = useState(theme.dark);
  const [scroll, setScroll] = useState(0);
  const [activeEN, setActiveEN] = useState(i18n.language === 'en');
  const [isArrow, setIsArrow] = useState(false);
  const [isArrow1, setIsArrow1] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const closeMenu = () => {
    setIsArrow(false);
    setIsArrow1(false);
    setIsOpenMenu(false);
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const arrowRef = document.getElementById('downArrow');

  useEffect(() => {
    if (window.location.host.toLocaleLowerCase().includes('de')) {
      setActiveEN(false);
    }

    async function getLocalization() {
      const response = await locationApi.get('json/');
      response?.data?.country_code === 'DE' && setActiveEN(false);
    }
    getLocalization();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = 'hidden';
    }
    if (!isOpenMenu) {
      document.body.style.overflow = 'visible';
    }
  }, [isOpenMenu]);

  useEffect(() => {
    if (location.pathname.includes('/order-form')) {
      setCurrentTheme(theme.dark);
    }
    if (!location.pathname.includes('/order-form')) {
      if (
        scroll > arrowRef?.offsetTop - 80 &&
        currentTheme.text === theme.dark.text
      ) {
        setCurrentTheme(theme.light);
      }
      if (
        scroll < arrowRef?.offsetTop - 80 &&
        currentTheme.text === theme.light.text
      ) {
        setCurrentTheme(theme.dark);
      }
    } else setCurrentTheme(theme.light);
  }, [scroll, location.pathname]);

  const onLanguageChange = language => {
    setActiveEN(language === 'en');
  };

  useEffect(() => {
    i18n.changeLanguage(activeEN ? 'en' : 'de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }, [activeEN]);

  const onClick = (e, to) => {
    let screen = '';
    if (window.innerWidth < 833) screen = '_m';
    else if (window.innerWidth < 1300) screen = '_t';

    if (!data.find(item => item.href === to)?.submenu) closeMenu();
    if (e.target.localName !== 'svg') closeMenu();

    if (screen.length > 0) {
      switch (to) {
        case '#services':
          setIsArrow(value => !value);
          setIsArrow1(false);
          break;
        case '#about':
          setIsArrow1(value => !value);
          setIsArrow(false);
          break;
        default:
      }
    }

    if (
      e.target.innerText === 'Milling process' ||
      e.target.innerText === 'Fräsen'
    ) {
      to = '#milling';
      closeMenu();
    } else if (
      e.target.innerText === 'Drehen' ||
      e.target.innerText === 'Turned parts'
    ) {
      to = '#drehteile';
      closeMenu();
    } else if (
      e.target.innerText === 'History' ||
      e.target.innerText === 'Geschichte'
    ) {
      to = '#history';
      closeMenu();
    } else if (e.target.innerText === 'FAQs') {
      to = '#team';
      closeMenu();
    } else if (
      e.target.innerText === 'Laser cutting' ||
      e.target.innerText === 'Laserschneiden'
    ) {
      to = '#laser';
      closeMenu();
    }

    to = to.concat(screen);

    if (
      location.pathname.includes('order-form') ||
      location.pathname.includes('policy') ||
      location.pathname.includes('career')
    ) {
      navigate('/');
    }
    setTimeout(
      () =>
        e.target.localName !== 'svg' &&
        !!document.querySelector(to) &&
        document.querySelector(to).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        }),
      500
    );
  };

  return (
    <>
      {/* //////////////   DESKTOP LAYOUT   ////////////////// */}
      <div className={[s.wrapper, currentTheme.backGround].join(' ')}>
        <header className={s.header}>
          <Link className={s.logoWrapper} to="/">
            <img
              onLoad={() => setIsLogoLoaded(true)}
              src={currentTheme.logo}
              alt="logo"
              width={160}
            />
          </Link>
          <nav className={s.linkWrapper}>
            <ul className={s.linkList}>
              {data.map(item => (
                <li className={s.item} key={item.title}>
                  <div
                    className={s.link}
                    onClick={e => {
                      item.title !== 'CAREER' && onClick(e, item.href);
                    }}
                  >
                    <div className={`${s.containerTitle} ${currentTheme.text}`}>
                      {item.title !== 'CAREER' ? (
                        <p className={currentTheme.text}>{t(item.title)}</p>
                      ) : (
                        <Link className={currentTheme.text} to="/career">
                          {t(item.title)}
                        </Link>
                      )}
                      {Array.isArray(item.submenu) && (
                        <>
                          <Icon
                            name={item.icon}
                            size={18}
                            className={`${s.iconArrow}`}
                          />
                          <div className={currentTheme.menuBackground}>
                            {item.submenu.map(subItem => (
                              <div className={s.menuItem} key={subItem.title}>
                                <p className={currentTheme.textMenu}>
                                  {t(subItem.title)}
                                </p>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
          <div className={s.headerRightPart}>
            <div className={s.socialWrapper}>
              <a
                href="https://www.linkedin.com/company/pfz-peuker/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.containerIcon}
              >
                <div className={s.containerHidden}>
                  <Icon
                    name="linkedin"
                    size={18}
                    className={[s.iconLinkedin, s.icon, currentTheme.icon].join(
                      ' '
                    )}
                  />
                  <Icon
                    name="linkedin"
                    size={18}
                    className={[
                      s.iconLinkedinAccent,
                      s.iconAccent,
                      currentTheme.iconAccent,
                    ].join(' ')}
                  />
                </div>
              </a>
              <a href="tel: +4974619662999" className={s.containerIcon}>
                <div className={s.numberTel}>
                  <p className={s.numberPhone}>+49 7461 / 966 2 999</p>
                </div>
                <div className={s.containerHidden}>
                  <Icon
                    name="phone"
                    size={16}
                    className={[s.iconPhone, s.icon, currentTheme.icon].join(
                      ' '
                    )}
                  />

                  <Icon
                    name="phone"
                    size={16}
                    className={[
                      s.iconPhoneAccent,
                      s.iconAccent,
                      currentTheme.iconAccent,
                    ].join(' ')}
                  />
                </div>
              </a>
              <a href="mailto: info@pfz-cnc.com" className={s.containerIcon}>
                <div className={s.containerHidden}>
                  <Icon
                    name="envelope"
                    size={18}
                    className={[s.iconMail, s.icon, currentTheme.icon].join(
                      ' '
                    )}
                  />
                  <Icon
                    name="envelope"
                    size={18}
                    className={[
                      s.iconMailAccent,
                      s.iconAccent,
                      currentTheme.iconAccent,
                    ].join(' ')}
                  />
                </div>
              </a>
            </div>

            <div>
              <span
                style={{
                  color: !activeEN
                    ? '#E8A0FF'
                    : currentTheme.text === s.lightThemeText
                    ? '#676767'
                    : '#e6e8ec',
                }}
                className={[s.languageSelector, currentTheme.text].join(' ')}
                onClick={() => onLanguageChange('de')}
              >
                DE
              </span>
              <span
                className={[s.languageSelector, currentTheme.text].join(' ')}
              >
                {' |'}
              </span>
              <span
                style={{
                  color: activeEN
                    ? '#E8A0FF'
                    : currentTheme.text === s.lightThemeText
                    ? '#676767'
                    : '#e6e8ec',
                }}
                className={[s.languageSelector, currentTheme.text].join(' ')}
                onClick={() => onLanguageChange('en')}
              >
                {' '}
                EN
              </span>
            </div>
          </div>
          <div className={s.bottomContainer}>
            <Link
              to="/order-form"
              className={
                currentTheme.text === s.lightThemeText
                  ? s.buttonQuoteAccent
                  : s.buttonQuote
              }
              type="button"
            >
              {t('get quote')}
            </Link>
          </div>
        </header>
      </div>

      {/*         ////////////    MOBILE LAYOUT   ////////////// */}
      <div className={[s.wrapperMobile, currentTheme.backGround].join(' ')}>
        <header className={s.header}>
          <Link
            className={s.logoWrapper}
            to="/"
            style={{ filter: isOpenMenu && 'blur(10px)' }}
          >
            <img
              src={currentTheme.logo}
              alt="logo"
              width={69}
              height={36}
              className={s.logoImg}
            />
          </Link>

          {isOpenMenu && (
            <nav className={s.linkWrapperMenu}>
              <Icon
                name="close-menu"
                size={40}
                className={s.iconClose}
                onClick={() => closeMenu()}
              ></Icon>

              <div className={s.line}></div>
              <ul className={s.linkListMenu}>
                {data.map((item, i) => (
                  <li className={s.item} key={item.title}>
                    <div
                      className={s.link}
                      onClick={e => {
                        item.title === 'CAREER'
                          ? closeMenu()
                          : onClick(e, item.href);
                      }}
                    >
                      <div
                        className={`${s.containerTitle} ${currentTheme.text}`}
                      >
                        {item.title !== 'CAREER' ? (
                          <p className={s.title}>{t(item.title)}</p>
                        ) : (
                          <Link className={s.title} to="/career">
                            {t(item.title)}
                          </Link>
                        )}
                      </div>
                      {item.icon && (
                        <>
                          {((!isArrow && i === 0) ||
                            (!isArrow1 && i === 1)) && (
                            <Icon
                              name={item.icon}
                              size={25}
                              className={`${s.iconArrowMenu}`}
                            />
                          )}
                          {((isArrow && i === 0) || (isArrow1 && i === 1)) && (
                            <Icon
                              name={item.icon}
                              size={25}
                              className={s.iconArrowMenuTop}
                            />
                          )}

                          {((isArrow && i === 0) || (isArrow1 && i === 1)) && (
                            <div className={s.menuBackground}>
                              {item.submenu.map(subItem => (
                                <div className={s.menuItem} key={subItem.title}>
                                  <p className={s.textMenu}>
                                    {t(subItem.title)}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <div className={s.bottomContainerAccent}>
                <div
                  className={
                    currentTheme.text === s.lightThemeText
                      ? s.buttonQuoteAccent
                      : s.buttonQuote
                  }
                  onClick={e => onClick(e, '#contact')}
                >
                  {t('get quote')}
                </div>
              </div>

              <ul className={s.linkListSocial}>
                {dataSocial.map(item => (
                  <li className={s.itemSocial} key={item.id}>
                    <a
                      className={s.linkSocial}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {<Icon name={item.icon} size={18} className={s.icon} />}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          )}

          <div className={s.containerMenu}>
            <div style={{ filter: isOpenMenu && 'blur(10px)' }}>
              <span
                style={{
                  color: !activeEN
                    ? '#E8A0FF'
                    : currentTheme.text === s.lightThemeText
                    ? '#676767'
                    : '#e6e8ec',
                }}
                className={[s.languageSelector, currentTheme.text].join(' ')}
                onClick={() => onLanguageChange('de')}
              >
                DE
              </span>
              <span
                className={[s.languageSelector, currentTheme.text].join(' ')}
              >
                {' |'}
              </span>
              <span
                style={{
                  color: activeEN
                    ? '#E8A0FF'
                    : currentTheme.text === s.lightThemeText
                    ? '#676767'
                    : '#e6e8ec',
                }}
                className={[s.languageSelector, currentTheme.text].join(' ')}
                onClick={() => onLanguageChange('en')}
              >
                {' '}
                EN
              </span>
            </div>
            {!isOpenMenu && (
              <div className={s.socialWrapper}>
                <a
                  href="https://www.linkedin.com/company/pfz-peuker/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.containerIcon}
                >
                  <div className={s.containerHidden}>
                    <Icon
                      name="linkedin"
                      size={18}
                      className={[
                        s.iconLinkedin,
                        s.icon,
                        currentTheme.icon,
                      ].join(' ')}
                    />
                    <Icon
                      name="linkedin"
                      size={18}
                      className={[
                        s.iconLinkedinAccent,
                        s.iconAccent,
                        currentTheme.iconAccent,
                      ].join(' ')}
                    />
                  </div>
                </a>
                <a href="tel: +4974619662999" className={s.containerIcon}>
                  <div className={s.numberTel}></div>
                  <div className={s.containerHidden}>
                    <Icon
                      name="phone"
                      size={16}
                      className={[s.iconPhone, s.icon, currentTheme.icon].join(
                        ' '
                      )}
                    />

                    <Icon
                      name="phone"
                      size={16}
                      className={[
                        s.iconPhoneAccent,
                        s.iconAccent,
                        currentTheme.iconAccent,
                      ].join(' ')}
                    />
                  </div>
                </a>
                <a href="mailto: info@pfz-cnc.com" className={s.containerIcon}>
                  <div className={s.containerHidden}>
                    <Icon
                      name="envelope"
                      size={18}
                      className={[s.iconMail, s.icon, currentTheme.icon].join(
                        ' '
                      )}
                    />
                    <Icon
                      name="envelope"
                      size={18}
                      className={[
                        s.iconMailAccent,
                        s.iconAccent,
                        currentTheme.iconAccent,
                      ].join(' ')}
                    />
                  </div>
                </a>
              </div>
            )}
            {!isOpenMenu && (
              <Icon
                className={currentTheme.iconMenu}
                onClick={() => {
                  setIsOpenMenu(true);
                }}
                name="menu"
                size={40}
              >
                {' '}
              </Icon>
            )}
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
