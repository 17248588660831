import Footer from '../footer';
import BackToBottomButton from '../backToBottomButton/backToBottomButton';
import CareerDescription from 'components/careerDescription';

export const CareerDescriptionPage = ({ isOpenMenu, data }) => (
  <>
    <CareerDescription isOpenMenu={isOpenMenu} data={data} />
    <BackToBottomButton />
  </>
);
