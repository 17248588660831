import React, { useState } from 'react';

import s from './cookies.module.scss';
import Icon from 'components/atoms/icon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Cookies = ({ setIsVisibleCookies, cookies }) => {
  const [isActiveCheckboxCookie, setIsActiveCheckboxCookie] = useState(
    cookies || false
  );
  const [isSettingsBlockVisible, setIsSettingsBlockVisible] = useState(false);
  const [isAboutActive, setIsAboutActive] = useState(false);
  const [isDeclarationActive, setIsDeclarationActive] = useState(true);
  const [isImportant, setIsImportant] = useState(true);
  const [isOptional, setIsOptional] = useState(false);

  const { t, i18n } = useTranslation();

  return (
    <div className={s.modalCookiesBackdrop}>
      <div className={s.modalCookies}>
        <div className={s.modalCookiesContent}>
          <h2 className={s.modalCookiesTitle}>
            {t('This website uses cookies.')}
          </h2>
          <p className={s.modalCookiesDescription}>
            {t('See more information below.')}
          </p>
          <div className={s.modalCookiesContainerCheckbox}>
            <div className={s.modalCookiesContainerCheckboxLeft}>
              <Icon name="active-disabled-checkbox" />
              <p className={s.modalCheckboxText}>{t('Essential')}</p>
            </div>
            <div className={s.modalCookiesContainerCheckboxLeft}>
              {!isActiveCheckboxCookie && (
                <Icon
                  name="cookies-checkbox"
                  onClick={() => {
                    setIsActiveCheckboxCookie(true);
                  }}
                />
              )}
              {isActiveCheckboxCookie && (
                <Icon
                  name="cookies-full-checkbox"
                  onClick={() => {
                    setIsActiveCheckboxCookie(false);
                  }}
                />
              )}
              <p className={s.modalCheckboxText}>{t('Marketing')}</p>
            </div>
          </div>

          <div className={s.containerCookiesButton}>
            {!isActiveCheckboxCookie ? (
              <button
                className={s.cookiesButtonLeft}
                onClick={() => {
                  setIsVisibleCookies(false);
                  localStorage.setItem(
                    'cookies',
                    JSON.stringify({
                      isShow: true,
                      agree: isActiveCheckboxCookie,
                    })
                  );
                }}
              >
                {t('SAVE AND CLOSE')}
              </button>
            ) : (
              <button
                className={s.cookiesButtonLeft}
                onClick={() => {
                  setIsVisibleCookies(false);
                  localStorage.setItem(
                    'cookies',
                    JSON.stringify({
                      isShow: true,
                      agree: isActiveCheckboxCookie,
                    })
                  );
                }}
              >
                {t('AGREE TO ALL')}
              </button>
            )}
            <button
              className={s.cookiesButtonRight}
              onClick={() => {
                setIsVisibleCookies(false);
                localStorage.setItem(
                  'cookies',
                  JSON.stringify({
                    isShow: true,
                    agree: isActiveCheckboxCookie,
                  })
                );
              }}
            >
              {t('REJECT ALL')}
            </button>
          </div>
          <div
            className={s.containerCookiesSettings}
            onClick={() => setIsSettingsBlockVisible(!isSettingsBlockVisible)}
          >
            <Icon name="settings1" className={s.iconSettings} />
            <p className={s.textCookiesSettings}>
              {isSettingsBlockVisible
                ? t('CLOSE CONFIGURATION')
                : t('CONFIGURATION')}
            </p>
          </div>
          {isSettingsBlockVisible && (
            <div className={s.settingsBlock}>
              <button
                className={
                  isDeclarationActive
                    ? s.cookiesDeclarationActive
                    : s.cookiesDeclaration
                }
                onClick={() => {
                  setIsDeclarationActive(true);
                  setIsAboutActive(false);
                }}
              >
                {t('COOKIE DECLARATION')}
              </button>
              <button
                className={
                  isAboutActive ? s.aboutCookiesActive : s.aboutCookies
                }
                onClick={() => {
                  setIsDeclarationActive(false);
                  setIsAboutActive(true);
                }}
              >
                {t('About Cookies')}
              </button>
              {isDeclarationActive && (
                <div className={s.containerCookiesDeclaration}>
                  <button
                    className={
                      isImportant ? s.buttonImportantActive : s.buttonImportant
                    }
                    onClick={() => {
                      setIsImportant(true);
                      setIsOptional(false);
                    }}
                  >
                    {t('Essential')}
                  </button>
                  <button
                    className={
                      isOptional ? s.buttonOptionalActive : s.buttonOptional
                    }
                    onClick={() => {
                      setIsImportant(false);
                      setIsOptional(true);
                    }}
                  >
                    {t('Marketing')}
                  </button>
                </div>
              )}
              {isImportant && !isAboutActive && (
                <>
                  <p className={s.textAccentWithPadding}>
                    {t(
                      'Strictly necessary cookies allow core website functionality such as user login and account management. The website cannot be used properly without strictly necessary cookies.'
                    )}
                  </p>
                  <div className={s.containerCookiesAccent}>
                    <p className={s.blockName}>{t('Name')}</p>
                    <p className={s.blockDomain}>{t('Domain')}</p>
                    <p className={s.blockExpiration}>{t('Expiration')}</p>
                    <p className={s.blockDescription}>{t('Description')}</p>
                  </div>
                  <div className={s.containerCookiesAccentDescription}>
                    <p className={s.blockNameDescription}>
                      {t('CookieScriptConsent')}
                    </p>
                    <p className={s.blockDomainDescription}>
                      .pfz-cnc.{i18n.language === 'en' ? 'com' : 'de'}
                    </p>
                    <p className={s.blockExpirationDescription}>
                      {t('1 month')}
                    </p>
                    <p className={s.blockDescriptionDescription}>
                      {t(
                        'This cookie is used by Cookie-Script.com service to remember visitor cookie consent preferences. It is necessary for Cookie-Script.com cookie banner to work properly.'
                      )}
                    </p>
                  </div>
                </>
              )}
              {isOptional && !isAboutActive && (
                <>
                  <p className={s.textAccentWithPadding}>
                    {t(
                      'Strictly necessary cookies allow core website functionality such as user login and account management. The website cannot be used properly without strictly necessary cookies.'
                    )}
                  </p>
                  <div className={s.containerCookiesAccent}>
                    <p className={s.blockName}>{t('Name')}</p>
                    <p className={s.blockDomain}>{t('Domain')}</p>
                    <p className={s.blockExpiration}>{t('Expiration')}</p>
                    <p className={s.blockDescription}>{t('Description')}</p>
                  </div>
                  <div className={s.containerCookiesAccentDescription}>
                    <p className={s.blockNameDescription}>
                      {t('fe_typo_user')}
                    </p>
                    <p className={s.blockDomainDescription}>
                      .pfz-cnc.{i18n.language === 'en' ? 'com' : 'de'}
                    </p>
                    <p className={s.blockExpirationDescription}>
                      {t('Session')}
                    </p>
                    <p className={s.blockDescriptionDescription}>
                      {t(
                        'This cookie name is associated with the Typo3 web content management system. It is generally used as a user session identifier to enable user preferences to be stored, but in many cases it may not actually be needed as it can be set by defualt by the platform, though this can be prevented by site administrators. In most cases it is set to be destroyed at the end of a browser session. It contains a random identifier rather than any specific user data.'
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          {isAboutActive && isSettingsBlockVisible && (
            <div className={s.containerAboutContent}>
              <p className={s.containerAboutContentText}>
                {t(
                  'Cookies are small text files that are placed on your computer by websites that you visit. Websites use cookies to help users navigate efficiently and perform certain functions. Cookies that are required for the website to operate properly are allowed to be set without your permission. All other cookies need to be approved before they can be set in the browser.'
                )}
              </p>
              <p className={s.containerAboutContentText}>
                {t(
                  'You can change your consent to cookie usage at any time on our'
                )}{' '}
                <Link to="/policy" className={s.textConfirmAccent}>
                  {t('Privacy Policy')}
                </Link>{' '}
                {t('page.')}
              </p>
            </div>
          )}
          <p className={s.textBottom}>
            {t('Cookie report created on 2020-07-29 by Cookie-Script')}
          </p>
        </div>
      </div>
    </div>
  );
};
