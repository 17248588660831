import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import s from './modal.module.scss';
import YouTube from 'react-youtube';
import Icon from 'components/atoms/icon';

const Modal = ({ id, setIsOpen, isOpen }) => {
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const handleKeyDown = e => {
    if (e.code === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const container = document.getElementById('modal-container');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    if (!isOpen) {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  // ReactDOM.render(
  //   <>
  //     {isOpen && (
  //       <div className={s.container}>
  //         <button
  //           className={s.button}
  //           onClick={() => {
  //             setIsOpen(false);
  //           }}
  //         >
  //           <Icon name="close" size={35} className={s.icon} />
  //         </button>
  //         <div className={s.videoContainer}>
  //           <div className={s.player}>
  //             <YouTube videoId={id} opts={opts} />
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>,
  //   container
  // );

  return <></>;
};

export default Modal;
