import React from 'react';
import styled from '@emotion/styled';
import background from './../../../images/hero/section1item.png';

const HeroItem = ({
  children,
  width,
  visible,
  href,
  height = 36,
  paddingLeft = 48,
  marginLeft = -48,
}) => (
  <Container
    background={background}
    visible={visible}
    height={height}
    paddingLeft={paddingLeft}
    marginLeft={marginLeft}
  >
    <a href={href}>
      <ContentWrapper>{children}</ContentWrapper>
    </a>
  </Container>
);

export default HeroItem;

const Container = styled.div`
  height: ${({ height }) => height}px;
  background-repeat: no-repeat;
  background-size: 100% 12px;
  width: fit-content;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  padding-right: 48px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  background-position: bottom;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms linear;
  background-image: url(${({ background }) => background});

  :not(:last-of-type) {
    margin-bottom: 25px;
  }
  &:hover h2 {
    color: var(--accent_color);
    transform: translateX(40px);
  }
`;

const ContentWrapper = styled.div``;
