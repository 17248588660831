import react, { useState, useEffect, useRef } from 'react';
import s from './turnedParts.module.scss';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import { Flex } from '@react-yuki/ui';
import boneReamer from '../../images/turnedPart/boneReamer.jpg';
import boneDrill from '../../images/turnedPart/boneDrill.jpg';
import threadCutter from '../../images/turnedPart/threadCutter.jpg';
import reamers from '../../images/turnedPart/reamers.jpg';
import boneScrews from '../../images/turnedPart/boneScrews.jpg';
import Title from 'components/molecules/title/Title';
import Icon from 'components/atoms/icon';

const data = [
  {
    name: 'Bone drill / Sinker',
    img: boneDrill,
    description:
      'The highest quality orthopaedic bone handling instruments are made individually according to the needs of the customers.',
  },
  {
    name: 'Thread cutter',
    img: threadCutter,
    description:
      'Orthopaedic bone drilling tools are manufactured individually according to customer requirements. Bone taps can be used in diameters from 1.0 mm to 20 mm.',
  },
  {
    name: 'Reamers',
    img: reamers,
    description:
      'Optimal orthopedic reamers are developed and / or manufactured for bone machining. Both rigid reamers for medical technology and flexible orthopedic reamers can be manufactured.',
  },
  {
    name: 'Bone reamer / Rose reamer',
    img: boneReamer,
    description:
      'We manufacture carbide tools for minimally invasive treatments of the jaw bone and soft tissues. The sharp cutting edges ensure precise and safe work with low contact pressure.',
  },
  {
    name: 'Bone screws / Distraction screws',
    img: boneScrews,
    description:
      'All types of orthopedic bone screws are manufactured. In addition to cannulated compression screws, we can manufacture cannulated medical screws for all applications.',
  },
];
const source = {
  title: {
    desktop: {
      svg1: 'turnedparts-titlevector2',
      svg2: 'turnedparts-titlevector1',
      svg3: 'turnedparts-titlevector3',
    },
    tablet: {
      svg1: 'turnedparts-tablet-titlevector2',
      svg2: 'turnedparts-tablet-titlevector1',
      svg3: 'turnedparts-tablet-titlevector3',
    },
    mobile: {
      svg1: 'turnedparts-mobile-titlevector2',
      svg2: 'turnedparts-mobile-titlevector1',
      svg3: 'turnedparts-mobile-titlevector3',
    },
  },
};

const SlideContainer = props => (
  <Flex
    {...props}
    __css={{
      position: 'relative',
      '.swiper-container': {
        width: '1240px',
        height: '20rem',
        marginTop: '50px',
      },
    }}
  />
);

const TurnedParts = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.container}>
        <div className={s.containerWrapper}>
          <div className={s.linkId} id="drehteile"></div>
          <Title
            svg1={source.title.desktop.svg1}
            svg2={source.title.desktop.svg2}
            svg3={source.title.desktop.svg3}
          >
            <h2 className={s.title}>{t('Turned parts')}</h2>
          </Title>
        </div>

        <CoverflowEffect />
      </div>

      <div className={s.containerMobile}>
        <div className={s.containerWrapper}>
          <div className={s.linkId} id="drehteile_m"></div>
          <Title
            svg1={source.title.mobile.svg1}
            svg2={source.title.mobile.svg3}
            svg3={source.title.mobile.svg2}
            top1={16}
            top2={30}
          >
            <h2 className={s.title}>{t('Turned parts')}</h2>
          </Title>
        </div>

        <CoverflowEffect />
      </div>

      <div className={s.containerTablet}>
        <div className={s.containerWrapper}>
          <div className={s.linkId} id="drehteile_t"></div>
          <Title
            svg1={source.title.tablet.svg2}
            svg2={source.title.tablet.svg1}
            svg3={source.title.tablet.svg3}
            top1={27}
            top2={44}
          >
            <h2 className={s.title}>{t('Turned parts')}</h2>
          </Title>
        </div>

        <CoverflowEffect />
      </div>
    </>
  );
};

export default TurnedParts;

const CoverflowEffect = () => {
  const swiperRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window?.screen?.width);
  let [isHover, setIsHover] = useState(false);

  const getWindowWidth = e => {
    setWindowWidth(e?.currentTarget?.screen?.width);
  };

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth);
    return () => window.removeEventListener('resize', getWindowWidth);
  }, []);

  const params = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: '4',
    loop: true,
    simulateTouch: true,
    slideToClickedSlide: true,
    rebuildOnUpdate: true,
    speed: 200,
    autoplay: { delay: isHover ? 20000 : 2000 },

    coverflowEffect: {
      rotate: 45,
      stretch: -10,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };

  return (
    <div className={s.wrapper}>
      <div className={s.wrapper1}>
        <div
          className={s.wrapper2}
          onMouseOver={() => {
            swiperRef.current.swiper.autoplay.pause();
          }}
          onMouseLeave={() => {
            swiperRef.current.swiper.autoplay.run();
          }}
        >
          <SlideContainer>
            <Swiper {...params} ref={swiperRef}>
              {data.map(item => (
                <SlideItem item={item} key={item.name} />
              ))}
            </Swiper>
          </SlideContainer>
        </div>
      </div>
    </div>
  );
};

const SlideItem = ({ item }) => {
  const [windowWidth, setWindowWidth] = useState(window?.screen?.width);
  const [activeClass, setActiveClass] = useState(false);

  const getWindowWidth = e => {
    setWindowWidth(e?.currentTarget?.screen?.width);
  };

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth);
    return () => window.removeEventListener('resize', getWindowWidth);
  }, []);
  const { t } = useTranslation();

  return (
    <div
      className={`swiper-slide ${s.cardItem}`}
      onClick={() => {
        setActiveClass(!activeClass);
      }}
    >
      <img className={s.cardImages} src={item.img} alt={item.name} />
      <div className={activeClass ? s.cardTextActive : s.cardText}>
        <h2 className={activeClass ? s.cardTitleActive : s.cardTitle}>
          {t(item.name)}
        </h2>
        <p
          className={activeClass ? s.cardDescriptionActive : s.cardDescription}
        >
          {t(item.description)}
        </p>

        {windowWidth < 1300 && (
          <div className={s.wrapperButton}>
            <Icon
              name="back-servicesMobile"
              width={180}
              height={34}
              className={s.iconButtonHover}
            ></Icon>

            <p className={s.link1}>{t('- More information -')}</p>
          </div>
        )}
      </div>
    </div>
  );
};
