import { useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon';
import dataMaterials from './materials';

import s from '../../orderForm/orderForm.module.scss';

export const MaterialSelect = ({ hidden }) => {
  const { t } = useTranslation();

  const [inputMaterialVisible, setInputMaterialVisible] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState('');

  const handleSetMaterial = material => {
    setInputMaterialVisible(false);
  };

  return (
    <>
      {!hidden && (
        <div className={s.containerMaterial}>
          <p className={s.containerAccentText}>{t('Material')}</p>
          <ClickAwayListener onClickAway={() => setInputMaterialVisible(false)}>
            <div className={s.wrapperInputText}>
              <input
                autoComplete="off"
                className={[
                  s.inputText,
                  inputMaterialVisible && s.inputTextMaterials,
                ].join(' ')}
                type="text"
                //{...register(FIELDS.material)}
              />
              {!inputMaterialVisible && (
                <Icon
                  size={11}
                  name="down"
                  onClick={() => setInputMaterialVisible(val => !val)}
                  className={s.iconArrowQuantity}
                ></Icon>
              )}
              {inputMaterialVisible && (
                <Icon
                  size={11}
                  name="down"
                  onClick={() => setInputMaterialVisible(val => !val)}
                  className={s.iconArrowQuantityUp}
                ></Icon>
              )}
              {inputMaterialVisible && (
                <ul className={s.containerOptionMaterialsCommon}>
                  {Object.keys(dataMaterials).map(item => (
                    <li
                      key={item}
                      className={[
                        s.selectMaterialsCategory,
                        item === selectedMaterial &&
                          s.selectMaterialsCategoryActive,
                      ].join(' ')}
                      onClick={() =>
                        setSelectedMaterial(val =>
                          val && val === item ? '' : item
                        )
                      }
                    >
                      {item !== selectedMaterial && (
                        <Icon
                          size={11}
                          name="down"
                          className={s.iconArrow}
                        ></Icon>
                      )}
                      {item === selectedMaterial ? (
                        <>
                          <div>
                            {selectedMaterial && (
                              <Icon
                                size={11}
                                name="down"
                                className={s.iconArrowUp}
                              ></Icon>
                            )}
                          </div>
                          <ul className={s.containerOptionMaterials}>
                            {dataMaterials[item].map(material => (
                              <li
                                key={material}
                                className={[
                                  s.selectMaterials,
                                  item === selectedMaterial &&
                                    s.selectMaterialsActive,
                                ].join(' ')}
                                onClick={() => handleSetMaterial(material)}
                              >
                                {material}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        item
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};
