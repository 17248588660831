import s from './backToTopButton.module.scss';
import Icon from '../atoms/icon';
import { useEffect, useState } from 'react';

const BackToTopButton = () => {
  const [scroll, setScroll] = useState(0);

  const onClickToTop = () => {
    window.scrollTo({
      top: '0px',
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {scroll > 500 && (
        <div className={s.wrapper} onClick={onClickToTop}>
          <Icon name="up" className={s.icon} />
        </div>
      )}
    </>
  );
};

export default BackToTopButton;
