import react from 'react';
import s from './milling.module.scss';
import front1 from '../../images/milling/frontend1.png';
import back1 from '../../images/milling/backend1.png';
import front2 from '../../images/milling/frontend2.png';
import back2 from '../../images/milling/backend2.png';
import front3 from '../../images/milling/frontend3.png';
import back3 from '../../images/milling/backend3.png';
import front1Webp from '../../images/milling/frontend1.webp';
import back1Webp from '../../images/milling/backend1.webp';
import front2Webp from '../../images/milling/frontend2.webp';
import back2Webp from '../../images/milling/backend2.webp';
import front3Webp from '../../images/milling/frontend3.webp';
import back3Webp from '../../images/milling/backend3.webp';
import { useTranslation } from 'react-i18next';
import Title from 'components/molecules/title/Title';
import Icon from 'components/atoms/icon';
import { useState } from 'react';

const data = [
  {
    img1: front1,
    img2: back1,
    img1Webp: front1Webp,
    img2Webp: back1Webp,
    href: '#machinery',
    title: 'Machinery',
    text: 'Consists of modern CNC machining centers.',
  },
  {
    img1: front2,
    img2: back2,
    img1Webp: front2Webp,
    img2Webp: back2Webp,
    href: '#partsSpectrum',
    title: 'Part spectrum',
    text: 'PFZ GmbH & Co. KG manufactures your individual product, whether turned part - micro turned part, milled part, micro-milled part or assembly - precision mechanical components or medical - orthopedic instruments (see below for some of them)',
  },
  {
    img1: front3,
    img2: back3,
    img1Webp: front3Webp,
    img2Webp: back3Webp,
    href: '#materials',
    title: 'Materials',
    text: 'Our high-performance materials are quality-certified',
  },
];
const dataMobile = [
  {
    img1: front1,
    img2: back1,
    img1Webp: front1Webp,
    img2Webp: back1Webp,
    href: '#machinery_m',
    title: 'Machinery',
    text: 'Consists of modern CNC machining centers.',
  },
  {
    img1: front2,
    img2: back2,
    img1Webp: front2Webp,
    img2Webp: back2Webp,
    href: '#partsSpectrum_m',
    title: 'Part spectrum',
    text: 'PFZ GmbH & Co. KG manufactures your individual product, whether turned part - micro turned part, milled part, micro-milled part or assembly - precision mechanical components or medical - orthopedic instruments (see below for some of them)',
  },
  {
    img1: front3,
    img2: back3,
    img1Webp: front3Webp,
    img2Webp: back3Webp,
    href: '#materials_m',
    title: 'Materials',
    text: 'Our high-performance materials are quality-certified',
  },
];
const dataTablet = [
  {
    img1: front1,
    img2: back1,
    img1Webp: front1Webp,
    img2Webp: back1Webp,
    href: '#machinery_t',
    title: 'Machinery',
    text: 'Consists of modern CNC machining centers.',
  },
  {
    img1: front2,
    img2: back2,
    img1Webp: front2Webp,
    img2Webp: back2Webp,
    href: '#partsSpectrum_t',
    title: 'Part spectrum',
    text: 'PFZ GmbH & Co. KG manufactures your individual product, whether turned part - micro turned part, milled part, micro-milled part or assembly - precision mechanical components or medical - orthopedic instruments (see below for some of them)',
  },
  {
    img1: front3,
    img2: back3,
    img1Webp: front3Webp,
    img2Webp: back3Webp,
    href: '#materials_t',
    title: 'Materials',
    text: 'Our high-performance materials are quality-certified',
  },
];

const source = {
  title: {
    desktop: {
      svg1: 'milling-titlevector2',
      svg2: 'milling-titlevector1',
      svg3: 'milling-titlevector3',
    },
    tablet: {
      svg1: 'milling-title-tablet1',
      svg2: 'milling-title-tablet2',
      svg3: 'milling-title-tablet3',
    },
    mobile: {
      svg1: 'milling-title-mobile2',
      svg2: 'milling-title-mobile1',
      svg3: 'milling-title-mobile3',
    },
  },
};

const Milling = () => {
  const [isBack, setIsBack] = useState(-1);
  const { t } = useTranslation();
  return (
    <>
      <div className={s.containerMilling}>
        <div id="milling"></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.desktop.svg1}
            svg2={source.title.desktop.svg2}
            svg3={source.title.desktop.svg3}
          >
            <h2 className={s.title}>{t('Milling')}</h2>
          </Title>
        </div>
        <div className={s.containerText}>
          <p className={s.description}>
            {t(
              'For our customers, we process materials such as steel, aluminum alloys, titanium, plastics, and other materials into milled parts, turned parts, and instruments'
            )}
          </p>

          <div className={s.line}></div>
        </div>
        <ul className={s.flipContainer}>
          {data.map((item, i) => (
            <a href={item.href} key={item.title}>
              <li className={s.card} key={item.title}>
                <div className={s.flipper}>
                  <div className={s.front}>
                    <picture>
                      <source srcSet={item.img1Webp} type="image/webp" />
                      <img className={s.img} src={item.img1} alt="front1" />
                    </picture>
                    <div className={s.containerFrontText}>
                      <p className={s.titleText}>{t(item.title)}</p>
                    </div>
                  </div>
                  <div className={s.back}>
                    <picture>
                      <source srcSet={item.img2Webp} type="image/webp" />
                      <img className={s.img} src={item.img2} alt="back1" />
                    </picture>
                    <div className={s.containerBackText}>
                      <p className={s.titleText}>{t(item.title)}</p>
                      <p className={s.text}> {t(item.text)}</p>
                    </div>
                  </div>
                </div>
              </li>
            </a>
          ))}
        </ul>
        {/* <div className={s.containerTextAccent}>
          <p className={s.description}>
            {t(
              'PFZ GmbH & Co. KG manufactures your individual product, whether turned part - micro turned part, milled part, micro-milled part or assembly - precision mechanical components or medical - orthopedic instruments (see below for some of them)'
            )}
          </p>
          <div className={s.line}></div>
        </div> */}
      </div>
      <div className={s.containerMillingMobile}>
        <div id="milling_m"></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.mobile.svg1}
            svg2={source.title.mobile.svg3}
            svg3={source.title.mobile.svg2}
            top2={31}
            top1={18}
          >
            <h2 className={s.title}>{t('Milling')}</h2>
          </Title>
        </div>
        <div className={s.containerText}>
          <p className={s.description}>
            {t(
              'For our customers, we process materials such as steel, aluminum alloys, titanium, plastics, and other materials into milled parts, turned parts, and instruments'
            )}
          </p>

          <div className={s.line}></div>
        </div>
        <ul className={s.flipContainer}>
          {dataMobile.map((item, i) => (
            <li
              className={s.card}
              key={item.title}
              onClick={() => {
                setIsBack(isBack >= 0 ? -1 : i);
              }}
            >
              <div className={`${s.flipper1} ${isBack === i && s.flip}`}>
                <div className={s.front}>
                  <picture>
                    <source srcSet={item.img1Webp} type="image/webp" />
                    <img className={s.img} src={item.img1} alt="front1" />
                  </picture>
                  <div className={s.containerFrontText}>
                    <p className={s.titleText}>{t(item.title)}</p>
                  </div>
                  <a href={item.href}>
                    <div className={s.wrapperButton}>
                      <Icon
                        name="back-servicesMobile"
                        width={180}
                        height={34}
                        className={s.iconButtonHover}
                      ></Icon>

                      <p className={s.link1}>{t('- More information -')}</p>
                    </div>
                  </a>
                </div>

                <div className={s.back}>
                  <picture>
                    <source srcSet={item.img2Webp} type="image/webp" />
                    <img className={s.img} src={item.img2} alt="back1" />
                  </picture>
                  <div className={s.containerBackText}>
                    <p className={s.titleText}>{t(item.title)}</p>
                    <p className={s.text}> {t(item.text)}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {/* <div className={s.containerTextAccent}>
          <p className={s.description}>
            {t(
              'For our customers, we process materials such as steel, aluminum alloys, titanium, plastics, and other materials into milled parts, turned parts, and instruments'
            )}
          </p>

          <div className={s.line}></div>
        </div> */}
      </div>
      <div className={s.containerMillingTablet}>
        <div id="milling_t"></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.tablet.svg2}
            svg2={source.title.tablet.svg1}
            svg3={source.title.tablet.svg3}
            top2={45}
            top1={29}
          >
            <h2 className={s.title}>{t('Milling')}</h2>
          </Title>
        </div>
        <div className={s.containerText}>
          <p className={s.description}>
            {t(
              'For our customers, we process materials such as steel, aluminum alloys, titanium, plastics, and other materials into milled parts, turned parts, and instruments'
            )}
          </p>

          <div className={s.line}></div>
        </div>
        <ul className={s.flipContainer}>
          {dataTablet.map((item, i) => (
            // <a href={item.href} key={item.title}>
            <li
              className={s.card}
              key={item.title}
              onClick={() => {
                setIsBack(isBack >= 0 ? -1 : i);
              }}
            >
              <div className={`${s.flipper1} ${isBack === i && s.flip}`}>
                <div className={s.front}>
                  <picture>
                    <source srcSet={item.img1Webp} type="image/webp" />
                    <img
                      className={s.img}
                      src={item.img1}
                      alt="front1"
                      width={250}
                    />
                  </picture>
                  <div className={s.containerFrontText}>
                    <p className={s.titleText}>{t(item.title)}</p>
                  </div>
                  <a href={item.href}>
                    <div className={s.wrapperButton}>
                      <Icon
                        name="back-servicesMobile"
                        width={180}
                        height={34}
                        className={s.iconButtonHover}
                      ></Icon>

                      <p className={s.link1}>{t('- More information -')}</p>
                    </div>
                  </a>
                </div>
                <div className={s.back}>
                  <picture>
                    <source srcSet={item.img2Webp} type="image/webp" />
                    <img className={s.img} src={item.img2} alt="back1" />
                  </picture>
                  <div className={s.containerBackText}>
                    <p className={s.titleText}>{t(item.title)}</p>
                    <p className={s.text}> {t(item.text)}</p>
                  </div>
                </div>
              </div>
            </li>
            // </a>
          ))}
        </ul>
        {/* <div className={s.containerTextAccent}>
          <p className={s.description}>
            {t(
              'For our customers, we process materials such as steel, aluminum alloys, titanium, plastics, and other materials into milled parts, turned parts, and instruments'
            )}
          </p>

          <div className={s.line}></div>
        </div> */}
      </div>
    </>
  );
};

export default Milling;
