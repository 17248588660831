import s from './backToBottomButton.module.scss';
import Icon from '../atoms/icon';

const BackToBottomButton = () => {
  const onClickToTop = () => {
    window.scrollTo({
      top: '380000',
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className={s.wrapper} onClick={onClickToTop}>
        <Icon name="up" className={s.icon} />
      </div>
    </>
  );
};

export default BackToBottomButton;
