import react from 'react';
import s from './career.module.scss';
import styled from '@emotion/styled';
import Title from 'components/molecules/title/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from 'components/footer';

import { SVG, ids } from 'components/atoms/svg/svg';

import { ReactComponent as LeftTopSVG } from '../../images/orderForm/leftTop.svg';
import { ReactComponent as LeftBottomSVG } from '../../images/orderForm/leftBottom.svg';
import { ReactComponent as RightTopSVG } from '../../images/orderForm/rightTop.svg';
import { ReactComponent as RightBottomSVG } from '../../images/orderForm/rightBottom.svg';

const source = {
  title: {
    desktop: {
      svg1: 'policy-desktop-titlevector3',
      svg2: 'policy-desktop-titlevector2',
      svg3: 'policy-desktop-titlevector1',
    },
    tablet: {
      svg1: 'policy-tablet-titlevector2',
      svg2: 'policy-tablet-titlevector1',
      svg3: 'policy-tablet-titlevector3',
    },
    mobile: {
      svg1: 'policy-mobile-titlevector2',
      svg2: 'policy-mobile-titlevector3',
      svg3: 'policy-mobile-titlevector1',
    },
  },
};

const Career = ({ isOpenMenu, data }) => {
  const { t, i18n } = useTranslation();

  const isEnglishSelected = i18n.language === 'en';
  const navigate = useNavigate();

  return (
    <div style={{ filter: isOpenMenu && 'blur(10px)' }}>
      <div className={s.container}>
        <LeftTopSVG className={s.leftTopSvg} />
        <LeftBottomSVG className={s.leftBottomSvg} />
        <RightTopSVG className={s.rightTopSvg} />
        <RightBottomSVG className={s.rightBottomSvg} />
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.desktop.svg2}
              svg2={source.title.desktop.svg1}
              svg3={source.title.desktop.svg3}
              animate={true}
            >
              <h2 className={s.title}>{t('Career')}</h2>
            </Title>
          </div>

          <ul className={s.listPosition}>
            {data &&
              data
                .filter(item => item.isVisible)
                .map(position => (
                  <li
                    key={position.title}
                    className={s.item}
                    onClick={() => {
                      navigate(`/career-description?${position.id}`);
                    }}
                  >
                    <StyledSVG
                      id={ids['vacancy-item']}
                      opacity={1}
                      top={0}
                      left={0}
                      translate={0}
                    />
                    <div className={s.backgroundItem}>
                      <p className={s.descriptionPosition}>
                        {isEnglishSelected
                          ? t(position.title)
                          : t(position.title_de)}
                      </p>
                      <div className={s.flexContainer}>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description)
                            : t(position.description_de)}
                        </p>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description2)
                            : t(position.description2_de)}
                        </p>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description3)
                            : t(position.description3_de)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
        <Footer />
      </div>
      <div className={s.containerTablet}>
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              animate={true}
            >
              <h2 className={s.title}>{t('Career')}</h2>
            </Title>
          </div>

          <ul className={s.listPosition}>
            {data &&
              data
                .filter(item => item.isVisible)
                .map(position => (
                  <li
                    key={position.title}
                    className={s.item}
                    onClick={() => {
                      navigate(`/career-description?${position.id}`);
                    }}
                  >
                    <StyledSVG
                      id={ids['vacancy-item-tablet']}
                      opacity={1}
                      top={0}
                      left={0}
                      translate={0}
                    />
                    <div className={s.backgroundItem}>
                      <p className={s.descriptionPosition}>
                        {isEnglishSelected
                          ? t(position.title)
                          : t(position.title_de)}
                      </p>
                      <div className={s.flexContainer}>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description)
                            : t(position.description_de)}
                        </p>

                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description2)
                            : t(position.description2_de)}
                        </p>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description3)
                            : t(position.description3_de)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
        <Footer />
      </div>
      <div className={s.containerMobile}>
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.mobile.svg2}
              svg2={source.title.mobile.svg1}
              svg3={source.title.mobile.svg3}
              animate={true}
              top2={32}
              top1={18}
            >
              <h2 className={s.title}>{t('Career')}</h2>
            </Title>
          </div>

          <ul className={s.listPosition}>
            {data &&
              data
                .filter(item => item.isVisible)
                .map(position => (
                  <li
                    key={position.title}
                    className={s.item}
                    onClick={() => {
                      navigate(`/career-description?${position.id}`);
                    }}
                  >
                    <StyledSVG
                      id={ids['vacancy-item-mobile']}
                      opacity={1}
                      top={0}
                      left={0}
                      translate={0}
                    />
                    <div className={s.backgroundItem}>
                      <p className={s.descriptionPosition}>
                        {isEnglishSelected
                          ? t(position.title)
                          : t(position.title_de)}
                      </p>
                      <div className={s.flexContainer}>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description)
                            : t(position.description_de)}
                        </p>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description2)
                            : t(position.description2_de)}
                        </p>
                        <p className={s.descriptionPositionAccent}>
                          {isEnglishSelected
                            ? t(position.description3)
                            : t(position.description3_de)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Career;

const StyledSVG = styled(SVG)`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;

  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: transform 800ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
`;
