import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import styled from '@emotion/styled';
import 'dayjs/locale/en-gb';

export const DatePicker = ({ value, onChange }) => {
  const handleChange = newValue =>
    onChange(dayjs(newValue).format('YYYY/MM/DD'));

  const suggestedDate = dayjs(Date.now() + 4838400000).format('YYYY/MM/DD');

  return (
    <LocalizationProvider adapterLocale={'en-gb'} dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat="YYYY/MM/DD"
        maxDate="2036"
        minDate={suggestedDate}
        value={value}
        onChange={handleChange}
        renderInput={params => <StyledTextField {...params} />}
        dayOfWeekFormatter={day => day}
        disablePast
      />
    </LocalizationProvider>
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #161619;
  .MuiInput-underline:after {
    height: 50px;
  }

  .Mui-focused {
    border-radius: 0;
    border: 2px solid #e8a0ff;
  }

  .MuiInput-underline.Mui-error:after {
    transform: scaleX(1);
    height: 50px;
    border: 2px solid red;
  }

  .MuiInputBase-adornedEnd {
    margin: 0;
  }

  input,
  .MuiSvgIcon-root {
    color: rgba(255, 255, 255, 0.4);
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
