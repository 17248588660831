import react, { useEffect, useState } from 'react';
import s from './contacts.module.scss';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import 'leaflet.tilelayer.colorfilter';
import { useForm } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import Footer from 'components/footer';
import { Link } from 'react-router-dom';
import data from './poligon.json';
import Title from 'components/molecules/title/Title';
import Icon from 'components/atoms/icon';
import { api } from 'api/client';

const source = {
  title: {
    desktop: {
      svg1: 'contacts-titlevector2',
      svg2: 'contacts-titlevector1',
      svg3: 'contacts-titlevector3',
    },
    tablet: {
      svg1: 'contacts-tablet-titlevector2',
      svg2: 'contacts-tablet-titlevector1',
      svg3: 'contacts-tablet-titlevector3',
    },
    mobile: {
      svg1: 'contacts-mobile-titlevector2',
      svg2: 'contacts-mobile-titlevector1',
      svg3: 'contacts-mobile-titlevector3',
    },
  },
};
const linkToMap = 'https://goo.gl/maps/XQ5F5S5LPVfWE4G3A';

const Contacts = ({
  setIsActiveCheckbox,
  isActiveCheckbox,
  setModalVisibleForm,
}) => {
  const { t, i18n } = useTranslation();

  const [clickLink, setClickLink] = useState(false);

  const isDesktop = window.screen.width >= 1300;
  const isTablet = window.screen.width >= 834 && window.screen.width < 1300;
  const isMobile = window.screen.width < 834;

  useEffect(() => {
    if (isActiveCheckbox) setClickLink(true);
  }, []);

  let map = null;

  let myFilter = [
    'blur:0px',
    'brightness:100%',
    'grayscale:100%',
    'invert:100%',
  ];

  useEffect(() => {
    if (isDesktop) {
      if (map !== null) {
        map.remove();
      }
      map = L.map('map', {
        center: [43.7, 8.5],
        scrollWheelZoom: false,
        zoom: 6,
      });
      L.tileLayer
        .colorFilter('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          filter: myFilter,
        })
        .addTo(map);
      var myIcon = L.divIcon({
        className: 'my-div-icon',
        html: `<div class=${s.point}></div><div class=${s.blob}></div>`,
      });
      L.marker([47.972088367915255, 8.792983713494161], { icon: myIcon }).addTo(
        map
      );
      L.geoJSON(data, {
        style: function (feature) {
          return { color: '#781d95', fillColor: 'grey', fillOpacity: 0 };
        },
      })
        .bindPopup(function (layer) {
          return layer.feature.properties.description;
        })
        .addTo(map);
    }
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 10475 && isDesktop) {
      setTimeout(() => {
        map.flyTo([47.972088367915255, 8.792983713494161], 4);
      }, 4000);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    var formData = new FormData();

    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('message', data.message);

    setModalVisibleForm(true);

    api
      .post('api/contact', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => reset())
      .catch(() => alert('something went wrong'));
  };

  const checkError = name => (errors[name] ? { border: '2px solid red' } : {});

  return (
    <>
      {/*     --- Desktop markup ---    */}
      {isDesktop && (
        <div className={s.commonWrapper}>
          <div className={s.link} id="contact"></div>
          <div className={s.map} id="map"></div>
          <div
            className={
              i18n.language === 'de' ? s.contactDetailsDe : s.contactDetails
            }
          >
            <p className={s.contactTitle}>{t('PFZ GmbH & Co. KG')}</p>
            <a
              href={linkToMap}
              target="_blank"
              rel="noopener noreferrer"
              className={s.containerContactText}
            >
              <p className={s.contactText}>
                {t('Messerschmittstrasse 5, 78532 Tuttlingen, Germany')}
              </p>
            </a>
            <div className={s.line1}></div>
            <div className={s.contactLeft}></div>
            <div className={s.contactCentral}>
              <p className={s.contactTel}>Tel:</p>
              <p className={s.contactMail}>{t('Accounting: ')}</p>

              <p className={s.contactMail}>{t('Quality feedback:')}</p>
            </div>
            <div className={s.contactRight}>
              <p className={s.contactTel}>+49 7461 / 966 2999</p>
              <a className={s.mailLink} href="mailto:info@pfz-cnc.com">
                <p className={s.contactInfo}>info@pfz-cnc.com</p>
              </a>
              <a className={s.mailLink} href="mailto:quality@pfz-cnc.com">
                <p className={s.contactInfo}>quality@pfz-cnc.com</p>
              </a>
            </div>
          </div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.desktop.svg1}
              svg2={source.title.desktop.svg2}
              svg3={source.title.desktop.svg3}
              top1={16}
              top2={26}
            >
              <h2 className={s.title}>{t('contact form')}</h2>
            </Title>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="text"
                  className={s.input}
                  placeholder={t('Name')}
                  style={checkError('name')}
                  {...register('name', { required: true })}
                />
              </label>
            </div>

            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="mail"
                  className={s.input}
                  style={checkError('email')}
                  placeholder={t('E-mail')}
                  {...register('email', { required: true })}
                />
              </label>
            </div>
            <div className={s.line}>
              <label
                className={`${s.phone} ${
                  !!errors['phone'] && s.containerPhoneError
                }`}
              >
                <PhoneInputWithCountry
                  autoComplete="off"
                  className={s.inputText}
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  placeholder={t('Phone')}
                  error={errors['phone']}
                />
              </label>
            </div>

            <label className={s.textarea_label}>
              <textarea
                type="text"
                className={s.textarea}
                placeholder={t('Message')}
                {...register('message')}
              />
            </label>

            <div className={s.containerTextConfirm}>
              {!isActiveCheckbox && (
                <Icon
                  onClick={() => setIsActiveCheckbox(true)}
                  name="checkbox"
                  size={22}
                />
              )}
              {isActiveCheckbox && (
                <Icon
                  name="checkbox-fill"
                  size={22}
                  onClick={() => setIsActiveCheckbox(false)}
                />
              )}
              <p className={s.textConfirm}>
                {t('I confirm that I have read and accepted the')}{' '}
                <Link to="/policy" className={s.textConfirmAccent}>
                  {t('Privacy Policy*')}
                </Link>{' '}
                <span className={s.textConfirmDe}>{t('PrivacyDe')}</span>
              </p>
            </div>

            <div className={s.bottomContainer}>
              <button
                type="submit"
                className={
                  !isActiveCheckbox ? s.buttonQuote : s.buttonQuoteActive
                }
                disabled={!isActiveCheckbox}
              >
                {t('send')}
              </button>
              <Link to="order-form" className={s.textAccentLink}>
                {t('We also manufacture to individual specifications')}
              </Link>
            </div>
          </form>
          <Footer />
        </div>
      )}

      {/*     --- Mobile markup ---    */}

      {isMobile && (
        <div className={s.commonWrapperMobile} id="contact_m">
          <div className={s.link} id="contact_m"></div>

          <div
            className={
              i18n.language === 'de' ? s.contactDetailsDe : s.contactDetails
            }
          >
            <div className={s.line1}></div>
            <p className={s.contactTitle}>{t('PFZ GmbH & Co. KG')}</p>

            <a
              href={linkToMap}
              target="_blank"
              rel="noopener noreferrer"
              className={s.containerContactText}
            >
              <Icon name="map" size={30} className={s.icon} />
              <p className={s.contactText}>
                {t('Messerschmittstrasse 5, 78532 Tuttlingen, Germany')}
              </p>
            </a>
            <div className={s.line12}></div>
            <div className={s.contactLeft}></div>
            <div className={s.contactCentralTablet}>
              <div className={s.containerFlexLeft}>
                <p className={s.contactTextMobile}>Tel:</p>{' '}
                <p className={s.contactTextMobile}>{t('Accounting: ')}</p>{' '}
                <p className={s.contactTextMobile}>{t('Quality feedback:')}</p>{' '}
              </div>
              <div className={s.containerFlexRight}>
                <p className={s.contactInfo}>+49 7461 / 966 2999</p>
                <a className={s.mailLink} href="mailto:info@pfz-cnc.com">
                  <p className={s.contactInfo}>info@pfz-cnc.com</p>
                </a>
                <a className={s.mailLink} href="mailto:quality@pfz-cnc.com">
                  <p className={s.contactInfo}>quality@pfz-cnc.com</p>
                </a>
              </div>
            </div>
            <div className={s.line1}></div>
          </div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.mobile.svg1}
              svg2={source.title.mobile.svg2}
              svg3={source.title.mobile.svg3}
              top1={18}
              top2={30}
            >
              <h2 className={s.title}>{t('contact form')}</h2>
            </Title>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="text"
                  className={s.input}
                  placeholder={t('Name')}
                  style={checkError('name')}
                  {...register('name', { required: true })}
                />
              </label>
            </div>

            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="mail"
                  className={s.input}
                  style={checkError('email')}
                  placeholder={t('E-mail')}
                  {...register('email', { required: true })}
                />
              </label>
            </div>
            <div className={s.line}>
              <label
                className={`${s.phone} ${
                  !!errors['phone'] && s.containerPhoneError
                }`}
              >
                <PhoneInputWithCountry
                  autoComplete="off"
                  className={s.inputText}
                  control={control}
                  name={'phone'}
                  rules={{ required: true }}
                  placeholder={t('Phone')}
                  error={errors['phone']}
                />
              </label>
            </div>
            <div class="form-group" className={s.lineAccent}>
              <label className={s.textarea_label}>
                <textarea
                  type="text"
                  className={s.textarea}
                  placeholder={t('Message')}
                  {...register('message')}
                />
              </label>
            </div>
            <div className={s.bottomContainer}>
              <div className={s.bottomContainer}>
                <p className={s.textAccent}>
                  {t('We also manufacture to individual specifications')}
                </p>

                <div className={s.containerTextConfirm}>
                  {!isActiveCheckbox && (
                    <Icon
                      onClick={() => setIsActiveCheckbox(true)}
                      name="checkbox"
                      size={22}
                    />
                  )}
                  {isActiveCheckbox && (
                    <Icon
                      name="checkbox-fill"
                      size={22}
                      onClick={() => setIsActiveCheckbox(false)}
                    />
                  )}
                  <p className={s.textConfirm}>
                    {t('I confirm that I have read and accepted the')}{' '}
                    <Link to="/policy" className={s.textConfirmAccent}>
                      {t('Privacy Policy*')}
                    </Link>{' '}
                    <span className={s.textConfirmDe}>{t('PrivacyDe')}</span>
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={!isActiveCheckbox}
                  className={
                    !isActiveCheckbox ? s.buttonQuote : s.buttonQuoteActive
                  }
                >
                  {t('send')}
                </button>
              </div>
            </div>
          </form>
          <Footer />
        </div>
      )}

      {/*     --- Tablet markup ---    */}

      {isTablet && (
        <div className={s.commonWrapperTablet} id="contact_t">
          <div className={s.link} id="contact_m"></div>

          <div
            className={
              i18n.language === 'de' ? s.contactDetailsDe : s.contactDetails
            }
          >
            <div className={s.contactDetailsLeft}>
              <Link to="order-form" className={s.contactTitle}>
                {t('PFZ GmbH & Co. KG')}
              </Link>

              <a
                href={linkToMap}
                target="_blank"
                rel="noopener noreferrer"
                className={s.containerContactText}
              >
                <Icon name="map" size={30} className={s.icon} />
                <p className={s.contactText}>
                  {t('Messerschmittstrasse 5, 78532 Tuttlingen, Germany')}
                </p>
              </a>

              <div className={s.contactLeft}></div>
              <div className={s.contactCentralTablet}>
                <div className={s.containerFlexLeft}>
                  <p className={s.contactTelTablet}>Tel:</p>{' '}
                </div>
                <div className={s.containerFlexRight}>
                  <p className={s.contactTelAccent}>+49 7461 / 966 2999</p>
                </div>
              </div>
            </div>
            <div className={s.contactDetailsRight}>
              <div className={s.containerFlexLeft}>
                <p className={s.contactMail}>{t('Accounting: ')}</p>{' '}
                <p className={s.contactMail}>{t('Quality feedback:')}</p>{' '}
              </div>
              <div className={s.containerFlexRight}>
                <a className={s.mailLink} href="mailto:info@pfz-cnc.com">
                  <p className={s.contactInfo}>info@pfz-cnc.com</p>
                </a>
                <a className={s.mailLink} href="mailto:quality@pfz-cnc.com">
                  <p className={s.contactInfo}>quality@pfz-cnc.com</p>
                </a>
              </div>
            </div>
          </div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.tablet.svg1}
              svg2={source.title.tablet.svg2}
              svg3={source.title.tablet.svg3}
              top1={44}
              top2={27}
            >
              <h2 className={s.title}>{t('contact form')}</h2>
            </Title>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="text"
                  className={s.input}
                  placeholder={t('Name')}
                  style={checkError('name')}
                  {...register('name', { required: true })}
                />
              </label>
            </div>

            <div className={s.line}>
              <label className={s.text}>
                <input
                  type="mail"
                  className={s.input}
                  style={checkError('email')}
                  placeholder={t('E-mail')}
                  {...register('email', { required: true })}
                />
              </label>
            </div>
            <div className={s.line}>
              <label
                className={`${s.phone} ${
                  !!errors['phone'] && s.containerPhoneError
                }`}
              >
                <PhoneInputWithCountry
                  autoComplete="off"
                  className={s.inputText}
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  placeholder={t('Phone')}
                  error={errors['phone']}
                />
              </label>
            </div>
            <div class="form-group" className={s.lineAccent}>
              <label className={s.textarea_label}>
                <textarea
                  type="text"
                  className={s.textarea}
                  placeholder={t('Message')}
                  {...register('message')}
                />
              </label>
            </div>
            <div className={s.bottomContainer}>
              <div className={s.bottomContainer}>
                <p className={s.textAccent}>
                  {t('We also manufacture to individual specifications')}
                </p>

                <div className={s.containerTextConfirm}>
                  {!isActiveCheckbox && (
                    <Icon
                      onClick={() => setIsActiveCheckbox(true)}
                      name="checkbox"
                      size={22}
                    />
                  )}
                  {isActiveCheckbox && (
                    <Icon
                      name="checkbox-fill"
                      size={22}
                      onClick={() => setIsActiveCheckbox(false)}
                    />
                  )}
                  <p className={s.textConfirm}>
                    {t('I confirm that I have read and accepted the')}{' '}
                    <Link to="/policy" className={s.textConfirmAccent}>
                      {t('Privacy Policy*')}
                    </Link>{' '}
                    <span className={s.textConfirmDe}>{t('PrivacyDe')}</span>
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={!isActiveCheckbox}
                  className={
                    !isActiveCheckbox ? s.buttonQuote : s.buttonQuoteActive
                  }
                >
                  {t('send')}
                </button>
              </div>
            </div>
          </form>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Contacts;
