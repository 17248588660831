import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { SVG, ids, files } from 'components/atoms/svg/svg';
import { isVisible } from 'utils/isVisible';

const Title = ({
  children,
  svg1,
  svg2,
  svg3,
  top1,
  top2,
  left1,
  left2,
  top3,
  left3,
  containerStyles,
  contentStyles,
  visible = true,
  shift = 24,
  animate,
  animationDisabled = false,
}) => {
  const first = useRef(null);
  const [animated, setAnimated] = useState(animationDisabled);

  const callBack = () => {
    first.current && !animated && isVisible(first.current) && setAnimated(true);
  };
  useEffect(() => {
    if (animate === true && !animated) {
      setTimeout(() => {
        setAnimated(true);
      }, 1000);
    }
  }, [animate]);

  useEffect(() => {
    window.addEventListener('scroll', callBack);

    return () => {
      window.removeEventListener('scroll', callBack);
    };
  }, []);

  if (!files.byId[svg1]) return <div>There is no '{svg1}' icon</div>;
  if (!files.byId[svg2]) return <div>There is no '{svg2}' icon</div>;
  if (!files.byId[svg3]) return <div>There is no '{svg3}' icon</div>;

  const totalWidth = Math.max(
    files.byId[svg1].width,
    files.byId[svg2].width,
    files.byId[svg3].width
  );
  const totalHeight = Math.max(
    files.byId[svg1].height,
    files.byId[svg2].height,
    files.byId[svg3].height
  );

  return (
    <Container
      ref={first}
      containerStyles={containerStyles}
      visible={visible}
      totalWidth={totalWidth}
      totalHeight={totalHeight}
    >
      {files.allIds.includes(svg1) && (
        <StyledSVG
          id={ids[svg1]}
          opacity={animated ? 1 : 0}
          top={
            top1 || totalHeight / 2 - files.byId[svg1].height / 2 - shift / 2
          }
          left={left1 || totalWidth / 2 - files.byId[svg1].width / 2}
          translate={animated ? 0 : 100}
        />
      )}
      {files.allIds.includes(svg2) && (
        <StyledSVG
          id={ids[svg2]}
          top={
            top2 || totalHeight / 2 - files.byId[svg2].height / 2 + shift / 2
          }
          left={left2 || totalWidth / 2 - files.byId[svg2].width / 2}
          opacity={animated ? 1 : 0}
          translate={animated ? 0 : -100}
        />
      )}
      {files.allIds.includes(svg3) && (
        <StyledSVG
          id={ids[svg3]}
          opacity={animated ? 1 : 0}
          top={top3 || totalHeight / 2 - files.byId[svg3].height / 2}
          left={left3 || totalWidth / 2 - files.byId[svg3].width / 2}
        />
      )}
      <ContentWrapper contentStyles={contentStyles} opacity={animated ? 1 : 0}>
        {children}
      </ContentWrapper>
    </Container>
  );
};

export default Title;

const Container = styled.div`
  position: relative;
  width: ${({ totalWidth }) => totalWidth}px;
  height: ${({ totalHeight }) => totalHeight}px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${({ containerStyles }) => containerStyles};

  transition: opacity 300ms linear;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ contentStyles }) => contentStyles}

  opacity: ${({ opacity }) => opacity};
  transition: all 500ms linear 800ms;
`;

const StyledSVG = styled(SVG)`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;

  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: transform 800ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
`;
