import React, { useState, useEffect } from 'react';
import Contacts from '../contacts/contacts';
import logoGif from '../../images/logo_preloader.png';
import s from './main.module.scss';
import { Cookies } from 'components/cookies/cookies';
import Hero from '../hero';
import Quality from '../quality';
import AboutUs from '../aboutUs';
import Machinery from '../machinery';
import PartsSpectrum from '../partsSpectrum';
import Materials from '../materials';
import Team from '../team';
import TurnedParts from 'components/turnedParts';
import BackToTopButton from '../backToTopButton';
import Milling from 'components/milling';

export const MainPage = ({
  isOpenMenu,
  isActiveCheckbox,
  setIsActiveCheckbox,
  isModalVisibleForm,
  setModalVisibleForm,
  isLogoLoaded,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookies, setCookies] = useState({});
  const [isVisibleCookies, setIsVisibleCookies] = useState(false);
  const [isSuspended, setIsSuspended] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  // const [scroll, setScroll] = useState(0);

  useEffect(() => {
    setCookies(JSON.parse(localStorage.getItem('cookies')));
  }, [isVisibleCookies]);

  useEffect(() => {
    cookies && setIsVisibleCookies(!cookies?.isShow);
  }, [cookies?.isShow]);

  // const handleScroll = () => {
  //   setScroll(window.scrollY);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // useEffect(() => {
  //   setIsVisibleCookies(false);
  // }, [scroll]);

  setTimeout(() => {
    setIsVisible(true);
  }, 0);

  useEffect(() => {
    isVideoLoaded && isLogoLoaded && isSuspended && setIsSuspended(false);
  }, [isLogoLoaded, isVideoLoaded]);

  useEffect(() => {
    if (isVisibleCookies && window.innerWidth > 1299) {
      document.body.style.overflow = 'hidden';
    }
    if (!isVisibleCookies) {
      document.body.style.overflow = 'visible';
    }
  }, [isVisibleCookies]);

  return (
    <div style={{ filter: isOpenMenu && 'blur(10px)' }}>
      {isSuspended && (
        <div className={s.backdrop}>
          <div className={s.containerLoader}>
            <div className={s.loader}></div>

            <div className={s.containerImg}>
              {' '}
              <img className={s.img} src={logoGif} alt="gif"></img>
            </div>
          </div>
        </div>
      )}
      {isVisibleCookies && (
        <Cookies
          setIsVisibleCookies={setIsVisibleCookies}
          cookies={cookies?.agree}
        />
      )}
      {!isVisibleCookies && (
        <button
          className={s.buttonShow}
          onClick={() => setIsVisibleCookies(true)}
        >
          Cookie Einstellungen
        </button>
      )}
      <Hero setIsVideoLoaded={setIsVideoLoaded} isSuspended={isSuspended} />
      <AboutUs
        isModalVisibleForm={isModalVisibleForm}
        setModalVisibleForm={setModalVisibleForm}
      />
      <Milling />
      <TurnedParts />
      <Machinery />
      <PartsSpectrum />
      <Materials />
      <Team />
      <Quality />
      <BackToTopButton />
      <Contacts
        setIsActiveCheckbox={setIsActiveCheckbox}
        isActiveCheckbox={isActiveCheckbox}
        setModalVisibleForm={setModalVisibleForm}
      />
    </div>
  );
};
