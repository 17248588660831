import react, { useEffect } from 'react';
import s from './privacyPolicy.module.scss';
import { useTranslation } from 'react-i18next';
import Title from 'components/molecules/title/Title';
import { ReactComponent as LeftTopSVG } from '../../images/orderForm/leftTop.svg';
import { ReactComponent as LeftBottomSVG } from '../../images/orderForm/leftBottom.svg';
import { ReactComponent as RightTopSVG } from '../../images/orderForm/rightTop.svg';
import { ReactComponent as RightBottomSVG } from '../../images/orderForm/rightBottom.svg';
import Marker from 'components/atoms/marker/Marker';
import Icon from 'components/atoms/icon';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = ({ setIsActiveCheckbox, isActiveCheckbox }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (isActiveCheckbox) setIsActiveCheckbox(false);
    window.scrollTo({
      top: '0px',
      behavior: 'smooth',
    });
  }, []);

  const navigate = useNavigate();

  const source = {
    title: {
      desktop: {
        svg1: 'policy-desktop-titlevector3',
        svg2: 'policy-desktop-titlevector2',
        svg3: 'policy-desktop-titlevector1',
      },
      tablet: {
        svg1: 'policy-tablet-titlevector2',
        svg2: 'policy-tablet-titlevector1',
        svg3: 'policy-tablet-titlevector3',
      },
      mobile: {
        svg1: 'policy-mobile-titlevector2',
        svg2: 'policy-mobile-titlevector3',
        svg3: 'policy-mobile-titlevector1',
      },
    },
  };
  return (
    <div>
      <div
        className={
          i18n.language === 'en' ? s.containerMobile : s.containerMobileDe
        }
      >
        <div className={s.wrapper}>
          <div className={s.containerTitle}>
            <Title
              svg1={source.title.mobile.svg2}
              svg2={source.title.mobile.svg1}
              svg3={source.title.mobile.svg3}
              top1={18}
              left1={-65}
              top2={33}
              left2={-103}
              left3={-100}
              animate={true}
            >
              <h2 className={i18n.language === 'en' ? s.title : s.titleDe}>
                {t('Privacy policy')}
              </h2>
            </Title>
          </div>
          <p className={s.text}>
            {t(
              'We are grateful for your interest in our company. Data protection is of a particularly high priority for the management of PFZ GmbH & Co. KG. The use of the Internet pages of the PFZ GmbH & Co. KG is possible without any indication of personal data. If a data subject wants to use special services of our enterprise via our website, however, processing of personal data could become necessary. If processing of personal data is necessary and there is no legal basis for such processing, we will generally obtain the consent of the data subject'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation, and in accordance with the country-specific data protection regulations applicable to the PFZ GmbH & Co. KG applicable country-specific data protection regulations. By means of this data protection declaration, our company would like to inform the public about the type, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed of their rights by means of this data protection declaration.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. Nevertheless, Internet-based data transmissions can always be vulnerable to security risks, so that absolute protection cannot be guaranteed. For this reason, every data subject is free to transmit personal data to us by alternative means, for example by telephone'
            )}
          </p>
          <h3 className={s.titleAccent}>1. {t('Definitions of terms')}</h3>
          <p className={s.text}>
            {t(
              'The data protection declaration of the PFZ GmbH & Co. KG is based on the terms used by the European Data Protection Supervisor when adopting the Data Protection Regulation (DS-GVO). Our privacy policy should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terminology used in advance.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'We use the following terms, among others, in this data protection declaration:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Personal data')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Personal data is any information relating to an identified or identifiable natural person (hereinafter "data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Data subject')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Data subject is any identified or identifiable natural person whose personal data are processed by the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processing is any operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('d) Restriction of processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Restriction of processing is the marking of stored personal data with the aim of limiting their future processing.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('e) Profiling')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Profiling is any type of automated processing of personal data that consists of using such personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that natural persons job performance, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Pseudonymization')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Pseudonymization is the processing of personal data in such a way that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separate and is subject to technical and organizational measures to ensure that the personal data is not attributed to an identified or identifiable natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('g) Controller or person responsible for processing.')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Controller or person responsible for processing is the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing of personal data. Where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its designation may be provided for under Union or Member State law.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('h) Processor')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('i) Recipient')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Recipient is a natural or legal person, public authority, agency or other body to whom personal data are disclosed, whether or not it is a third party. However, public authorities that may receive Personal Data in the context of a specific investigative task under Union or Member State law shall not be considered as recipients'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('j) Third Party')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Third party is a natural or legal person, public authority, agency or other body other than the data subject, the controller, the processor and the persons authorized to process the personal data under the direct responsibility of the controller or the processor.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('k) Consent')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Consent is any freely given indication of the data subjects wishes for the specific case in an informed and unambiguous manner, in the form of a statement or other unambiguous affirmative act by which the data subject indicates that he or she consents to the processing of personal data relating to him or her.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            2. {t('Name and address of the controller')}
          </h3>
          <p className={s.text}>
            {t(
              'Responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the Member States of the European Union and other provisions with data protection character is:'
            )}
          </p>
          <p className={s.textContact}>{t('PFZ GmbH & Co. KG')}</p>
          <p className={s.textContact}>
            {t('Messerschmittstrasse 5, 78532 Tuttlingen')}
          </p>
          <p className={s.textContact}>{t('Germany')}</p>
          <p className={s.textContact}>{t('Phone: +49 (0) 7461 966 2999')}</p>
          <p className={s.textContact}>{t('Fax: +49 (0) 7461 966 3397')}</p>
          <p className={s.textContact}>{t('Email: info@pfz-cnc.com')}</p>
          <div className={s.flex}>
            <p className={s.textWeb}>{t('Website')}:</p>
            <a
              href="https://pfz-cnc.com"
              target="_blank"
              rel="noopener noreferrer"
              className={s.linkAccent}
            >
              {' '}
              https://pfz-cnc.com
            </a>
          </div>

          <h3 className={s.titleAccent}>
            3. {t('Name and address of the data protection officer')}
          </h3>
          <p className={s.text}>
            {t('The data protection officer of the controller is:')}
          </p>
          <p className={s.text}>{t('Manuel Wörner')}</p>
          <p className={s.text}>{t('E-mail: manuel.woerner@pfz-cnc.com')}</p>
          <p className={s.text}>
            {t(
              'Any data subject may contact our data protection officer directly at any time with any questions or suggestions regarding data protection.'
            )}
          </p>
          <h3 className={s.titleAccent}>4. Cookies</h3>
          <p className={s.text}>
            {t(
              'The websites of the PFZ GmbH & Co. KG use cookies. Cookies are text files that are stored on a computer system via an Internet browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Numerous Internet pages and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a string of characters by which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This enables the visited Internet pages and servers to distinguish the individual browser of the data subject from other Internet browsers that contain other cookies. A specific internet browser can be recognized and identified via the unique cookie ID.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the information and offers on our website can be optimized for the benefit of the user. Cookies enable us, as already mentioned, to recognize the users of our website. The purpose of this recognition is to make it easier for users to use our website. For example, the user of a website that uses cookies does not have to re-enter his or her access data each time he or she visits the website, because this is handled by the website and the cookie stored on the users computer system. Another example is the cookie of a shopping cart in an online store. The online store remembers the items that a customer has placed in the virtual shopping cart via a cookie'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via an Internet browser or other software programs. This is possible in all common Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be fully usable.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'More information about cookies: http://www.whatarecookies.com/'
            )}
          </p>

          <h3 className={s.titleAccent}>
            5. {t('Collection of general data and information')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of the PFZ GmbH & Co. KG collects a series of general data and information with each call-up of the website by a data subject or automated system. This general data and information is stored in the log files of the server. The following data may be collected: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrer), (4) the sub-websites that are accessed via an accessing system on our website, (5) the date and time of an access to the website, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) other similar data and information that serve to avert danger in the event of attacks on our information technology systems.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'When using these general data and information, the PFZ GmbH & Co. KG does not draw any conclusions about the data subject. Rather, this information is needed (1) to deliver the contents of our website correctly, (2) to optimize the contents of our website and the advertising for these, (3) to ensure the long-term functionality of our information technology systems and the technology of our website, and (4) to provide law enforcement authorities with the information necessary for prosecution in the event of a cyber attack. This anonymously collected data and information is used by the PFZ GmbH & Co. KG therefore statistically and moreover with the aim of increasing the data protection and data security of our enterprise, and ultimately with the aim of ensuring an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from any personal data provided by a data subject.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            6. {t('Registration on our website')}
          </h3>
          <p className={s.text}>
            {t(
              'The data subject has the opportunity to register on the website of the controller by providing personal data. Which personal data are transmitted to the controller in the process results from the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller and for its own purposes. The controller may arrange for the data to be transferred to one or more processors, for example a parcel service provider, who will also use the personal data exclusively for an internal use attributable to the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By registering on the website of the controller, the IP address assigned by the Internet service provider (ISP) of the data subject, the date as well as the time of registration are also stored. The storage of this data takes place against the background that only in this way can the misuse of our services be prevented and, if necessary, this data makes it possible to clarify committed crimes. In this respect, the storage of this data is necessary for the protection of the data controller. As a matter of principle, this data is not passed on to third parties unless there is a legal obligation to pass it on or the passing on serves the purpose of criminal prosecution.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The registration of the data subject by voluntarily providing personal data serves the purpose of the controller to offer the data subject content or services which, due to the nature of the matter, can only be offered to registered users. Registered persons are free to modify the personal data provided during registration at any time or to have it completely deleted from the data stock of the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The controller shall provide any data subject at any time upon request with information on what personal data is stored about the data subject. Furthermore, the controller shall correct or delete personal data at the request or indication of the data subject, provided that this does not conflict with any statutory retention obligations. The entire staff of the controller shall be available to the data subject as contact persons in this context.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            7. {t('Contact possibility via the internet page')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of PFZ GmbH & Co. KG contains legal requirements which enable a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or by using a contact form, the personal data transmitted by the data subject will be stored automatically. Such personal data transmitted on a voluntary basis by a data subject to the controller will be stored for the purpose of processing or contacting the data subject. No disclosure of such personal data to third parties will take place.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            8. {t('Routine erasure and blocking of personal data')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller processes and stores personal data of the data subject only for the period of time necessary to achieve the purpose of storage or insofar as this has been provided for by the European Directives and Regulations or other legislator in laws or regulations to which the controller is subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the storage purpose ceases to apply or if a storage period prescribed by the European Directive and Regulation Maker or another competent legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            9. {t('Rights of the data subject')}
          </h3>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Right to confirmation')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every data subject has the right, granted by the European Directive and Regulation, to obtain confirmation from the controller as to whether personal data concerning him or her are being processed. If a data subject wishes to exercise this right of confirmation, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Right of access')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain at any time from the controller, free of charge, information about the personal data stored about him or her and a copy of that information. In addition, the European Directive and Regulation Legislator has granted the data subject access to the following information:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('The purposes of processing')}</p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The categories of personal data processed')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular in the case of recipients in third countries or international organizations'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right to obtain the rectification or erasure of personal data concerning him or her, or to obtain the restriction of processing by the controller, or a right to object to such processing'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right of appeal to a supervisory authority'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If the personal data are not collected from the data subject: Any available information about the origin of the data'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject shall have the right to obtain information as to whether personal data have been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to obtain information about the appropriate safeguards in connection with the transfer.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of access, he or she may, at any time, contact an employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Right to rectification')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every person affected by the processing of personal data has the right granted by the European Directive and Regulation to demand the immediate rectification of any inaccurate personal data concerning him or her. Furthermore, the data subject has the right to request the completion of incomplete personal data - also by means of a supplementary declaration - taking into account the purposes of the processing.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of rectification, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('d) Right to erasure (right to be forgotten)')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the erasure without delay of personal data concerning him or her, where one of the following grounds applies and insofar as the processing is not necessary:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data were collected or otherwise processed for such purposes for which they are no longer necessary.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject revokes his or her consent on which the processing was based pursuant to Art. 6(1)(a) DS-GVO or Art. 9(2)(a) DS-GVO and there is no other legal basis for the processing.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject objects to the processing pursuant to Article 21(1) DS-GVO and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) DS-GVO.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The personal data have been processed unlawfully.')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The erasure of the personal data is necessary for compliance with a legal obligation under Union or Member State law to which the controller is subject.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data has been collected in relation to information society services offered pursuant to Article 8(1) DS-GVO.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG shall arrange for the erasure request to be complied with immediately.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the personal data was collected by the PFZ GmbH & Co. KG has been made public and our company as the controller is obligated to delete the personal data pursuant to Art. 17 Para. 1 DS-GVO, the PFZ GmbH & Co. KG shall implement reasonable measures, including technical measures, taking into account the available technology and the costs of implementation, in order to inform other data controllers which process the published personal data that the data subject has requested from those other data controllers to erase all links to or copies or replications of the personal data, unless the processing is necessary. The employee of the PFZ GmbH & Co. KG will arrange the necessary in individual cases.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('e) Right to restriction of processing')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the restriction of processing where one of the following conditions is met:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The processing is unlawful, the data subject objects to the erasure of the personal data and requests instead the restriction of the use of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The controller no longer needs the personal data for the purposes of the processing, but the data subject needs it for the assertion, exercise or defense of legal claims.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject has objected to the processing pursuant to Article 21 (1) of the GDPR and it is not yet clear whether the legitimate grounds of the controller override those of the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG will arrange the restriction of the processing.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Right to data portability')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Each data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation, to obtain personal data concerning him or her, which has been provided by the data subject to a controller, in a structured, commonly used and machine-readable format. He or she also has the right to transmit such data to another controller without hindrance from the controller to whom the personal data have been provided, provided that the processing is based on consent pursuant to Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR or on a contract pursuant to Article 6(1)(b) of the GDPR and the processing is carried out by automated means, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, when exercising the right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to obtain that the personal data be transferred directly from one controller to another controller where technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to assert the right to data portability, the data subject may at any time contact any employee of the PFZ GmbH & Co. KG at any time.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('g) Right to object')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to object at any time, on grounds relating to his or her particular situation, to the processing of personal data concerning him or her carried out on the basis of Article 6(1)(e) or (f) of the GDPR. This also applies to profiling based on these provisions.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the assertion, exercise or defence of legal claims.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the PFZ GmbH & Co. KG processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data processed for such marketing. This also applies to the profiling, insofar as it is related to such direct marketing. If the data subject objects to PFZ GmbH & Co. KG to processing for the purposes of direct marketing, the PFZ GmbH & Co. KG will no longer process the personal data for these purposes.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her which is carried out by the PFZ GmbH & Co. KG for scientific or historical research purposes, or for statistical purposes pursuant to Article 89 (1) of the Data Protection Regulation (DS-GVO), unless such processing is necessary for the performance of a task carried out in the public interest.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to exercise the right to object, the data subject may directly contact any employee of the PFZ GmbH & Co. KG or another employee. The data subject is also free to exercise his/her right to object by means of automated procedures using technical specifications in connection with the use of information society services, notwithstanding Directive 2002/58/EC.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'h) Automated decisions in individual cases, including profiling'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data shall have the right, granted by the European Directive and Regulation, not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her, unless the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the controller, or (2) is permitted by Union or Member State law to which the controller is subject and that law contains suitable measures to safeguard the data subjects rights and freedoms and legitimate interests, or (3) is made with the data subjects explicit consent.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the data controller, or (2) it is made with the data subjects explicit consent, the PFZ GmbH & Co. KG will take reasonable steps to safeguard the rights and freedoms as well as the legitimate interests of the data subject, which at least include the right to obtain the intervention of a data subject on the part of the controller, to express his or her point of view and to contest the decision.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the rights concerning automated decisions, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('i) Right to withdraw consent under data protection law')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation Body, to withdraw his or her consent to the processing of personal data at any time.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the right to withdraw consent, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            10.{' '}
            {t(
              'Data protection during applications and the application process'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller collects and processes the personal data of applicants for the purpose of handling the application procedure. The processing may also take place electronically. This is particularly the case if an applicant submits relevant application documents to the controller by electronic means, for example by e-mail or via a web form located on the website. If the controller concludes an employment contract with an applicant, the transmitted data will be stored for the purpose of processing the employment relationship in compliance with the statutory provisions. If the controller does not conclude an employment contract with the applicant, the application documents will be automatically deleted two months after notification of the rejection decision, provided that no other legitimate interests of the controller conflict with such deletion. Other legitimate interest in this sense is, for example, a duty to provide evidence in proceedings under the General Equal Treatment Act (AGG).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            11.{' '}
            {t(
              'Data protection provisions on the use and application of Facebook'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of the company Facebook on this website. Facebook is a social network.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'A social network is a social meeting place operated on the Internet, an online community that generally allows users to communicate with each other and interact in virtual space. A social network can serve as a platform for sharing opinions and experiences or enables the Internet community to provide personal or company-related information. Among other things, Facebook enables users of the social network to create private profiles, upload photos and network via friend requests.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Facebook is Facebook, Inc, 1 Hacker Way, Menlo Park, CA 94025, USA. The controller of personal data, if a data subject lives outside the USA or Canada, is Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the data controller is called up and on which a Facebook component (Facebook plug-in) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Facebook component to download a representation of the corresponding Facebook component from Facebook. A complete overview of all Facebook plug-ins can be found at developers.facebook.com/docs/plugins/. Within the scope of this technical procedure, Facebook obtains knowledge of which specific sub-page of our website is visited by the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged in to Facebook at the same time, Facebook recognizes which specific subpage of our website the data subject is visiting each time the data subject calls up our website and for the entire duration of the respective stay on our website. This information is collected by the Facebook component and assigned by Facebook to the respective Facebook account of the data subject. If the data subject activates one of the Facebook buttons integrated on our website, for example the "Like" button, or if the data subject posts a comment, Facebook assigns this information to the personal Facebook user account of the data subject and stores this personal data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Facebook always receives information via the Facebook component that the data subject has visited our website if the data subject is simultaneously logged into Facebook at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Facebook component or not. If the data subject does not want this information to be transmitted to Facebook, he or she can prevent the transmission by logging out of his or her Facebook account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data policy published by Facebook, which can be accessed at de.facebook.com/about/privacy/, provides information about the collection, processing and use of personal data by Facebook. It also explains which setting options Facebook offers to protect the privacy of the data subject. In addition, various applications are available that allow data transmission to Facebook to be suppressed. Such applications can be used by the data subject to suppress data transmission to Facebook.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            12. {t('Privacy policy on the use and application of Twitter')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of Twitter on this website. Twitter is a multilingual publicly accessible microblogging service on which users can publish and distribute so-called tweets, i.e. short messages limited to 280 characters. These short messages can be accessed by anyone, including people who are not registered with Twitter. However, the tweets are also displayed to the so-called followers of the respective user. Followers are other Twitter users who follow the tweets of a user. Furthermore, Twitter enables a broad audience to be addressed via hashtags, links or retweets.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Twitter is Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the controller is called up and on which a Twitter component (Twitter button) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Twitter component to download a representation of the corresponding Twitter component from Twitter. Further information on the Twitter buttons can be found at about.twitter.com/en/resources/buttons. Within the scope of this technical procedure, Twitter receives knowledge of which specific sub-page of our website is visited by the data subject. The purpose of integrating the Twitter component is to enable our users to disseminate the content of this website, to make this website known in the digital world and to increase our visitor numbers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged into Twitter at the same time, Twitter recognizes which specific subpage of our website the data subject is visiting with each call to our website by the data subject and for the entire duration of the respective stay on our website. This information is collected by the Twitter component and assigned by Twitter to the respective Twitter account of the data subject. If the data subject activates one of the Twitter buttons integrated on our website, the data and information thus transmitted will be assigned to the personal Twitter user account of the data subject and stored and processed by Twitter.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Twitter always receives information via the Twitter component that the data subject has visited our website if the data subject is simultaneously logged into Twitter at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Twitter component or not. If the data subject does not want this information to be transmitted to Twitter, he or she can prevent the transmission by logging out of his or her Twitter account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The applicable data protection provisions of Twitter are available at twitter.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            13.{' '}
            {t('Privacy policy on the use and application of Google Analytics')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated the Google Analytics component on this website. Google Analytics is an open source software tool for web analysis. Web analysis is the collection, compilation and evaluation of data about the behavior of visitors to websites. Among other things, a web analysis tool collects data about the website from which a data subject came to a website (so-called referrer), which subpages of the website were accessed or how often and for how long a subpage was viewed. A web analysis is mainly used for optimizing a website and for cost-benefit analysis of internet advertising.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The software is operated on the server of the controller, the data protection sensitive log files are stored on Google servers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The purpose of the Google Analytics component is to analyze the flow of visitors to our website. The controller uses the data and information obtained, among other things, to evaluate the use of this website in order to compile online reports showing the activities on our websites.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Google Analytics sets a cookie on the information technology system of the data subject. What cookies are has already been explained above. The setting of the cookie enables us to analyze the use of our website. Each time one of the individual pages of this website is called up, the Internet browser on the information technology system of the data subject is automatically caused by the Google Analytics component to transmit data to our server for the purpose of online analysis. As part of this technical procedure, we obtain knowledge of personal data, such as the IP address of the data subject, which serves us, among other things, to trace the origin of visitors and clicks.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By means of the cookie, personal information, for example the access time, the place from which an access originated and the frequency of visits to our website are stored. Each time you visit our website, this personal data, including the IP address of the Internet connection used by the person concerned, is transmitted to our server. This personal data is stored by us. We do not pass on this personal data to third parties.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website, as already described above, at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Such a setting of the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, a cookie already set by Google Analytics can be deleted at any time via an Internet browser or other software programs.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject has the possibility to object to the collection of data generated by Google Analytics and related to a use of this website and to prevent such a collection. To do this, the data subject must set "Do Not Track" in your browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'However, with the setting of the opt-out cookie, there is the possibility that the Internet pages of the controller are no longer fully usable for the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Further information and the applicable privacy policy of Google Analytics can be found at policies.google.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            14. {t('Legal basis of processing')}
          </h3>
          <p className={s.text}>
            {t(
              'Art. 6 I lit. a DS-GVO serves our company as the legal basis for processing operations in which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for a delivery of goods or the provision of another service or consideration, the processing is based on Art. 6 I lit. b DS-GVO. The same applies to such processing operations that are necessary for the implementation of pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation by which a processing of personal data becomes necessary, such as for the fulfillment of tax obligations, the processing is based on Art. 6 I lit. c DS-GVO. In rare cases, the processing of personal data might become necessary to protect vital interests of the data subject or another natural person. This would be the case, for example, if a visitor were to be injured on our premises and as a result his or her name, age, health insurance data or other vital information had to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d DS-GVO. Finally, processing operations could be based on Art. 6 I lit. f DS-GVO. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if the processing is necessary to protect a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject are not overridden. Such processing operations are permitted to us in particular because they were specifically mentioned by the European legislator. In this respect, it took the view that a legitimate interest could be assumed if the data subject is a customer of the controller (recital 47 sentence 2 DS-GVO).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            15.
            {t(
              'Legitimate interests in the processing pursued by the controller or a third party'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'If the processing of personal data is based on Article 6 I lit. f DS-GVO, our legitimate interest is the conduct of our business for the benefit of the well-being of all our employees and our shareholders.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            16. {t('Duration for which the personal data are stored')}
          </h3>
          <p className={s.text}>
            {t(
              'The criterion for the duration of the storage of personal data is the respective statutory retention period. After expiry of the period, the corresponding data is routinely deleted, provided that it is no longer required for the fulfillment or initiation of the contract.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            17.{' '}
            {t(
              'Legal or contractual provisions for the provision of personal data; necessity for the conclusion of the contract; obligation of the data subject to provide the personal data; possible consequences of non-provision'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'We inform you that the provision of personal data is sometimes required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information on the contractual partner). Sometimes, in order to conclude a contract, it may be necessary for a data subject to provide us with personal data that must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data if our company concludes a contract with him or her. Failure to provide the personal data would mean that the contract with the data subject could not be concluded. Before providing personal data by the data subject, the data subject must contact one of our employees. Our employee will explain to the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data, and what the consequences of not providing the personal data would be.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            18. {t('Existence of automated decision making')}
          </h3>
          <p className={s.text}>
            {t(
              'As a responsible company, we do not use automated decision making or profiling.'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('SSL encryption')}</h3>
          <p className={s.text}>
            {t(
              'To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'You can recognize an encrypted connection by the "https://" address line of your browser and the lock symbol in the browser line.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Use of script libraries (Google Webfonts)')}
          </h3>
          <p className={s.text}>
            {t(
              'In order to display our content correctly and graphically appealing across browsers, we use script libraries and font libraries such as Google Webfonts (https://www.google.com/webfonts/) on this website. Google Web Fonts are transferred to your browsers cache to avoid multiple loading. If the browser does not support Google Web Fonts or prevents access, content is displayed in a standard font.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Calling up script libraries or font libraries automatically triggers a connection to the operator of the library. It is theoretically possible - although it is currently also unclear whether and, if so, for what purposes - that operators of corresponding libraries collect data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The privacy policy of the library operator Google can be found here: https://www.google.com/policies/privacy/'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('Use of Google Maps')}</h3>

          <p className={s.text}>
            {t(
              'This website uses Google Maps API to visually display geographical information. When using Google Maps, Google also collects, processes and uses data about visitors use of the map functions. You can find more information about data processing by Google in the Google privacy policy. There you can also change your personal privacy settings in the Privacy Center.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Detailed instructions on how to manage your own data in connection with Google products can be found here.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Changes to our privacy policy')}
          </h3>
          <p className={s.text}>
            {t(
              'We reserve the right to occasionally adapt this data protection statement so that it always complies with the current legal requirements or to implement changes to our services in the data protection statement, e.g. when introducing new services. The new data protection declaration will then apply to your next visit.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'This data protection declaration was created by the data protection declaration generator of DGD Deutsche Gesellschaft für Datenschutz GmbH, which acts as the External Data Protection Officer Bayreuth, in cooperation with the lawyer for IT and data protection law Christian Solmecke.'
            )}
          </p>
          <div className={s.containerTextConfirm}>
            {!isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(true)}
              >
                <Icon name="checkbox" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
            {isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(false)}
              >
                <Icon name="checkbox-fill" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
          </div>

          <button
            onClick={() => {
              navigate(-1);
            }}
            className={s.backToForm}
          >
            {t('back to form')}
          </button>
        </div>
      </div>
      <div
        className={
          i18n.language === 'en' ? s.containerTablet : s.containerTabletDe
        }
      >
        <div className={s.wrapper}>
          <div className={s.containerTitle}>
            <Title
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              animate={true}
              top1={28}
              top2={45}
            >
              <h2 className={i18n.language === 'en' ? s.title : s.titleDe}>
                {t('Privacy policy')}
              </h2>
            </Title>
          </div>
          <p className={s.text}>
            {t(
              'We are grateful for your interest in our company. Data protection is of a particularly high priority for the management of PFZ GmbH & Co. KG. The use of the Internet pages of the PFZ GmbH & Co. KG is possible without any indication of personal data. If a data subject wants to use special services of our enterprise via our website, however, processing of personal data could become necessary. If processing of personal data is necessary and there is no legal basis for such processing, we will generally obtain the consent of the data subject'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation, and in accordance with the country-specific data protection regulations applicable to the PFZ GmbH & Co. KG applicable country-specific data protection regulations. By means of this data protection declaration, our company would like to inform the public about the type, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed of their rights by means of this data protection declaration.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. Nevertheless, Internet-based data transmissions can always be vulnerable to security risks, so that absolute protection cannot be guaranteed. For this reason, every data subject is free to transmit personal data to us by alternative means, for example by telephone'
            )}
          </p>
          <h3 className={s.titleAccent}>1. {t('Definitions of terms')}</h3>
          <p className={s.text}>
            {t(
              'The data protection declaration of the PFZ GmbH & Co. KG is based on the terms used by the European Data Protection Supervisor when adopting the Data Protection Regulation (DS-GVO). Our privacy policy should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terminology used in advance.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'We use the following terms, among others, in this data protection declaration:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Personal data')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Personal data is any information relating to an identified or identifiable natural person (hereinafter "data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Data subject')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Data subject is any identified or identifiable natural person whose personal data are processed by the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processing is any operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('d) Restriction of processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Restriction of processing is the marking of stored personal data with the aim of limiting their future processing.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('e) Profiling')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Profiling is any type of automated processing of personal data that consists of using such personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that natural persons job performance, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Pseudonymization')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Pseudonymization is the processing of personal data in such a way that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separate and is subject to technical and organizational measures to ensure that the personal data is not attributed to an identified or identifiable natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('g) Controller or person responsible for processing.')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Controller or person responsible for processing is the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing of personal data. Where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its designation may be provided for under Union or Member State law.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('h) Processor')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('i) Recipient')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Recipient is a natural or legal person, public authority, agency or other body to whom personal data are disclosed, whether or not it is a third party. However, public authorities that may receive Personal Data in the context of a specific investigative task under Union or Member State law shall not be considered as recipients'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('j) Third Party')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Third party is a natural or legal person, public authority, agency or other body other than the data subject, the controller, the processor and the persons authorized to process the personal data under the direct responsibility of the controller or the processor.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('k) Consent')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Consent is any freely given indication of the data subjects wishes for the specific case in an informed and unambiguous manner, in the form of a statement or other unambiguous affirmative act by which the data subject indicates that he or she consents to the processing of personal data relating to him or her.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            2. {t('Name and address of the controller')}
          </h3>
          <p className={s.text}>
            {t(
              'Responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the Member States of the European Union and other provisions with data protection character is:'
            )}
          </p>
          <p className={s.textContact}>{t('PFZ GmbH & Co. KG')}</p>
          <p className={s.textContact}>
            {t('Messerschmittstrasse 5, 78532 Tuttlingen')}
          </p>
          <p className={s.textContact}>{t('Germany')}</p>
          <p className={s.textContact}>{t('Phone: +49 (0) 7461 966 2999')}</p>
          <p className={s.textContact}>{t('Fax: +49 (0) 7461 966 3397')}</p>
          <p className={s.textContact}>{t('Email: info@pfz-cnc.com')}</p>
          <div className={s.flex}>
            <p className={s.textWeb}>{t('Website')}:</p>
            <a
              href="https://pfz-cnc.com"
              target="_blank"
              rel="noopener noreferrer"
              className={s.linkAccent}
            >
              {' '}
              https://pfz-cnc.com
            </a>
          </div>

          <h3 className={s.titleAccent}>
            3. {t('Name and address of the data protection officer')}
          </h3>
          <p className={s.text}>
            {t('The data protection officer of the controller is:')}
          </p>
          <p className={s.text}>{t('Manuel Wörner')}</p>
          <p className={s.text}>{t('E-mail: manuel.woerner@pfz-cnc.com')}</p>
          <p className={s.text}>
            {t(
              'Any data subject may contact our data protection officer directly at any time with any questions or suggestions regarding data protection.'
            )}
          </p>
          <h3 className={s.titleAccent}>4. Cookies</h3>
          <p className={s.text}>
            {t(
              'The websites of the PFZ GmbH & Co. KG use cookies. Cookies are text files that are stored on a computer system via an Internet browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Numerous Internet pages and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a string of characters by which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This enables the visited Internet pages and servers to distinguish the individual browser of the data subject from other Internet browsers that contain other cookies. A specific internet browser can be recognized and identified via the unique cookie ID.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the information and offers on our website can be optimized for the benefit of the user. Cookies enable us, as already mentioned, to recognize the users of our website. The purpose of this recognition is to make it easier for users to use our website. For example, the user of a website that uses cookies does not have to re-enter his or her access data each time he or she visits the website, because this is handled by the website and the cookie stored on the users computer system. Another example is the cookie of a shopping cart in an online store. The online store remembers the items that a customer has placed in the virtual shopping cart via a cookie'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via an Internet browser or other software programs. This is possible in all common Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be fully usable.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'More information about cookies: http://www.whatarecookies.com/'
            )}
          </p>

          <h3 className={s.titleAccent}>
            5. {t('Collection of general data and information')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of the PFZ GmbH & Co. KG collects a series of general data and information with each call-up of the website by a data subject or automated system. This general data and information is stored in the log files of the server. The following data may be collected: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrer), (4) the sub-websites that are accessed via an accessing system on our website, (5) the date and time of an access to the website, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) other similar data and information that serve to avert danger in the event of attacks on our information technology systems.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'When using these general data and information, the PFZ GmbH & Co. KG does not draw any conclusions about the data subject. Rather, this information is needed (1) to deliver the contents of our website correctly, (2) to optimize the contents of our website and the advertising for these, (3) to ensure the long-term functionality of our information technology systems and the technology of our website, and (4) to provide law enforcement authorities with the information necessary for prosecution in the event of a cyber attack. This anonymously collected data and information is used by the PFZ GmbH & Co. KG therefore statistically and moreover with the aim of increasing the data protection and data security of our enterprise, and ultimately with the aim of ensuring an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from any personal data provided by a data subject.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            6. {t('Registration on our website')}
          </h3>
          <p className={s.text}>
            {t(
              'The data subject has the opportunity to register on the website of the controller by providing personal data. Which personal data are transmitted to the controller in the process results from the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller and for its own purposes. The controller may arrange for the data to be transferred to one or more processors, for example a parcel service provider, who will also use the personal data exclusively for an internal use attributable to the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By registering on the website of the controller, the IP address assigned by the Internet service provider (ISP) of the data subject, the date as well as the time of registration are also stored. The storage of this data takes place against the background that only in this way can the misuse of our services be prevented and, if necessary, this data makes it possible to clarify committed crimes. In this respect, the storage of this data is necessary for the protection of the data controller. As a matter of principle, this data is not passed on to third parties unless there is a legal obligation to pass it on or the passing on serves the purpose of criminal prosecution.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The registration of the data subject by voluntarily providing personal data serves the purpose of the controller to offer the data subject content or services which, due to the nature of the matter, can only be offered to registered users. Registered persons are free to modify the personal data provided during registration at any time or to have it completely deleted from the data stock of the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The controller shall provide any data subject at any time upon request with information on what personal data is stored about the data subject. Furthermore, the controller shall correct or delete personal data at the request or indication of the data subject, provided that this does not conflict with any statutory retention obligations. The entire staff of the controller shall be available to the data subject as contact persons in this context.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            7. {t('Contact possibility via the internet page')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of PFZ GmbH & Co. KG contains legal requirements which enable a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or by using a contact form, the personal data transmitted by the data subject will be stored automatically. Such personal data transmitted on a voluntary basis by a data subject to the controller will be stored for the purpose of processing or contacting the data subject. No disclosure of such personal data to third parties will take place.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            8. {t('Routine erasure and blocking of personal data')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller processes and stores personal data of the data subject only for the period of time necessary to achieve the purpose of storage or insofar as this has been provided for by the European Directives and Regulations or other legislator in laws or regulations to which the controller is subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the storage purpose ceases to apply or if a storage period prescribed by the European Directive and Regulation Maker or another competent legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            9. {t('Rights of the data subject')}
          </h3>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Right to confirmation')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every data subject has the right, granted by the European Directive and Regulation, to obtain confirmation from the controller as to whether personal data concerning him or her are being processed. If a data subject wishes to exercise this right of confirmation, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Right of access')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain at any time from the controller, free of charge, information about the personal data stored about him or her and a copy of that information. In addition, the European Directive and Regulation Legislator has granted the data subject access to the following information:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('The purposes of processing')}</p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The categories of personal data processed')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular in the case of recipients in third countries or international organizations'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right to obtain the rectification or erasure of personal data concerning him or her, or to obtain the restriction of processing by the controller, or a right to object to such processing'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right of appeal to a supervisory authority'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If the personal data are not collected from the data subject: Any available information about the origin of the data'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject shall have the right to obtain information as to whether personal data have been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to obtain information about the appropriate safeguards in connection with the transfer.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of access, he or she may, at any time, contact an employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Right to rectification')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every person affected by the processing of personal data has the right granted by the European Directive and Regulation to demand the immediate rectification of any inaccurate personal data concerning him or her. Furthermore, the data subject has the right to request the completion of incomplete personal data - also by means of a supplementary declaration - taking into account the purposes of the processing.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of rectification, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('d) Right to erasure (right to be forgotten)')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the erasure without delay of personal data concerning him or her, where one of the following grounds applies and insofar as the processing is not necessary:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data were collected or otherwise processed for such purposes for which they are no longer necessary.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject revokes his or her consent on which the processing was based pursuant to Art. 6(1)(a) DS-GVO or Art. 9(2)(a) DS-GVO and there is no other legal basis for the processing.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject objects to the processing pursuant to Article 21(1) DS-GVO and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) DS-GVO.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The personal data have been processed unlawfully.')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The erasure of the personal data is necessary for compliance with a legal obligation under Union or Member State law to which the controller is subject.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data has been collected in relation to information society services offered pursuant to Article 8(1) DS-GVO.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG shall arrange for the erasure request to be complied with immediately.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the personal data was collected by the PFZ GmbH & Co. KG has been made public and our company as the controller is obligated to delete the personal data pursuant to Art. 17 Para. 1 DS-GVO, the PFZ GmbH & Co. KG shall implement reasonable measures, including technical measures, taking into account the available technology and the costs of implementation, in order to inform other data controllers which process the published personal data that the data subject has requested from those other data controllers to erase all links to or copies or replications of the personal data, unless the processing is necessary. The employee of the PFZ GmbH & Co. KG will arrange the necessary in individual cases.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('e) Right to restriction of processing')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the restriction of processing where one of the following conditions is met:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The processing is unlawful, the data subject objects to the erasure of the personal data and requests instead the restriction of the use of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The controller no longer needs the personal data for the purposes of the processing, but the data subject needs it for the assertion, exercise or defense of legal claims.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject has objected to the processing pursuant to Article 21 (1) of the GDPR and it is not yet clear whether the legitimate grounds of the controller override those of the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG will arrange the restriction of the processing.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Right to data portability')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Each data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation, to obtain personal data concerning him or her, which has been provided by the data subject to a controller, in a structured, commonly used and machine-readable format. He or she also has the right to transmit such data to another controller without hindrance from the controller to whom the personal data have been provided, provided that the processing is based on consent pursuant to Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR or on a contract pursuant to Article 6(1)(b) of the GDPR and the processing is carried out by automated means, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, when exercising the right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to obtain that the personal data be transferred directly from one controller to another controller where technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to assert the right to data portability, the data subject may at any time contact any employee of the PFZ GmbH & Co. KG at any time.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('g) Right to object')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to object at any time, on grounds relating to his or her particular situation, to the processing of personal data concerning him or her carried out on the basis of Article 6(1)(e) or (f) of the GDPR. This also applies to profiling based on these provisions.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the assertion, exercise or defence of legal claims.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the PFZ GmbH & Co. KG processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data processed for such marketing. This also applies to the profiling, insofar as it is related to such direct marketing. If the data subject objects to PFZ GmbH & Co. KG to processing for the purposes of direct marketing, the PFZ GmbH & Co. KG will no longer process the personal data for these purposes.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her which is carried out by the PFZ GmbH & Co. KG for scientific or historical research purposes, or for statistical purposes pursuant to Article 89 (1) of the Data Protection Regulation (DS-GVO), unless such processing is necessary for the performance of a task carried out in the public interest.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to exercise the right to object, the data subject may directly contact any employee of the PFZ GmbH & Co. KG or another employee. The data subject is also free to exercise his/her right to object by means of automated procedures using technical specifications in connection with the use of information society services, notwithstanding Directive 2002/58/EC.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'h) Automated decisions in individual cases, including profiling'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data shall have the right, granted by the European Directive and Regulation, not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her, unless the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the controller, or (2) is permitted by Union or Member State law to which the controller is subject and that law contains suitable measures to safeguard the data subjects rights and freedoms and legitimate interests, or (3) is made with the data subjects explicit consent.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the data controller, or (2) it is made with the data subjects explicit consent, the PFZ GmbH & Co. KG will take reasonable steps to safeguard the rights and freedoms as well as the legitimate interests of the data subject, which at least include the right to obtain the intervention of a data subject on the part of the controller, to express his or her point of view and to contest the decision.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the rights concerning automated decisions, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('i) Right to withdraw consent under data protection law')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation Body, to withdraw his or her consent to the processing of personal data at any time.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the right to withdraw consent, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            10.{' '}
            {t(
              'Data protection during applications and the application process'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller collects and processes the personal data of applicants for the purpose of handling the application procedure. The processing may also take place electronically. This is particularly the case if an applicant submits relevant application documents to the controller by electronic means, for example by e-mail or via a web form located on the website. If the controller concludes an employment contract with an applicant, the transmitted data will be stored for the purpose of processing the employment relationship in compliance with the statutory provisions. If the controller does not conclude an employment contract with the applicant, the application documents will be automatically deleted two months after notification of the rejection decision, provided that no other legitimate interests of the controller conflict with such deletion. Other legitimate interest in this sense is, for example, a duty to provide evidence in proceedings under the General Equal Treatment Act (AGG).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            11.{' '}
            {t(
              'Data protection provisions on the use and application of Facebook'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of the company Facebook on this website. Facebook is a social network.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'A social network is a social meeting place operated on the Internet, an online community that generally allows users to communicate with each other and interact in virtual space. A social network can serve as a platform for sharing opinions and experiences or enables the Internet community to provide personal or company-related information. Among other things, Facebook enables users of the social network to create private profiles, upload photos and network via friend requests.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Facebook is Facebook, Inc, 1 Hacker Way, Menlo Park, CA 94025, USA. The controller of personal data, if a data subject lives outside the USA or Canada, is Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the data controller is called up and on which a Facebook component (Facebook plug-in) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Facebook component to download a representation of the corresponding Facebook component from Facebook. A complete overview of all Facebook plug-ins can be found at developers.facebook.com/docs/plugins/. Within the scope of this technical procedure, Facebook obtains knowledge of which specific sub-page of our website is visited by the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged in to Facebook at the same time, Facebook recognizes which specific subpage of our website the data subject is visiting each time the data subject calls up our website and for the entire duration of the respective stay on our website. This information is collected by the Facebook component and assigned by Facebook to the respective Facebook account of the data subject. If the data subject activates one of the Facebook buttons integrated on our website, for example the "Like" button, or if the data subject posts a comment, Facebook assigns this information to the personal Facebook user account of the data subject and stores this personal data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Facebook always receives information via the Facebook component that the data subject has visited our website if the data subject is simultaneously logged into Facebook at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Facebook component or not. If the data subject does not want this information to be transmitted to Facebook, he or she can prevent the transmission by logging out of his or her Facebook account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data policy published by Facebook, which can be accessed at de.facebook.com/about/privacy/, provides information about the collection, processing and use of personal data by Facebook. It also explains which setting options Facebook offers to protect the privacy of the data subject. In addition, various applications are available that allow data transmission to Facebook to be suppressed. Such applications can be used by the data subject to suppress data transmission to Facebook.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            12. {t('Privacy policy on the use and application of Twitter')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of Twitter on this website. Twitter is a multilingual publicly accessible microblogging service on which users can publish and distribute so-called tweets, i.e. short messages limited to 280 characters. These short messages can be accessed by anyone, including people who are not registered with Twitter. However, the tweets are also displayed to the so-called followers of the respective user. Followers are other Twitter users who follow the tweets of a user. Furthermore, Twitter enables a broad audience to be addressed via hashtags, links or retweets.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Twitter is Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the controller is called up and on which a Twitter component (Twitter button) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Twitter component to download a representation of the corresponding Twitter component from Twitter. Further information on the Twitter buttons can be found at about.twitter.com/en/resources/buttons. Within the scope of this technical procedure, Twitter receives knowledge of which specific sub-page of our website is visited by the data subject. The purpose of integrating the Twitter component is to enable our users to disseminate the content of this website, to make this website known in the digital world and to increase our visitor numbers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged into Twitter at the same time, Twitter recognizes which specific subpage of our website the data subject is visiting with each call to our website by the data subject and for the entire duration of the respective stay on our website. This information is collected by the Twitter component and assigned by Twitter to the respective Twitter account of the data subject. If the data subject activates one of the Twitter buttons integrated on our website, the data and information thus transmitted will be assigned to the personal Twitter user account of the data subject and stored and processed by Twitter.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Twitter always receives information via the Twitter component that the data subject has visited our website if the data subject is simultaneously logged into Twitter at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Twitter component or not. If the data subject does not want this information to be transmitted to Twitter, he or she can prevent the transmission by logging out of his or her Twitter account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The applicable data protection provisions of Twitter are available at twitter.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            13.{' '}
            {t('Privacy policy on the use and application of Google Analytics')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated the Google Analytics component on this website. Google Analytics is an open source software tool for web analysis. Web analysis is the collection, compilation and evaluation of data about the behavior of visitors to websites. Among other things, a web analysis tool collects data about the website from which a data subject came to a website (so-called referrer), which subpages of the website were accessed or how often and for how long a subpage was viewed. A web analysis is mainly used for optimizing a website and for cost-benefit analysis of internet advertising.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The software is operated on the server of the controller, the data protection sensitive log files are stored on Google servers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The purpose of the Google Analytics component is to analyze the flow of visitors to our website. The controller uses the data and information obtained, among other things, to evaluate the use of this website in order to compile online reports showing the activities on our websites.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Google Analytics sets a cookie on the information technology system of the data subject. What cookies are has already been explained above. The setting of the cookie enables us to analyze the use of our website. Each time one of the individual pages of this website is called up, the Internet browser on the information technology system of the data subject is automatically caused by the Google Analytics component to transmit data to our server for the purpose of online analysis. As part of this technical procedure, we obtain knowledge of personal data, such as the IP address of the data subject, which serves us, among other things, to trace the origin of visitors and clicks.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By means of the cookie, personal information, for example the access time, the place from which an access originated and the frequency of visits to our website are stored. Each time you visit our website, this personal data, including the IP address of the Internet connection used by the person concerned, is transmitted to our server. This personal data is stored by us. We do not pass on this personal data to third parties.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website, as already described above, at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Such a setting of the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, a cookie already set by Google Analytics can be deleted at any time via an Internet browser or other software programs.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject has the possibility to object to the collection of data generated by Google Analytics and related to a use of this website and to prevent such a collection. To do this, the data subject must set "Do Not Track" in your browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'However, with the setting of the opt-out cookie, there is the possibility that the Internet pages of the controller are no longer fully usable for the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Further information and the applicable privacy policy of Google Analytics can be found at policies.google.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            14. {t('Legal basis of processing')}
          </h3>
          <p className={s.text}>
            {t(
              'Art. 6 I lit. a DS-GVO serves our company as the legal basis for processing operations in which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for a delivery of goods or the provision of another service or consideration, the processing is based on Art. 6 I lit. b DS-GVO. The same applies to such processing operations that are necessary for the implementation of pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation by which a processing of personal data becomes necessary, such as for the fulfillment of tax obligations, the processing is based on Art. 6 I lit. c DS-GVO. In rare cases, the processing of personal data might become necessary to protect vital interests of the data subject or another natural person. This would be the case, for example, if a visitor were to be injured on our premises and as a result his or her name, age, health insurance data or other vital information had to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d DS-GVO. Finally, processing operations could be based on Art. 6 I lit. f DS-GVO. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if the processing is necessary to protect a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject are not overridden. Such processing operations are permitted to us in particular because they were specifically mentioned by the European legislator. In this respect, it took the view that a legitimate interest could be assumed if the data subject is a customer of the controller (recital 47 sentence 2 DS-GVO).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            15.
            {t(
              'Legitimate interests in the processing pursued by the controller or a third party'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'If the processing of personal data is based on Article 6 I lit. f DS-GVO, our legitimate interest is the conduct of our business for the benefit of the well-being of all our employees and our shareholders.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            16. {t('Duration for which the personal data are stored')}
          </h3>
          <p className={s.text}>
            {t(
              'The criterion for the duration of the storage of personal data is the respective statutory retention period. After expiry of the period, the corresponding data is routinely deleted, provided that it is no longer required for the fulfillment or initiation of the contract.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            17.{' '}
            {t(
              'Legal or contractual provisions for the provision of personal data; necessity for the conclusion of the contract; obligation of the data subject to provide the personal data; possible consequences of non-provision'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'We inform you that the provision of personal data is sometimes required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information on the contractual partner). Sometimes, in order to conclude a contract, it may be necessary for a data subject to provide us with personal data that must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data if our company concludes a contract with him or her. Failure to provide the personal data would mean that the contract with the data subject could not be concluded. Before providing personal data by the data subject, the data subject must contact one of our employees. Our employee will explain to the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data, and what the consequences of not providing the personal data would be.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            18. {t('Existence of automated decision making')}
          </h3>
          <p className={s.text}>
            {t(
              'As a responsible company, we do not use automated decision making or profiling.'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('SSL encryption')}</h3>
          <p className={s.text}>
            {t(
              'To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'You can recognize an encrypted connection by the "https://" address line of your browser and the lock symbol in the browser line.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Use of script libraries (Google Webfonts)')}
          </h3>
          <p className={s.text}>
            {t(
              'In order to display our content correctly and graphically appealing across browsers, we use script libraries and font libraries such as Google Webfonts (https://www.google.com/webfonts/) on this website. Google Web Fonts are transferred to your browsers cache to avoid multiple loading. If the browser does not support Google Web Fonts or prevents access, content is displayed in a standard font.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Calling up script libraries or font libraries automatically triggers a connection to the operator of the library. It is theoretically possible - although it is currently also unclear whether and, if so, for what purposes - that operators of corresponding libraries collect data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The privacy policy of the library operator Google can be found here: https://www.google.com/policies/privacy/'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('Use of Google Maps')}</h3>

          <p className={s.text}>
            {t(
              'This website uses Google Maps API to visually display geographical information. When using Google Maps, Google also collects, processes and uses data about visitors use of the map functions. You can find more information about data processing by Google in the Google privacy policy. There you can also change your personal privacy settings in the Privacy Center.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Detailed instructions on how to manage your own data in connection with Google products can be found here.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Changes to our privacy policy')}
          </h3>
          <p className={s.text}>
            {t(
              'We reserve the right to occasionally adapt this data protection statement so that it always complies with the current legal requirements or to implement changes to our services in the data protection statement, e.g. when introducing new services. The new data protection declaration will then apply to your next visit.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'This data protection declaration was created by the data protection declaration generator of DGD Deutsche Gesellschaft für Datenschutz GmbH, which acts as the External Data Protection Officer Bayreuth, in cooperation with the lawyer for IT and data protection law Christian Solmecke.'
            )}
          </p>
          <div className={s.containerTextConfirm}>
            {!isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(true)}
              >
                <Icon name="checkbox" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
            {isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(false)}
              >
                <Icon name="checkbox-fill" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
          </div>

          <button
            onClick={() => {
              navigate(-1);
            }}
            className={s.backToForm}
          >
            {t('back to form')}
          </button>
        </div>
      </div>

      <div className={i18n.language === 'en' ? s.container : s.containerDe}>
        <LeftTopSVG className={s.leftTopSvg} />
        <LeftBottomSVG className={s.leftBottomSvg} />
        <RightTopSVG className={s.rightTopSvg} />
        <RightBottomSVG className={s.rightBottomSvg} />
        <div className={s.wrapper}>
          <div className={s.containerTitle}>
            <Title
              svg1={source.title.desktop.svg2}
              svg2={source.title.desktop.svg1}
              svg3={source.title.desktop.svg3}
              animate={true}
            >
              <h2 className={i18n.language === 'en' ? s.title : s.titleDe}>
                {t('Privacy policy')}
              </h2>
            </Title>
          </div>
          <p className={s.text}>
            {t(
              'We are grateful for your interest in our company. Data protection is of a particularly high priority for the management of PFZ GmbH & Co. KG. The use of the Internet pages of the PFZ GmbH & Co. KG is possible without any indication of personal data. If a data subject wants to use special services of our enterprise via our website, however, processing of personal data could become necessary. If processing of personal data is necessary and there is no legal basis for such processing, we will generally obtain the consent of the data subject'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation, and in accordance with the country-specific data protection regulations applicable to the PFZ GmbH & Co. KG applicable country-specific data protection regulations. By means of this data protection declaration, our company would like to inform the public about the type, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed of their rights by means of this data protection declaration.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. Nevertheless, Internet-based data transmissions can always be vulnerable to security risks, so that absolute protection cannot be guaranteed. For this reason, every data subject is free to transmit personal data to us by alternative means, for example by telephone'
            )}
          </p>
          <h3 className={s.titleAccent}>1. {t('Definitions of terms')}</h3>
          <p className={s.text}>
            {t(
              'The data protection declaration of the PFZ GmbH & Co. KG is based on the terms used by the European Data Protection Supervisor when adopting the Data Protection Regulation (DS-GVO). Our privacy policy should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terminology used in advance.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'We use the following terms, among others, in this data protection declaration:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Personal data')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Personal data is any information relating to an identified or identifiable natural person (hereinafter "data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Data subject')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Data subject is any identified or identifiable natural person whose personal data are processed by the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processing is any operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('d) Restriction of processing')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Restriction of processing is the marking of stored personal data with the aim of limiting their future processing.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('e) Profiling')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Profiling is any type of automated processing of personal data that consists of using such personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that natural persons job performance, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Pseudonymization')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Pseudonymization is the processing of personal data in such a way that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separate and is subject to technical and organizational measures to ensure that the personal data is not attributed to an identified or identifiable natural person.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('g) Controller or person responsible for processing.')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Controller or person responsible for processing is the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing of personal data. Where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its designation may be provided for under Union or Member State law.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('h) Processor')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('i) Recipient')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Recipient is a natural or legal person, public authority, agency or other body to whom personal data are disclosed, whether or not it is a third party. However, public authorities that may receive Personal Data in the context of a specific investigative task under Union or Member State law shall not be considered as recipients'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('j) Third Party')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Third party is a natural or legal person, public authority, agency or other body other than the data subject, the controller, the processor and the persons authorized to process the personal data under the direct responsibility of the controller or the processor.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('k) Consent')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Consent is any freely given indication of the data subjects wishes for the specific case in an informed and unambiguous manner, in the form of a statement or other unambiguous affirmative act by which the data subject indicates that he or she consents to the processing of personal data relating to him or her.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            2. {t('Name and address of the controller')}
          </h3>
          <p className={s.text}>
            {t(
              'Responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the Member States of the European Union and other provisions with data protection character is:'
            )}
          </p>
          <p className={s.textContact}>{t('PFZ GmbH & Co. KG')}</p>
          <p className={s.textContact}>
            {t('Messerschmittstrasse 5, 78532 Tuttlingen')}
          </p>
          <p className={s.textContact}>{t('Germany')}</p>
          <p className={s.textContact}>{t('Phone: +49 (0) 7461 966 2999')}</p>
          <p className={s.textContact}>{t('Fax: +49 (0) 7461 966 3397')}</p>
          <p className={s.textContact}>{t('Email: info@pfz-cnc.com')}</p>
          <div className={s.flex}>
            <p className={s.textWeb}>{t('Website')}:</p>
            <a
              href="https://pfz-cnc.com"
              target="_blank"
              rel="noopener noreferrer"
              className={s.linkAccent}
            >
              {' '}
              https://pfz-cnc.com
            </a>
          </div>

          <h3 className={s.titleAccent}>
            3. {t('Name and address of the data protection officer')}
          </h3>
          <p className={s.text}>
            {t('The data protection officer of the controller is:')}
          </p>
          <p className={s.text}>{t('Manuel Wörner')}</p>
          <p className={s.text}>{t('E-mail: manuel.woerner@pfz-cnc.com')}</p>
          <p className={s.text}>
            {t(
              'Any data subject may contact our data protection officer directly at any time with any questions or suggestions regarding data protection.'
            )}
          </p>
          <h3 className={s.titleAccent}>4. Cookies</h3>
          <p className={s.text}>
            {t(
              'The websites of the PFZ GmbH & Co. KG use cookies. Cookies are text files that are stored on a computer system via an Internet browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Numerous Internet pages and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a string of characters by which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This enables the visited Internet pages and servers to distinguish the individual browser of the data subject from other Internet browsers that contain other cookies. A specific internet browser can be recognized and identified via the unique cookie ID.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the PFZ GmbH & Co. KG can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Through the use of cookies, the information and offers on our website can be optimized for the benefit of the user. Cookies enable us, as already mentioned, to recognize the users of our website. The purpose of this recognition is to make it easier for users to use our website. For example, the user of a website that uses cookies does not have to re-enter his or her access data each time he or she visits the website, because this is handled by the website and the cookie stored on the users computer system. Another example is the cookie of a shopping cart in an online store. The online store remembers the items that a customer has placed in the virtual shopping cart via a cookie'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via an Internet browser or other software programs. This is possible in all common Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be fully usable.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'More information about cookies: http://www.whatarecookies.com/'
            )}
          </p>

          <h3 className={s.titleAccent}>
            5. {t('Collection of general data and information')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of the PFZ GmbH & Co. KG collects a series of general data and information with each call-up of the website by a data subject or automated system. This general data and information is stored in the log files of the server. The following data may be collected: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrer), (4) the sub-websites that are accessed via an accessing system on our website, (5) the date and time of an access to the website, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) other similar data and information that serve to avert danger in the event of attacks on our information technology systems.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'When using these general data and information, the PFZ GmbH & Co. KG does not draw any conclusions about the data subject. Rather, this information is needed (1) to deliver the contents of our website correctly, (2) to optimize the contents of our website and the advertising for these, (3) to ensure the long-term functionality of our information technology systems and the technology of our website, and (4) to provide law enforcement authorities with the information necessary for prosecution in the event of a cyber attack. This anonymously collected data and information is used by the PFZ GmbH & Co. KG therefore statistically and moreover with the aim of increasing the data protection and data security of our enterprise, and ultimately with the aim of ensuring an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from any personal data provided by a data subject.'
            )}
          </p>

          <h3 className={s.titleAccent}>
            6. {t('Registration on our website')}
          </h3>
          <p className={s.text}>
            {t(
              'The data subject has the opportunity to register on the website of the controller by providing personal data. Which personal data are transmitted to the controller in the process results from the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller and for its own purposes. The controller may arrange for the data to be transferred to one or more processors, for example a parcel service provider, who will also use the personal data exclusively for an internal use attributable to the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By registering on the website of the controller, the IP address assigned by the Internet service provider (ISP) of the data subject, the date as well as the time of registration are also stored. The storage of this data takes place against the background that only in this way can the misuse of our services be prevented and, if necessary, this data makes it possible to clarify committed crimes. In this respect, the storage of this data is necessary for the protection of the data controller. As a matter of principle, this data is not passed on to third parties unless there is a legal obligation to pass it on or the passing on serves the purpose of criminal prosecution.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The registration of the data subject by voluntarily providing personal data serves the purpose of the controller to offer the data subject content or services which, due to the nature of the matter, can only be offered to registered users. Registered persons are free to modify the personal data provided during registration at any time or to have it completely deleted from the data stock of the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The controller shall provide any data subject at any time upon request with information on what personal data is stored about the data subject. Furthermore, the controller shall correct or delete personal data at the request or indication of the data subject, provided that this does not conflict with any statutory retention obligations. The entire staff of the controller shall be available to the data subject as contact persons in this context.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            7. {t('Contact possibility via the internet page')}
          </h3>
          <p className={s.text}>
            {t(
              'The website of PFZ GmbH & Co. KG contains legal requirements which enable a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or by using a contact form, the personal data transmitted by the data subject will be stored automatically. Such personal data transmitted on a voluntary basis by a data subject to the controller will be stored for the purpose of processing or contacting the data subject. No disclosure of such personal data to third parties will take place.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            8. {t('Routine erasure and blocking of personal data')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller processes and stores personal data of the data subject only for the period of time necessary to achieve the purpose of storage or insofar as this has been provided for by the European Directives and Regulations or other legislator in laws or regulations to which the controller is subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the storage purpose ceases to apply or if a storage period prescribed by the European Directive and Regulation Maker or another competent legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            9. {t('Rights of the data subject')}
          </h3>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('a) Right to confirmation')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every data subject has the right, granted by the European Directive and Regulation, to obtain confirmation from the controller as to whether personal data concerning him or her are being processed. If a data subject wishes to exercise this right of confirmation, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>

          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('b) Right of access')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain at any time from the controller, free of charge, information about the personal data stored about him or her and a copy of that information. In addition, the European Directive and Regulation Legislator has granted the data subject access to the following information:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('The purposes of processing')}</p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The categories of personal data processed')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular in the case of recipients in third countries or international organizations'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right to obtain the rectification or erasure of personal data concerning him or her, or to obtain the restriction of processing by the controller, or a right to object to such processing'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of a right of appeal to a supervisory authority'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'If the personal data are not collected from the data subject: Any available information about the origin of the data'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject shall have the right to obtain information as to whether personal data have been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to obtain information about the appropriate safeguards in connection with the transfer.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of access, he or she may, at any time, contact an employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('c) Right to rectification')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Every person affected by the processing of personal data has the right granted by the European Directive and Regulation to demand the immediate rectification of any inaccurate personal data concerning him or her. Furthermore, the data subject has the right to request the completion of incomplete personal data - also by means of a supplementary declaration - taking into account the purposes of the processing.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If a data subject wishes to exercise this right of rectification, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('d) Right to erasure (right to be forgotten)')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the erasure without delay of personal data concerning him or her, where one of the following grounds applies and insofar as the processing is not necessary:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data were collected or otherwise processed for such purposes for which they are no longer necessary.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject revokes his or her consent on which the processing was based pursuant to Art. 6(1)(a) DS-GVO or Art. 9(2)(a) DS-GVO and there is no other legal basis for the processing.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject objects to the processing pursuant to Article 21(1) DS-GVO and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) DS-GVO.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('The personal data have been processed unlawfully.')}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The erasure of the personal data is necessary for compliance with a legal obligation under Union or Member State law to which the controller is subject.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The personal data has been collected in relation to information society services offered pursuant to Article 8(1) DS-GVO.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG shall arrange for the erasure request to be complied with immediately.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the personal data was collected by the PFZ GmbH & Co. KG has been made public and our company as the controller is obligated to delete the personal data pursuant to Art. 17 Para. 1 DS-GVO, the PFZ GmbH & Co. KG shall implement reasonable measures, including technical measures, taking into account the available technology and the costs of implementation, in order to inform other data controllers which process the published personal data that the data subject has requested from those other data controllers to erase all links to or copies or replications of the personal data, unless the processing is necessary. The employee of the PFZ GmbH & Co. KG will arrange the necessary in individual cases.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('e) Right to restriction of processing')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the restriction of processing where one of the following conditions is met:'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The processing is unlawful, the data subject objects to the erasure of the personal data and requests instead the restriction of the use of the personal data.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The controller no longer needs the personal data for the purposes of the processing, but the data subject needs it for the assertion, exercise or defense of legal claims.'
              )}
            </p>
          </div>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'The data subject has objected to the processing pursuant to Article 21 (1) of the GDPR and it is not yet clear whether the legitimate grounds of the controller override those of the data subject.'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of personal data stored by the PFZ GmbH & Co. KG, he or she may, at any time, contact any employee of the controller. The employee of the PFZ GmbH & Co. KG will arrange the restriction of the processing.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('f) Right to data portability')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Each data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation, to obtain personal data concerning him or her, which has been provided by the data subject to a controller, in a structured, commonly used and machine-readable format. He or she also has the right to transmit such data to another controller without hindrance from the controller to whom the personal data have been provided, provided that the processing is based on consent pursuant to Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR or on a contract pursuant to Article 6(1)(b) of the GDPR and the processing is carried out by automated means, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, when exercising the right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to obtain that the personal data be transferred directly from one controller to another controller where technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to assert the right to data portability, the data subject may at any time contact any employee of the PFZ GmbH & Co. KG at any time.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>{t('g) Right to object')}</p>
          </div>
          <p className={s.text}>
            {t(
              'Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to object at any time, on grounds relating to his or her particular situation, to the processing of personal data concerning him or her carried out on the basis of Article 6(1)(e) or (f) of the GDPR. This also applies to profiling based on these provisions.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The PFZ GmbH & Co. KG shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the assertion, exercise or defence of legal claims.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the PFZ GmbH & Co. KG processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data processed for such marketing. This also applies to the profiling, insofar as it is related to such direct marketing. If the data subject objects to PFZ GmbH & Co. KG to processing for the purposes of direct marketing, the PFZ GmbH & Co. KG will no longer process the personal data for these purposes.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her which is carried out by the PFZ GmbH & Co. KG for scientific or historical research purposes, or for statistical purposes pursuant to Article 89 (1) of the Data Protection Regulation (DS-GVO), unless such processing is necessary for the performance of a task carried out in the public interest.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'In order to exercise the right to object, the data subject may directly contact any employee of the PFZ GmbH & Co. KG or another employee. The data subject is also free to exercise his/her right to object by means of automated procedures using technical specifications in connection with the use of information society services, notwithstanding Directive 2002/58/EC.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t(
                'h) Automated decisions in individual cases, including profiling'
              )}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data shall have the right, granted by the European Directive and Regulation, not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her, unless the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the controller, or (2) is permitted by Union or Member State law to which the controller is subject and that law contains suitable measures to safeguard the data subjects rights and freedoms and legitimate interests, or (3) is made with the data subjects explicit consent.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the data controller, or (2) it is made with the data subjects explicit consent, the PFZ GmbH & Co. KG will take reasonable steps to safeguard the rights and freedoms as well as the legitimate interests of the data subject, which at least include the right to obtain the intervention of a data subject on the part of the controller, to express his or her point of view and to contest the decision.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the rights concerning automated decisions, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <div className={s.containerMarker}>
            <Marker top={4} ind={0} size={16} />
            <p className={s.textList}>
              {t('i) Right to withdraw consent under data protection law')}
            </p>
          </div>
          <p className={s.text}>
            {t(
              'Any data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation Body, to withdraw his or her consent to the processing of personal data at any time.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject wishes to exercise the right to withdraw consent, he or she may, at any time, contact any employee of the controller.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            10.{' '}
            {t(
              'Data protection during applications and the application process'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller collects and processes the personal data of applicants for the purpose of handling the application procedure. The processing may also take place electronically. This is particularly the case if an applicant submits relevant application documents to the controller by electronic means, for example by e-mail or via a web form located on the website. If the controller concludes an employment contract with an applicant, the transmitted data will be stored for the purpose of processing the employment relationship in compliance with the statutory provisions. If the controller does not conclude an employment contract with the applicant, the application documents will be automatically deleted two months after notification of the rejection decision, provided that no other legitimate interests of the controller conflict with such deletion. Other legitimate interest in this sense is, for example, a duty to provide evidence in proceedings under the General Equal Treatment Act (AGG).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            11.{' '}
            {t(
              'Data protection provisions on the use and application of Facebook'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of the company Facebook on this website. Facebook is a social network.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'A social network is a social meeting place operated on the Internet, an online community that generally allows users to communicate with each other and interact in virtual space. A social network can serve as a platform for sharing opinions and experiences or enables the Internet community to provide personal or company-related information. Among other things, Facebook enables users of the social network to create private profiles, upload photos and network via friend requests.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Facebook is Facebook, Inc, 1 Hacker Way, Menlo Park, CA 94025, USA. The controller of personal data, if a data subject lives outside the USA or Canada, is Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the data controller is called up and on which a Facebook component (Facebook plug-in) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Facebook component to download a representation of the corresponding Facebook component from Facebook. A complete overview of all Facebook plug-ins can be found at developers.facebook.com/docs/plugins/. Within the scope of this technical procedure, Facebook obtains knowledge of which specific sub-page of our website is visited by the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged in to Facebook at the same time, Facebook recognizes which specific subpage of our website the data subject is visiting each time the data subject calls up our website and for the entire duration of the respective stay on our website. This information is collected by the Facebook component and assigned by Facebook to the respective Facebook account of the data subject. If the data subject activates one of the Facebook buttons integrated on our website, for example the "Like" button, or if the data subject posts a comment, Facebook assigns this information to the personal Facebook user account of the data subject and stores this personal data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Facebook always receives information via the Facebook component that the data subject has visited our website if the data subject is simultaneously logged into Facebook at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Facebook component or not. If the data subject does not want this information to be transmitted to Facebook, he or she can prevent the transmission by logging out of his or her Facebook account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data policy published by Facebook, which can be accessed at de.facebook.com/about/privacy/, provides information about the collection, processing and use of personal data by Facebook. It also explains which setting options Facebook offers to protect the privacy of the data subject. In addition, various applications are available that allow data transmission to Facebook to be suppressed. Such applications can be used by the data subject to suppress data transmission to Facebook.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            12. {t('Privacy policy on the use and application of Twitter')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated components of Twitter on this website. Twitter is a multilingual publicly accessible microblogging service on which users can publish and distribute so-called tweets, i.e. short messages limited to 280 characters. These short messages can be accessed by anyone, including people who are not registered with Twitter. However, the tweets are also displayed to the so-called followers of the respective user. Followers are other Twitter users who follow the tweets of a user. Furthermore, Twitter enables a broad audience to be addressed via hashtags, links or retweets.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The operating company of Twitter is Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Each time one of the individual pages of this website operated by the controller is called up and on which a Twitter component (Twitter button) has been integrated, the Internet browser on the information technology system of the data subject is automatically caused by the respective Twitter component to download a representation of the corresponding Twitter component from Twitter. Further information on the Twitter buttons can be found at about.twitter.com/en/resources/buttons. Within the scope of this technical procedure, Twitter receives knowledge of which specific sub-page of our website is visited by the data subject. The purpose of integrating the Twitter component is to enable our users to disseminate the content of this website, to make this website known in the digital world and to increase our visitor numbers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'If the data subject is logged into Twitter at the same time, Twitter recognizes which specific subpage of our website the data subject is visiting with each call to our website by the data subject and for the entire duration of the respective stay on our website. This information is collected by the Twitter component and assigned by Twitter to the respective Twitter account of the data subject. If the data subject activates one of the Twitter buttons integrated on our website, the data and information thus transmitted will be assigned to the personal Twitter user account of the data subject and stored and processed by Twitter.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Twitter always receives information via the Twitter component that the data subject has visited our website if the data subject is simultaneously logged into Twitter at the time of calling up our website; this takes place regardless of whether the data subject clicks on the Twitter component or not. If the data subject does not want this information to be transmitted to Twitter, he or she can prevent the transmission by logging out of his or her Twitter account before accessing our website.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The applicable data protection provisions of Twitter are available at twitter.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            13.{' '}
            {t('Privacy policy on the use and application of Google Analytics')}
          </h3>
          <p className={s.text}>
            {t(
              'The controller has integrated the Google Analytics component on this website. Google Analytics is an open source software tool for web analysis. Web analysis is the collection, compilation and evaluation of data about the behavior of visitors to websites. Among other things, a web analysis tool collects data about the website from which a data subject came to a website (so-called referrer), which subpages of the website were accessed or how often and for how long a subpage was viewed. A web analysis is mainly used for optimizing a website and for cost-benefit analysis of internet advertising.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The software is operated on the server of the controller, the data protection sensitive log files are stored on Google servers.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The purpose of the Google Analytics component is to analyze the flow of visitors to our website. The controller uses the data and information obtained, among other things, to evaluate the use of this website in order to compile online reports showing the activities on our websites.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Google Analytics sets a cookie on the information technology system of the data subject. What cookies are has already been explained above. The setting of the cookie enables us to analyze the use of our website. Each time one of the individual pages of this website is called up, the Internet browser on the information technology system of the data subject is automatically caused by the Google Analytics component to transmit data to our server for the purpose of online analysis. As part of this technical procedure, we obtain knowledge of personal data, such as the IP address of the data subject, which serves us, among other things, to trace the origin of visitors and clicks.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'By means of the cookie, personal information, for example the access time, the place from which an access originated and the frequency of visits to our website are stored. Each time you visit our website, this personal data, including the IP address of the Internet connection used by the person concerned, is transmitted to our server. This personal data is stored by us. We do not pass on this personal data to third parties.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The data subject can prevent the setting of cookies by our website, as already described above, at any time by means of an appropriate setting of the Internet browser used and thus permanently object to the setting of cookies. Such a setting of the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, a cookie already set by Google Analytics can be deleted at any time via an Internet browser or other software programs.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Furthermore, the data subject has the possibility to object to the collection of data generated by Google Analytics and related to a use of this website and to prevent such a collection. To do this, the data subject must set "Do Not Track" in your browser.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'However, with the setting of the opt-out cookie, there is the possibility that the Internet pages of the controller are no longer fully usable for the data subject.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Further information and the applicable privacy policy of Google Analytics can be found at policies.google.com/privacy.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            14. {t('Legal basis of processing')}
          </h3>
          <p className={s.text}>
            {t(
              'Art. 6 I lit. a DS-GVO serves our company as the legal basis for processing operations in which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for a delivery of goods or the provision of another service or consideration, the processing is based on Art. 6 I lit. b DS-GVO. The same applies to such processing operations that are necessary for the implementation of pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation by which a processing of personal data becomes necessary, such as for the fulfillment of tax obligations, the processing is based on Art. 6 I lit. c DS-GVO. In rare cases, the processing of personal data might become necessary to protect vital interests of the data subject or another natural person. This would be the case, for example, if a visitor were to be injured on our premises and as a result his or her name, age, health insurance data or other vital information had to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d DS-GVO. Finally, processing operations could be based on Art. 6 I lit. f DS-GVO. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if the processing is necessary to protect a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject are not overridden. Such processing operations are permitted to us in particular because they were specifically mentioned by the European legislator. In this respect, it took the view that a legitimate interest could be assumed if the data subject is a customer of the controller (recital 47 sentence 2 DS-GVO).'
            )}
          </p>
          <h3 className={s.titleAccent}>
            15.
            {t(
              'Legitimate interests in the processing pursued by the controller or a third party'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'If the processing of personal data is based on Article 6 I lit. f DS-GVO, our legitimate interest is the conduct of our business for the benefit of the well-being of all our employees and our shareholders.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            16. {t('Duration for which the personal data are stored')}
          </h3>
          <p className={s.text}>
            {t(
              'The criterion for the duration of the storage of personal data is the respective statutory retention period. After expiry of the period, the corresponding data is routinely deleted, provided that it is no longer required for the fulfillment or initiation of the contract.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            17.{' '}
            {t(
              'Legal or contractual provisions for the provision of personal data; necessity for the conclusion of the contract; obligation of the data subject to provide the personal data; possible consequences of non-provision'
            )}
          </h3>
          <p className={s.text}>
            {t(
              'We inform you that the provision of personal data is sometimes required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information on the contractual partner). Sometimes, in order to conclude a contract, it may be necessary for a data subject to provide us with personal data that must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data if our company concludes a contract with him or her. Failure to provide the personal data would mean that the contract with the data subject could not be concluded. Before providing personal data by the data subject, the data subject must contact one of our employees. Our employee will explain to the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data, and what the consequences of not providing the personal data would be.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            18. {t('Existence of automated decision making')}
          </h3>
          <p className={s.text}>
            {t(
              'As a responsible company, we do not use automated decision making or profiling.'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('SSL encryption')}</h3>
          <p className={s.text}>
            {t(
              'To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'You can recognize an encrypted connection by the "https://" address line of your browser and the lock symbol in the browser line.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Use of script libraries (Google Webfonts)')}
          </h3>
          <p className={s.text}>
            {t(
              'In order to display our content correctly and graphically appealing across browsers, we use script libraries and font libraries such as Google Webfonts (https://www.google.com/webfonts/) on this website. Google Web Fonts are transferred to your browsers cache to avoid multiple loading. If the browser does not support Google Web Fonts or prevents access, content is displayed in a standard font.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Calling up script libraries or font libraries automatically triggers a connection to the operator of the library. It is theoretically possible - although it is currently also unclear whether and, if so, for what purposes - that operators of corresponding libraries collect data.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'The privacy policy of the library operator Google can be found here: https://www.google.com/policies/privacy/'
            )}
          </p>
          <h3 className={s.titleAccent}>{t('Use of Google Maps')}</h3>

          <p className={s.text}>
            {t(
              'This website uses Google Maps API to visually display geographical information. When using Google Maps, Google also collects, processes and uses data about visitors use of the map functions. You can find more information about data processing by Google in the Google privacy policy. There you can also change your personal privacy settings in the Privacy Center.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'Detailed instructions on how to manage your own data in connection with Google products can be found here.'
            )}
          </p>
          <h3 className={s.titleAccent}>
            {t('Changes to our privacy policy')}
          </h3>
          <p className={s.text}>
            {t(
              'We reserve the right to occasionally adapt this data protection statement so that it always complies with the current legal requirements or to implement changes to our services in the data protection statement, e.g. when introducing new services. The new data protection declaration will then apply to your next visit.'
            )}
          </p>
          <p className={s.text}>
            {t(
              'This data protection declaration was created by the data protection declaration generator of DGD Deutsche Gesellschaft für Datenschutz GmbH, which acts as the External Data Protection Officer Bayreuth, in cooperation with the lawyer for IT and data protection law Christian Solmecke.'
            )}
          </p>
          <div className={s.containerTextConfirm}>
            {!isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(true)}
              >
                <Icon name="checkbox" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
            {isActiveCheckbox && (
              <div
                className={s.containerAgree}
                onClick={() => setIsActiveCheckbox(false)}
              >
                <Icon name="checkbox-fill" size={22} />
                <button className={s.buttonAgree}>
                  I have read and agree to{' '}
                  <span className={s.buttonAgreeText}>Privacy Policy</span>
                </button>
              </div>
            )}
          </div>

          <button
            onClick={() => {
              navigate(-1);
            }}
            className={s.backToForm}
          >
            {t('back to form')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
