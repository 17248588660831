import Footer from '../footer';
import BackToBottomButton from '../backToBottomButton/backToBottomButton';
import PrivacyPolicy from '../privacyPolicy';

export const Policy = ({ isActiveCheckbox, setIsActiveCheckbox }) => (
  <>
    <PrivacyPolicy
      setIsActiveCheckbox={setIsActiveCheckbox}
      isActiveCheckbox={isActiveCheckbox}
    />
    <Footer />
    <BackToBottomButton />
  </>
);
