import React from 'react';
import { useGLTF } from '@react-three/drei';

export function Model(props) {
  const { nodes, materials } = useGLTF('/model.glb');
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.9, 0]} rotation={[10.2, 9.8, 34.5]}>
        <group scale={0.2}>
          <mesh
            geometry={nodes['4002356_001_1'].geometry}
            material={materials.Material__84}
          />
          <mesh
            geometry={nodes['4002356_001_2'].geometry}
            material={materials.Metal}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/model.glb');
