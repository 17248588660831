import { useTranslation } from 'react-i18next';
import s from './quality.module.scss';
import picture from '../../images/certEN.png';
import pictureDE from '../../images/certDE.png';
import pictureWebp from '../../images/certEN.webp';
import pictureWebpDE from '../../images/certDE.webp';
import certificateEN from '../../images/certEN.pdf';
import certificateDE from '../../images/certDE.pdf';
import Title from 'components/molecules/title/Title';

const source = {
  title: {
    desktop: {
      svg1: 'quality-titlevector2',
      svg2: 'quality-titlevector1',
      svg3: 'quality-titlevector3',
    },
    tablet: {
      svg1: 'quality-tablet-titlevector2',
      svg2: 'quality-tablet-titlevector1',
      svg3: 'quality-tablet-titlevector3',
    },
    mobile: {
      svg1: 'quality-mobile-titlevector2',
      svg2: 'quality-mobile-titlevector1',
      svg3: 'quality-mobile-titlevector3',
    },
  },
};

const Quality = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={s.container}>
        <div id="quality" className={s.linkId}></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.desktop.svg1}
            svg2={source.title.desktop.svg2}
            svg3={source.title.desktop.svg3}
          >
            <h2 className={s.title}>
              {t('QUALITY')} {t('IS OUR TOP PRIORITY')}
            </h2>
          </Title>
        </div>

        <div className={s.wrapper}>
          <div className={s.qualityLeftPart}>
            <div className={s.textWrapper}>
              <div className={s.text}>
                <p className={s.textLineOne}>
                  {t('The quality management of our')}
                </p>
                <p className={s.textLineTwo}>DIN EN ISO 9001:2015</p>
                <p className={s.textLineTwo}>DIN EN ISO 13485:2016</p>
                <div className={s.line}></div>
                <p className={s.textLineThree}>
                  {t(
                    'certified company ensures consistent dimensional accuracy and reliability.'
                  )}
                </p>

                <p className={s.textLineFour}>
                  {t(
                    'We develop and produce absolutely everything in Germany.'
                  )}
                </p>
              </div>
            </div>
            <a
              className={s.button}
              target="_blank"
              rel="noopener noreferrer"
              href={i18n.language === 'de' ? certificateDE : certificateEN}
            >
              <span className={s.buttonText}>{t('Download certificates')}</span>
            </a>
          </div>
          <picture className={s.certificate}>
            <a
              className={s.certificateLink}
              target="_blank"
              rel="noopener noreferrer"
              href={i18n.language === 'de' ? certificateDE : certificateEN}
            >
              <source
                srcSet={i18n.language === 'de' ? pictureWebpDE : pictureWebp}
                type="image/webp"
              />
              <img
                src={i18n.language === 'de' ? pictureDE : picture}
                alt="Quality Certificates"
                height="478"
                width="543"
              />
            </a>
          </picture>
        </div>
      </div>
      <div className={s.containerMobile}>
        <div id="quality_m" className={s.linkId}></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.mobile.svg2}
            svg2={source.title.mobile.svg1}
            svg3={source.title.mobile.svg3}
            top1={18}
            left1={-3}
            left2={3}
          >
            <h2 className={s.title}>
              {t('QUALITY')}
              {t('IS OUR TOP PRIORITY')}
            </h2>
          </Title>
        </div>

        <div className={s.wrapper}>
          <div className={s.qualityLeftPart}>
            <div className={s.textWrapper}>
              <div className={s.text}>
                <p className={s.textLineOne}>
                  {t('The quality management of our')}
                </p>
                <p className={s.textLineTwo}>DIN EN ISO 9001:2015</p>
                <p className={s.textLineTwo}>DIN EN ISO 13485:2016</p>
                <div className={s.line}></div>
                <p className={s.textLineThree}>
                  {t(
                    'certified company ensures consistent dimensional accuracy and reliability.'
                  )}
                </p>

                <p className={s.textLineFour}>
                  {t(
                    'We develop and produce absolutely everything in Germany.'
                  )}
                </p>
              </div>
            </div>
            <a
              className={s.button}
              target="_blank"
              rel="noopener noreferrer"
              href={i18n.language === 'de' ? certificateDE : certificateEN}
            >
              <span className={s.buttonText}>{t('Download certificates')}</span>
            </a>
          </div>
        </div>
      </div>
      <div className={s.containerTablet}>
        <div id="quality_t" className={s.linkId}></div>
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.tablet.svg1}
            svg2={source.title.tablet.svg2}
            svg3={source.title.tablet.svg3}
            top2={26}
            top1={46}
          >
            <h2 className={s.title}>
              {t('QUALITY')}
              {t('IS OUR TOP PRIORITY')}
            </h2>
          </Title>
        </div>

        <div className={s.wrapper}>
          <div className={s.qualityLeftPart}>
            <div className={s.textWrapper}>
              <div className={s.text}>
                <p className={s.textLineOne}>
                  {t('The quality management of our')}
                </p>
                <p className={s.textLineTwo}>DIN EN ISO 9001:2015</p>
                <p className={s.textLineTwo}>DIN EN ISO 13485:2016</p>
                <div className={s.line}></div>
                <p className={s.textLineThree}>
                  {t(
                    'certified company ensures consistent dimensional accuracy and reliability.'
                  )}
                </p>

                <p className={s.textLineFour}>
                  {t(
                    'We develop and produce absolutely everything in Germany.'
                  )}
                </p>
              </div>
            </div>
          </div>
          <a
            className={s.button}
            target="_blank"
            rel="noopener noreferrer"
            href={i18n.language === 'de' ? certificateDE : certificateEN}
          >
            <span className={s.buttonText}>{t('Download certificates')}</span>
          </a>
          <picture className={s.certificate}>
            <source
              srcSet={i18n.language === 'de' ? pictureWebpDE : pictureWebp}
              type="image/webp"
            />
            <a
              className={s.certificateLink}
              href={i18n.language === 'de' ? certificateDE : certificateEN}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={i18n.language === 'de' ? pictureDE : picture}
                alt="Quality Certificates"
                height="313"
                width="357"
              />
            </a>
          </picture>
        </div>
      </div>
    </>
  );
};
export default Quality;
