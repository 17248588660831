import axios from 'axios';

const domain = window.location.host.toLocaleLowerCase().includes('de')
  ? 'de'
  : window.location.host.toLocaleLowerCase().includes('com')
  ? 'com'
  : 'tech';

export const api = axios.create({
  baseURL: `https://pfz-cnc.${domain}/`,
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
  },
});
