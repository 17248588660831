import React from 'react';
import sprite from '../../../images/sprite.svg';

const Icon = ({
  name,
  size = 24,
  className,
  onClick,
  style,
  width,
  height,
}) => (
  <svg
    width={width || size}
    height={height || size}
    className={className}
    // viewBox="0 0 20 20"
    onClick={onClick}
    style={style}
  >
    <use href={`${sprite}#${name}`} />
  </svg>
);

export default Icon;
