import react, { useState, useEffect } from 'react';
import s from './laserCuttingHero.module.scss';
import { useTranslation } from 'react-i18next';

import TitleHero from 'components/molecules/titleHero/titleHero';

const source = {
  title: {
    desktop: {
      svg1: 'laser-vector3',
      svg2: 'laser-vector2',
      svg3: 'laser-vector111',
    },
    tablet: {
      svg1: 'laser-tablet-vector3',
      svg2: 'laser-tablet-vector2',
      svg3: 'laser-tablet-vector111',
    },
    mobile: {
      svg1: 'laser-mobile-vector3',
      svg2: 'laser-mobile-vector2',
      svg3: 'laser-mobile-vector111',
    },
  },
};

const LaserCuttingHero = ({ currentSlide }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      <div className={s.mainContainer}>
        <div className={s.container}>
          <div className={currentSlide === 1 ? s.visible : s.hide}>
            <div className={s.containerTitle}>
              <TitleHero
                svg1={source.title.desktop.svg2}
                svg2={source.title.desktop.svg1}
                svg3={source.title.desktop.svg3}
                top="0"
                left1="-400"
                top2="-25"
                left2="-397"
                left3="22"
                top3="-135"
              >
                <h2 className={s.title}>
                  {t('Our CNC laser cutting and laser')}{' '}
                </h2>
                <h2 className={s.title}>
                  {t('welding machining can be used for processing materials')}
                </h2>
              </TitleHero>
            </div>
            <div className={s.containerDescription}>
              <p className={s.description}>
                {t('with a thickness of 0.5 to 5 mm')}
              </p>
              <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
            </div>
          </div>
        </div>
        <div className={s.containerTablet}>
          <div className={currentSlide === 1 ? s.visible : s.hide}>
            <div className={s.containerTitle}>
              <TitleHero
                svg1={source.title.tablet.svg2}
                svg2={source.title.tablet.svg1}
                svg3={source.title.tablet.svg3}
                top="-4"
                left1="-1040"
                top2="-17"
                left2="-1030"
                left3="-130"
                top3="-80"
              >
                <h2 className={s.title}>{t('Our CNC laser cutting')}</h2>
                <h2 className={s.title}>
                  {t('and laser welding machining can')}
                </h2>
                <h2 className={s.title}>
                  {t('be used for processing materials')}
                </h2>
              </TitleHero>
            </div>
            <div className={s.containerDescription}>
              <p className={s.description}>
                {t('with a thickness of 0.5 to 5 mm')}
              </p>
              <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
            </div>
          </div>
        </div>
        <div className={s.containerMobile}>
          <div className={currentSlide === 1 ? s.visible : s.hide}>
            <div className={s.containerTitle}>
              <TitleHero
                svg1={source.title.mobile.svg2}
                svg2={source.title.mobile.svg1}
                svg3={source.title.mobile.svg3}
                top="0"
                left1="-327"
                top2="-11"
                left2="-350"
                left3="-100"
                top3="-35"
              >
                <h2 className={s.title}>{t('Our CNC laser cutting')}</h2>
                <h2 className={s.title}>
                  {t('and laser welding machining can')}
                </h2>
                <h2 className={s.title}>
                  {t('be used for processing materials')}
                </h2>
              </TitleHero>
            </div>
            <div className={s.containerDescription}>
              <p className={s.description}>
                {t('with a thickness of 0.5 to 5 mm')}
              </p>
              <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaserCuttingHero;
