import s from './FileInput.module.scss';
import gif from '../../../images/loading.gif';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon';

export const FileInput = ({
  uploadedFileName,
  setUploadedFileName,
  register,
  required,
  error,
  id,
  reset,
  isUploading,
  accept,
}) => {
  const { t } = useTranslation();

  const errorStyles = error && !uploadedFileName ? { borderColor: 'red' } : {};

  return (
    <div className={s.upload}>
      <div className={s.innerText} style={errorStyles}>
        {isUploading && <img alt="gif" src={gif}></img>}
        {uploadedFileName && (
          <Icon size={86} name="uploadDone" className={s.iconUpload} />
        )}
        {!uploadedFileName && (
          <Icon size={86} name="upload" className={s.iconUpload} />
        )}
        <div className={s.textUpload}>
          {!uploadedFileName && (
            <>
              {' '}
              <p className={s.textAccent}>
                {t('Drag and drop it here or click on the button.')}
              </p>
              <p className={s.textAccent}>{t('Maximum file size: 80MB')}</p>
            </>
          )}
          {uploadedFileName && (
            <div className={s.containerFile}>
              <div>
                <p className={s.textAccent}>{t(uploadedFileName)}</p>
                {/* <p className={s.textAccentUpload}>File uploaded. 100%</p> */}
              </div>
              <Icon
                size={30}
                name="basket"
                className={s.iconBasket}
                onClick={() => {
                  reset();
                }}
              />
            </div>
          )}
        </div>
      </div>
      <label className={s.label}>
        <input
          {...register(id, { required })}
          type="file"
          accept={accept}
          className={s.uploadFileInput}
          onChange={e => {
            const name = e?.target?.value?.length > 0 && e.target.value;

            name &&
              setUploadedFileName(
                name.split('\\')[name.split('\\').length - 1]
              );
          }}
        />
      </label>
    </div>
  );
};
