import react, { useState, useEffect } from 'react';
import s from './footer.module.scss';
import Icon from '../atoms/icon';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const linkToMap = 'https://goo.gl/maps/XQ5F5S5LPVfWE4G3A';

const data = [
  { href: 'https://linkedin.com/company/pfz-peuker/', icon: 'linkedin' },
  { href: 'https://facebook.com', icon: 'facebook' },
  { href: 'https://instagram.com', icon: 'instagram' },
  { href: 'tel:+49074619663397', icon: 'phone' },
  { href: 'mailto: info@pfz-cnc.com', icon: 'envelope' },
];

const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation(useLocation);
  const date = new Date().getFullYear();
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    }
    if (!isModalVisible) {
      document.body.style.overflow = 'visible';
    }
  }, [isModalVisible]);

  return (
    <>
      {isModalVisible && (
        <div
          className={s.backdrop}
          onClick={() => {
            setIsModalVisible(false);
          }}
        >
          {' '}
          <Icon name="icon-close-menu" size={30} className={s.closeIcon} />
          <div className={s.backdropWrapper}>
            <div
              className={
                i18n.language === 'de' ? s.contactDetailsDe : s.contactDetails
              }
            >
              <p className={s.imgTextAccent}>{t('Impressum')}</p>

              <p className={s.contactText}>{t('PFZ GmbH & Co. KG')}</p>
              <a
                href={linkToMap}
                target="_blank"
                rel="noopener noreferrer"
                className={s.containerContactText}
              >
                <p className={s.contactText}>
                  {t('Messerschmittstrasse 5, 78532 Tuttlingen, Germany')}
                </p>
              </a>
              <div className={s.line1}></div>
              <div className={s.wrapperModalText}>
                <div className={s.contactCentral}>
                  <p className={s.contactText}>Tel:</p>
                  <p className={s.contactText}>{t('email: ')}</p>

                  <p className={s.contactText}>web:</p>
                </div>
                <div className={s.contactRight}>
                  <p className={s.contactText}>+49 7461 / 966 2999</p>
                  <a className={s.mailLink} href="mailto:info@pfz-cnc.com">
                    <p className={s.contactInfo}>info@pfz-cnc.com</p>
                  </a>
                  <a className={s.mailLink} href="mailto:quality@pfz-cnc.com">
                    <p className={s.contactInfo}>
                      https://www.pfz-cnc.
                      {i18n.language === 'en' ? 'com' : 'de'}/
                    </p>
                  </a>
                </div>
              </div>
              <div className={s.line1}></div>
              <div className={s.wrapperModalText}>
                <div className={s.contactCentral}>
                  <p className={s.contactText}>Kommanditgesellschaft:</p>
                  <p className={s.contactText}>Sitz der Gesellschaft:</p>
                  <p className={s.contactText}>Handelsregister</p>

                  <p className={s.contactText}>USt-Id-Nr.:</p>
                  <p className={s.contactText}>
                    Persönlich haftender Gesellschafter:
                  </p>
                  <p className={s.contactText}>Sitz der Gesellschaft:</p>
                  <p className={s.contactText}>Handelsregister:</p>
                  <p className={s.contactText}>Geschäftsführer:</p>
                </div>
                <div className={s.contactRight}>
                  <p className={s.contactText}>{t('PFZ GmbH & Co. KG')}</p>
                  <p className={s.contactText}>Tuttlingen</p>
                  <p className={s.contactText}>Stuttgart HRA 735499</p>
                  <p className={s.contactText}>DE323128077</p>
                  <p className={s.contactText}>PFZ GmbH & Co. KG</p>
                  <p className={s.contactText}>Tuttlingen</p>
                  <p className={s.contactText}>Stuttgart HRB 768727</p>
                  <p className={s.contactText}>
                    Sascha Maluscik, Steven Maluscik, Manuel Wörner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          location.pathname.includes('/policy') && i18n.language === 'en'
            ? s.wrapperAccent
            : location.pathname.includes('/policy') && i18n.language === 'de'
            ? s.wrapperAccentDe
            : s.wrapper
        }
      >
        <footer className={s.footer}>
          <div className={s.socialWrapper}>
            <ul className={s.linkList}>
              {data.map(item => (
                <li className={s.item} key={item.href}>
                  <a
                    className={s.link}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {<Icon name={item.icon} size={18} className={s.icon} />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={s.widget}>
            <p className={s.text}>{`© ${date} PFZ GmbH & Co. KG`} </p>
            <p className={s.text}>|</p>
            <p className={s.textLink} onClick={() => setIsModalVisible(true)}>
              {t('Impressum')}{' '}
            </p>
            <p className={s.text}>|</p>
            <Link to="/policy" className={s.textLink}>
              {t('Privacy Policy-footer')}
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
