import { useEffect, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PhoneInput from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/icon';
import s from './countryInput.module.scss';
import countries from '../../orderForm/countryCodes.json';

export const CountryInput = ({ onChange, value, error }) => {
  const { t } = useTranslation();

  const [country, setCountry] = useState({ name: '', code: '' });
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const [countryName, setCountryName] = useState(value || '');

  useEffect(() => {
    onChange(country.name);
  }, [country.name]);

  useEffect(() => {
    if (value) {
      setCountryName(value || '');
    } else {
      setCountry({ code: null });
      setCountryName('');
    }
  }, [value]);

  const findCountry = query => {
    const newCountry = countries.find(item => item.name === query);
    if (newCountry && country.name !== newCountry?.name) {
      setCountry(newCountry);
      setCountryName(newCountry?.name || '');
    }
  };

  useEffect(() => findCountry(countryName), [countryName]);

  return (
    <div
      className={s.containerBottom}
      style={error ? { border: '2px solid red' } : {}}
    >
      <input
        className={s.inputTextCountry}
        type="text"
        name="order_quantity"
        value={countryName}
        autoComplete="off"
        placeholder={t('Country')}
        onChange={({ target }) => {
          const country = target?.value?.length
            ? target.value.charAt(0).toUpperCase() + target.value.slice(1)
            : '';
          setCountryName(country);
        }}
      />
      <ClickAwayListener onClickAway={() => setCountrySelectOpen(false)}>
        <div
          className={s.countryFlag}
          onClick={() => setCountrySelectOpen(val => !val)}
        >
          <PhoneInput
            className={s.flag}
            placeholder=""
            value={''}
            defaultCountry={country.code || ''}
            onChange={() => {}}
          />
          <div>
            <div>
              {countrySelectOpen && (
                <ul className={[s.containerOption, s.countrySelect].join(' ')}>
                  {countries
                    .filter(item =>
                      item.name
                        .toLowerCase()
                        .includes(countryName.toLowerCase())
                    )
                    .map(item => (
                      <li
                        className={s.select}
                        onClick={e => findCountry(e.target?.innerText)}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            {countryName.length > 0 && (
              <Icon
                size={20}
                name="buttonClose"
                onClick={() => {
                  setCountry({ code: null });
                  setCountryName('');
                }}
                className={[s.iconArrowQuantity, s.countryDelete].join(' ')}
              />
            )}
            {!countrySelectOpen && countryName.length === 0 && (
              <Icon
                size={10}
                name="down"
                className={[s.iconArrowQuantity, s.countryArrow].join(' ')}
              />
            )}
            {countrySelectOpen && (
              <Icon
                size={10}
                name="down"
                className={[s.iconArrowQuantityUp, s.countryArrow].join(' ')}
              ></Icon>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
