import Footer from '../footer';
import BackToBottomButton from '../backToBottomButton/backToBottomButton';

import Career from 'components/career/career';

export const CareerPage = ({ isOpenMenu, data }) => (
  <>
    <Career isOpenMenu={isOpenMenu} data={data} />
    <BackToBottomButton />
  </>
);
// commit
