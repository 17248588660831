import react, { useEffect, useRef, useState } from 'react';
import s from './aboutUs.module.scss';
import { useTranslation } from 'react-i18next';
// import video from '../../images/about/video.mp4';
import { ReactComponent as SubTitleSVGTablet } from '../../images/about/tablet/section2itemBackground.svg';
import { ReactComponent as SubTitleSVG } from '../../images/about/section2itemBackground.svg';
import Icon from 'components/atoms/icon';
// import ReactPlayer from 'react-player';
// import img from '../../images/about/background.jpg';
import img08 from '../../images/machinery/img08.png';
import img08Webp from '../../images/machinery/img08.webp';
import img12Webp from '../../images/machinery/img12.webp';
import img15Webp from '../../images/machinery/img15.webp';
import img18Webp from '../../images/machinery/img18.webp';
import img22Webp from '../../images/machinery/img22.webp';
import img12 from '../../images/machinery/img12.png';
import img15 from '../../images/machinery/img15.png';
import img18 from '../../images/machinery/img18.png';
import img22 from '../../images/machinery/img22.png';
import Title from 'components/molecules/title/Title';
import { useLocation } from 'react-router-dom';
import DescriptionItem from 'components/molecules/animateAboutUs/AnimateAboutUs';

const AboutUs = ({ isModalVisibleForm, setModalVisibleForm }) => {
  const { t } = useTranslation();
  const location = useLocation(useLocation);

  useEffect(() => {
    window.scrollTo({
      top: '0px',
      behavior: 'smooth',
    });
  }, [location]);
  useEffect(() => {
    if (isModalVisibleForm) {
      setTimeout(() => {
        setModalVisibleForm(false);
      }, 10000);
    }
  }, [isModalVisibleForm]);

  return (
    <div id="about_t" className={s.mainContainer}>
      {isModalVisibleForm && (
        <div className={s.backdrop}>
          <div className={s.wrapperBackdrop}>
            <Icon
              name="icon-close-menu"
              size={30}
              className={s.iconMenu}
              onClick={() => setModalVisibleForm(false)}
            />
            <p className={s.backdropText}>
              {t('Thank you for your request, we will process it immediately.')}
            </p>
          </div>
        </div>
      )}
      <div id="about" className={s.linkAbout}></div>
      <div id="about_m" className={s.containerVideo} />
      <div style={{ position: 'absolute', width: 0, height: 0, opacity: 0 }}>
        <SubTitleSVGTablet />
      </div>
      <div style={{ position: 'absolute', width: 0, height: 0, opacity: 0 }}>
        <SubTitleSVG />
      </div>
      <div className={s.container}>
        <picture>
          <source srcSet={img08Webp} type="image/webp" />
          <img src={img08} alt="tabs" className={s.tabsImg} />
        </picture>
        <picture>
          <source srcSet={img12Webp} type="image/webp" />
          <img src={img12} alt="tabs" className={s.tabsImg} />
        </picture>
        <picture>
          <source srcSet={img15Webp} type="image/webp" />
          <img src={img15} alt="tabs" className={s.tabsImg} />
        </picture>
        <picture>
          <source srcSet={img18Webp} type="image/webp" />
          <img src={img18} alt="tabs" className={s.tabsImg} />
        </picture>
        <picture>
          <source srcSet={img22Webp} type="image/webp" />
          <img src={img22} alt="tabs" className={s.tabsImg} />
        </picture>

        <div className={s.contentMobile}>
          <div className={s.description}>
            <Title
              svg1="about-mobile-vector2"
              svg2="about-mobile-vector3"
              svg3="about-mobile-vector1"
              top1={0}
              top2={27}
              left1={22}
              left2={-20}
            >
              <h2 className={s.title}>
                {t('THE FOUNDATIONS FOR THIS SUCCESS ARE LAID BY:')}
              </h2>
            </Title>
            <div className={s.containerMobileAccent}>
              <div className={s.containerMobileDescription}>
                <DescriptionItem
                  id={1}
                  title={t('Fast response and delivery terms')}
                  message={t(
                    'We react quickly to your concerns with our top management and realize them immediately through short instance paths.'
                  )}
                  icon="section2item1"
                  width={235}
                  margin={0}
                  height={60}
                />
              </div>
              <div className={s.containerMobileDescription1}>
                <DescriptionItem
                  id={2}
                  title={t('Personal attention')}
                  message={t(
                    'Time and attention are the basis of a successful cooperation'
                  )}
                  icon="section2item2"
                  width={235}
                  margin={0}
                  height={60}
                />
              </div>
              <div className={s.containerMobileDescription2}>
                <DescriptionItem
                  id={3}
                  title={t('Specialization')}
                  message={t(
                    'Your demanding workpieces are manufactured with the highest precision and care on our state-of-the-art CNC machining centres by our highly qualified specialist staff.'
                  )}
                  icon="section2item3"
                  width={235}
                  margin={0}
                  height={60}
                />
              </div>
              <div className={s.containerMobileDescription3}>
                <DescriptionItem
                  id={4}
                  title={t('Flexibility')}
                  icon="section2item4"
                  message={t(
                    'We have the flexibility to change policies depending on the need of the hour, to bend and make decisions instantly.'
                  )}
                  width={235}
                  margin={0}
                  height={60}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.contentTablet}>
          <div className={s.description}>
            <Title
              svg1="about-tablet-vector2"
              svg2="about-tablet-vector1"
              svg3="about-tablet-vector3"
              top1={16}
              top2={52}
            >
              <h2 className={s.title}>
                {t('THE FOUNDATIONS FOR THIS SUCCESS ARE LAID BY:')}
              </h2>
            </Title>
            <div>
              <DescriptionItem
                id={1}
                title={t('Fast response and delivery terms')}
                message={t(
                  'We react quickly to your concerns with our top management and realize them immediately through short instance paths.'
                )}
                icon="section2item1"
                margin={0}
              />
              <DescriptionItem
                id={2}
                title={t('Personal attention')}
                message={t(
                  'Time and attention are the basis of a successful cooperation'
                )}
                icon="section2item2"
                width={375}
                margin={0}
              />
              <DescriptionItem
                id={3}
                title={t('Specialization')}
                message={t(
                  'Your demanding workpieces are manufactured with the highest precision and care on our state-of-the-art CNC machining centres by our highly qualified specialist staff.'
                )}
                icon="section2item3"
                width={375}
                margin={0}
              />
              <DescriptionItem
                id={4}
                title={t('Flexibility')}
                icon="section2item4"
                message={t(
                  'We have the flexibility to change policies depending on the need of the hour, to bend and make decisions instantly.'
                )}
                width={375}
                margin={0}
              />
            </div>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.description}>
            <Title
              svg1="about-titlevector2"
              svg2="about-titlevector1"
              svg3="about-titlevector3"
              top1={22}
              top2={60}
            >
              <h2 className={s.title}>
                {t('THE FOUNDATIONS FOR THIS SUCCESS ARE LAID BY:')}
              </h2>
            </Title>
            <div>
              <DescriptionItem
                id={1}
                title={t('Fast response and delivery terms')}
                message={t(
                  'We react quickly to your concerns with our top management and realize them immediately through short instance paths.'
                )}
                icon="section2item1"
                margin={170}
              />
              <DescriptionItem
                id={2}
                title={t('Personal attention')}
                message={t(
                  'Time and attention are the basis of a successful cooperation'
                )}
                icon="section2item2"
                width={345}
                margin={240}
              />
              <DescriptionItem
                id={3}
                title={t('Specialization')}
                message={t(
                  'Your demanding workpieces are manufactured with the highest precision and care on our state-of-the-art CNC machining centres by our highly qualified specialist staff.'
                )}
                icon="section2item3"
                width={345}
                margin={320}
              />
              <DescriptionItem
                id={4}
                title={t('Flexibility')}
                icon="section2item4"
                message={t(
                  'We have the flexibility to change policies depending on the need of the hour, to bend and make decisions instantly.'
                )}
                width={345}
                margin={420}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
