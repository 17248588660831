import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { SVG, ids, files } from 'components/atoms/svg/svg';
import { isVisible } from 'utils/isVisible';

const TitleHero = ({
  children,
  svg1,
  svg2,
  svg3,
  containerStyles,
  contentStyles,
  visible = true,
  left1,
  left2,
  left3,
  top = 61,
  top2 = 48,
  top3,
  shift = 24,
  isSuspended,
}) => {
  const first = useRef(null);
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    !isSuspended && setAnimated(true);
  }, [isSuspended]);

  if (!files.byId[svg1]) return <div>There is no '{svg1}' icon</div>;
  if (!files.byId[svg2]) return <div>There is no '{svg2}' icon</div>;
  if (!files.byId[svg3]) return <div>There is no '{svg3}' icon</div>;

  const totalWidth = Math.max(
    files.byId[svg1].width,
    files.byId[svg2].width,
    files.byId[svg3].width
  );
  const totalHeight = Math.max(
    files.byId[svg1].height,
    files.byId[svg2].height,
    files.byId[svg3].height
  );

  return (
    <Container
      ref={first}
      containerStyles={containerStyles}
      visible={visible}
      totalWidth={totalWidth}
      totalHeight={totalHeight}
    >
      {files.allIds.includes(svg1) && (
        <StyledSVG
          id={ids[svg1]}
          opacity={animated ? 1 : 0}
          top={top}
          left={left1}
          translate={animated ? 0 : -100}
        />
      )}
      {files.allIds.includes(svg2) && (
        <StyledSVG1
          id={ids[svg2]}
          top={top2}
          left={left2}
          opacity={animated ? 1 : 0}
          translate={animated ? 0 : -100}
        />
      )}
      {files.allIds.includes(svg3) && (
        <StyledSVG
          id={ids[svg3]}
          opacity={animated ? 1 : 0}
          top={top3}
          left={left3}
        />
      )}
      <ContentWrapper
        contentStyles={contentStyles}
        opacity={animated ? 1 : 0}
        top={top}
      >
        {children}
      </ContentWrapper>
    </Container>
  );
};

export default TitleHero;

const Container = styled.div`
  position: relative;
  width: ${({ totalWidth }) => totalWidth}px;
  height: ${({ totalHeight }) => totalHeight}px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${({ containerStyles }) => containerStyles};

  transition: opacity 300ms linear;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ contentStyles }) => contentStyles}

  opacity: ${({ opacity }) => opacity};
  transition: all 500ms linear 800ms;
`;

const StyledSVG = styled(SVG)`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: transform 600ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
`;
const StyledSVG1 = styled(SVG)`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: transform 1000ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
`;
