export default {
  'Frequently requested steels': [
    'Steel │ 1.0037 • S235JRC+C • St37k • AISI 1015',
    '1.0038 • S235JR • St37-2',
    '1.0503 • C45 • AISI 1034',
    '1.0570 • S35J2 • St52-3 • AISI 1024',
    '1.2379 • AISI D2',
    ' 1.2842 • 90MnCrV8 • AISI 02',
    'Stainless steel │ 1.4301 • V2A • AISI 304',
    '1.4305 • AISI 303',
    '1.4404 • V4A • AISI 316 L',
    '1.7131 • 16MnCr5 • AISI 5115',
    '1.7225 • 42CrMo4 • AISI 4140',
  ],
  'Frequently requested aluminum alloys ': [
    'Aluminum │ EN AW-6061 • 3.3211 • AlMg1SiCu',
    'EN AW-5083 • 3.3547 • AlMg4,5Mn',
    'EN AW-5754 • 3.3535 • AlMg3',
    'EN AW-6060 • 3.3206 • AlMgSi0,5',
    'EN AW-7075 • 3.4365 • AlZnMgCu1,5',
  ],
  'Frequently requested other metals': [
    'Copper │ Cu-ETP • 2.0060 • CW004A',
    'Brass │ CuZn39Pb3 • 2.0401 • CW614N • Ms58',
    'Bronze │ CuSn8 • 2.1030 • CW453K',
    'Titanium │ Ti Gr 5 • Ti6Al4V • 3.7165',
  ],
  'Frequently requested plastics': [
    'ABS (black)',
    'ABS (white)',
    'PA6 (natural/white)',
    'PA6 (black)',
    'PA66 • Nylon (natural/white)',
    'PA66 • Nylon (black)',
    'PE (natural/white)',
    'PE-UHMW (natural/white)',
    'PEEK (natural/beige)',
    'PMMA • Acrylic glass (as machined)',
    'PMMA • Acrylic glass (transparent)',
    'POM (black)',
    'POM (white)',
    'PTFE • Teflon (natural/white)',
    'PVC (dark grey)',
  ],
  // 'Metal 3D Printing ': [
  //   'Aluminum (3D Printing) │ EN AC-43000 • 3.2381 • AlSi10Mg',
  //   'Stainless steel (3D Printing) │ 1.4404 • X2CrNiMo17-12-2 • AISI 316 L',
  //   'Tool steel (3D Printing) │ 1.2709 • X3NiCoMoTi18-9-5',
  //   'Titanium (3D Printing) │ Ti Gr 5 • 3.7165 • Ti6Al4V',
  //   'Inconel (3D Printing) │ IN 718',
  // ],
  'Other steels': [
    '1.0037 • S235JRC+C • St37k • AISI 1015',
    '1.0038 • S235JR • St37-2',
    '1.0044',
    '1.0045 • S355JR',
    '1.0050',
    '1.0060',
    '1.0070',
    '1.0112',
    '1.0116',
    '1.0140',
    '1.0161',
    '1.0213',
    '1.0226',
    '1.0278',
    '1.0301',
    '1.0308',
    '1.0330',
    '1.0401',
    '1.0425',
    '1.0460',
    '1.0501',
    '1.0503 • C45 • AISI 1034',
    '1.0536',
    '1.0545',
    '1.0553',
    '1.0570 • S35J2 • St52-3 • AISI 1024',
    '1.0575',
    '1.0576',
    '1.0577',
    '1.0579',
    '1.0580',
    '1.0601 • C60',
    '1.0605 • C75',
    '1.0711',
    '1.0715',
    '1.0718',
    '1.0721',
    '1.0725',
    '1.0736',
    '1.0737',
    '1.0762',
    '1.0763',
    '1.0765',
    '1.0978',
    '1.0980',
    '1.1014',
    '1.1127',
    '1.1133',
    '1.1141',
    '1.1151',
    '1.1169',
    '1.1191',
    '1.1213',
    '1.1248',
    '1.1520',
    '1.1730 • C45U',
    '1.1740',
    '1.1937',
    '1.1938',
    '1.1944',
    '1.1960',
    '1.2063',
    '1.2080',
    '1.2083',
    '1.2085',
    '1.2161',
    '1.2162',
    '1.2162',
    '1.2162',
    '1.2210',
    '1.2242',
    '1.2303',
    '1.2311',
    '1.2312',
    '1.2316',
    '1.2343',
    '1.2344',
    '1.2344 ESU',
    '1.2363',
    '1.2379 • AISI D2',
    '1.2436',
    '1.2510',
    '1.2516',
    '1.2601',
    '1.2709',
    '1.2714',
    '1.2721',
    '1.2738',
    '1.2764',
    '1.2767',
    '1.2826',
    '1.2842 • 90MnCrV8 • AISI 02',
    '1.2990',
    '1.3247',
    '1.3343',
    '1.3401',
    '1.3505',
    '1.3515',
    '1.3543',
    '1.3576',
    '1.3952',
    '1.4003',
    '1.4005 • AISI 416',
    '1.4006 • AISI 410',
    '1.4016 • AISI 430',
    '1.4021 • AISI 420',
    '1.4028 • AISI 420',
    '1.4034',
    '1.4035',
    '1.4057 • AISI 431',
    '1.4104 • AISI 430F',
    '1.4105',
    '1.4112 • AISI 440B',
    '1.4122',
    '1.4125 • AISI 440C',
    '1.4201',
    '1.4301 • V2A • AISI 304',
    '1.4305 • AISI 303',
    '1.4306 • AISI 304L',
    '1.4307 • AISI 304L',
    '1.4310 • AISI 301',
    '1.4313',
    '1.4362',
    '1.4401 • V4A • AISI 316',
    '1.4404 • V4A • AISI 316L',
    '1.4410',
    '1.4435 • AISI 316L',
    '1.4441',
    '1.4462',
    '1.4510 • AISI 430Ti',
    '1.4517',
    '1.4521 • AISI 444',
    '1.4539 • AISI 904L',
    '1.4541 • AISI 321',
    '1.4542 • AISI 630',
    '1.4548 • AISI 630',
    '1.4562',
    '1.4571 • AISI 316Ti',
    '1.4718 • AISI HNV 3',
    '1.4747',
    '1.4762',
    '1.4828 • AISI 309',
    '1.4835',
    '1.4841 • AISI 310',
    '1.4873',
    '1.4873',
    '1.4876',
    '1.4923',
    '1.4980',
    '1.5217',
    '1.5525',
    '1.5752',
    '1.5815',
    '1.5860',
    '1.5918',
    '1.6220',
    '1.6510',
    '1.6523 • AISI 8620',
    '1.6546',
    '1.6580 • AISI 4340',
    '1.6582 • AISI 4340',
    '1.6587',
    '1.7030',
    '1.7035',
    '1.7108',
    '1.7131 • 16MnCr5 • AISI 5115',
    '1.7147',
    '1.7149',
    '1.7201',
    '1.7218 • AISI 4130',
    '1.7224',
    '1.7225 • 42CrMo4 • AISI 4140',
    '1.7227',
    '1.7311',
    '1.7321',
    '1.7335',
    '1.7701',
    '1.7709',
    '1.7734',
    '1.8070',
    '1.8159 • AISI 6150',
    '1.8507',
    '1.8515',
    '1.8519',
    '1.8550',
    '1.8946',
    '1.8982',
  ],
  'Other aluminum alloys ': [
    'EN AW-1050A • 3.0255 • Al99,5',
    'EN AW-1060 • Al99,6',
    'EN AW-1200 • 3.0205 • Al99',
    'EN AW-2007 • 3.1645 • AlCuMgPb',
    'EN AW-2011 • 3.1655',
    'EN AW-2014 • 3.1255 • AlCuSiMn',
    'EN AW-2017A • 3.1325 • AlCuMg1',
    'EN AW-2024 • 3.1355 • AlCuMg2',
    'EN AW-3003 • 3.0517 • AlMnCu',
    'EN AW-3004 • 3.0526',
    'EN AW-3103 • 3.0515',
    'EN AW-5005 • 3.3315',
    'EN AW-5019 • 3.3555 • AlMg5',
    'EN AW-5052 • 3.3523 • AlMg2,5',
    'EN AW-5083 • 3.3547 • AlMg4,5Mn',
    'EN AW-5086 • 3.3545 • AlMg4Mn',
    'EN AW-5251 • 3.3525 • AlMg2Mn0,3',
    'EN AW-5754 • 3.3535 • AlMg3',
    'EN AW-6012 • 3.0615 • AlMgSiPb',
    'EN AW-6023 • AlSi1Sn1MgBi',
    'EN AW-6026 • AlMgSiBi',
    'EN AW-6060 • 3.3206 • AlMgSi0,5',
    'EN AW-6061 • 3.3211 • AlMg1SiCu',
    'EN AW-6063 • 3.3206 • AlMgSi0,7',
    'EN AW-6082 • 3.2315 • AlMgSi1',
    'EN AW-7020 • 3.4335',
    'EN AW-7022 • 3.4345 • AlZnMgCu0,5',
    'EN AW-7075 • 3.4365 • AlZnMgCu1,5	',
    'Other copper alloys ',
    'Cu-OF • 2.0040 • CW008A',
    'Cu-OFE • CW009A',
    'Cu-ETP • 2.0060 • CW004A',
    'Cu-DHP • 2.0090 • CW024A',
    'CuAl10Ni5Fe4 • 2.0966 • CW307G',
    'CuAL10Fe5Ni5 • 2.0970 • CC333G	',
    'CuAl10Ni5Fe5-C • 2.0975 • CC333G	',
    'CuBe2 • 2.1247 • CW101C',
    'CuCo2Be • 2.1285 • CW104C',
    'CuNi18Zn20 • 2.0740 • CW409J',
    'CuSn12-C • 2.1052 • CC483K',
    'CuSn4 • 2.1016 • CW450K',
    'CuSn5Zn5Pb5-C • 2.1096.01 • CC491K • Rotguss RG 5',
    'CuSn6 • 2.1020 • CW452',
    'CuSn6 • 2.1020 • CW452K',
    'CuSn7Zn4Pb7-C • 2.1090.01 • CC493K • Rotguss RG 7',
    'CuSn7znPb • CC493K',
    'CuSn8 • 2.1030 • CW453K',
    'CuTeP • 2.1546 • CW118C',
    'CuZn21Si3P • CW724R',
    'CuZn31Si1 • 2.0490 • CW708R',
    'CuZn36Pb3 • 2.0375 • CW603N',
    'CuZn37 • 2.0321 • CW508L',
    'CuZn39Pb3 • 2.0401 • CW614N • Ms58',
    'CuZn39Pb3Sn • CW615N',
  ],
  'Other titanium alloys': [
    'Ti Gr 1 • 3.7025',
    'Ti Gr 2 • 3.7035',
    'Ti Gr 3 • 3.7055',
    'Ti Gr 4 • 3.7065',
    'Ti Gr 5 • Ti6Al4V • 3.7165',
    'Ti Gr 23 • Ti6AL4V-ELI',
  ],
  'Other plastics': [
    'ABS (black)',
    'ABS (white)',
    'ABS (other color)',
    'Bakelit (black)',
    'Bakelit (other color)',
    'ESD POM (black)',
    'ESD POM (other color)',
    'HDPE (natural/white)',
    'HDPE (black)',
    'HDPE (other color)',
    'PA6 (natural/white)',
    'PA6 (black)',
    'PA6 (other color)',
    'PA66 • Nylon (natural/white)',
    'PA66 • Nylon (black)',
    'PA66 • Nylon (other color)',
    'PA12 (natural/white)',
    'PA12 (black)',
    'PA12 (other color)',
    'PAI (natural/brown)',
    'PAI (other color)		',
    'PBT (black)',
    'PBT (other color)',
    'PC (transparent)		',
    'PC (other color)',
    'PE (natural/white)',
    'PE (black)',
    'PE (green)',
    'PE (other color)',
    'PE-UHMW (natural/white)',
    'PE-UHMW (black)',
    'PE-UHMW (green)',
    'PE-UHMW (other color)',
    'PEEK (natural/beige)',
    'PEEK (other color)',
    'PEI (natural/amber)',
    'PEI (other color)',
    'PET (natural/white)',
    'PET (black)',
    'PET (other color)',
    'PMMA • Acrylic glass (as machined)',
    'PMMA • Acrylic glass (transparent)',
    'PMMA • Acrylic glass (other color)',
    'POM (white)',
    'POM (black)',
    'POM (other color)',
    'PP (natural/white)',
    'PP (grey)',
    'PP (black)',
    'PP (white)',
    'PP (other color)',
    'PPS (natural/beige)',
    'PPS (other color)',
    'PPSU (natural/beige)',
    'PPSU (black)',
    'PPSU (other color)',
    'PS (white)',
    'PS (other color)',
    'PSU (natural/amber)',
    'PSU (other color)		',
    'PTFE • Teflon (natural/white)',
    'PTFE • Teflon (other color)',
    'PVC (dark grey)',
    'PVC (other color)',
    'PVDF (natural/white)',
    'PVDF (other color)',
    'PUR (natural/white)',
  ],
};
