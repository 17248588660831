import react, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import styled from '@emotion/styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import s from './hero.module.scss';
import Icon from 'components/atoms/icon';
import video from '../../images/hero/video.mp4';
import imgVideo from '../../images/hero/imgvideo.jpg';
import imgVideoWebp from '../../images/hero/imgvideo.webp';
import TitleHero from 'components/molecules/titleHero/titleHero';
import HeroItem from 'components/atoms/heroItem/HeroItem';
import LaserCuttingHero from 'components/laserCuttingHeader/laserCuttingHero';

const key = 'loaded';
const source = {
  title: {
    desktop: {
      svg1: 'hero-vector3',
      svg2: 'hero-vector2',
      svg3: 'hero-vector1',
    },
    tablet: {
      svg1: 'hero-tablet-vector3',
      svg2: 'hero-tablet-vector2',
      svg3: 'hero-tablet-vector1',
    },
    mobile: {
      svg1: 'hero-mobile-vector3',
      svg2: 'hero-mobile-vector2',
      svg3: 'hero-mobile-vector1',
    },
  },
};

const carouselArrows = (name, styles) => (clickHandler, hasPrev) => {
  return (
    <>
      {hasPrev && (
        <></>
        // <div className={styles} onClick={clickHandler}>
        //   <Icon name={name} className={s.arrow} />
        // </div>
      )}
    </>
  );
};

const classNames = {
  visible: {
    title: s.visibleTitle,
    text: s.visibleText,
    button: s.visibleButton,
  },
  hide: {
    title: s.hide,
    text: s.hide,
    button: s.hide,
  },
};

const Hero = ({ setIsVideoLoaded, isSuspended }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation();
  const isDesktop = window.screen.width > 1299;
  const [isVideo, setIsVideo] = useState(isDesktop);

  useEffect(() => {
    !isDesktop && setIsVideoLoaded(true);
  }, []);

  return (
    <>
      <Container className="wrapper">
        <Carousel
          swipeable={false}
          emulateTouch={true}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={(name, styles) =>
            carouselArrows('left', s.leftArrowWrapper)(name, styles)
          }
          renderArrowNext={(name, styles) =>
            carouselArrows('right', s.rightArrowWrapper)(name, styles)
          }
          renderIndicator={() => <></>}
          onChange={e => setCurrentSlide(e)}
        >
          <div className={s.container}>
            <div className={s.videoWrapper}>
              {isVideo && (
                <video
                  className={s.video}
                  preload="auto"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onCanPlayThrough={() => setIsVideoLoaded(true)}
                >
                  <source type="video/mp4" src={video} />
                </video>
              )}

              <HeroMessage
                classNames={
                  currentSlide === 0 ? classNames.visible : classNames.hide
                }
                isSuspended={isSuspended}
              />
            </div>
            <div className={s.videoWrapperMobile}>
              <div className={s.containerImgMobile}></div>
              <HeroMessage
                classNames={
                  currentSlide === 0 ? classNames.visible : classNames.hide
                }
                isSuspended={isSuspended}
              />
            </div>
            <div className={s.videoWrapperTablet}>
              <div className={s.containerImgTablet}></div>

              <HeroMessage
                classNames={
                  currentSlide === 0 ? classNames.visible : classNames.hide
                }
                isSuspended={isSuspended}
              />
            </div>

            <a
              href="#about"
              className={[s.arrowDown, 'scrollDownArrow'].join(' ')}
            >
              <Icon name="down" size={44} className={s.arrowDownSvg} />
            </a>
          </div>
          <div className={s.container}>
            <LaserCuttingHero currentSlide={currentSlide} />
            <a href="#about" id="downArrow" className={s.arrowDown}>
              <Icon name="down" size={44} className={s.arrowDownSvg} />
            </a>
          </div>
        </Carousel>
      </Container>
    </>
  );
};

export default Hero;

const HeroMessage = ({ classNames, isSuspended }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className={s.containerText}>
        <div className={classNames.title}>
          <TitleHero
            svg1={source.title.desktop.svg1}
            svg2={source.title.desktop.svg2}
            svg3={source.title.desktop.svg3}
            visible={scroll < 250}
            top="64"
            left1="-320"
            left2="-315"
            top3="-34"
            isSuspended={isSuspended}
          >
            <h2 className={`${s.title} ${scroll > 100 ? s.textHide : ''}`}>
              {t('We are your reliable partner for')}
            </h2>
            <h2 className={`${s.title} ${scroll > 200 ? s.textHide : ''}`}>
              {t('CNC manufacturing:')}
            </h2>
          </TitleHero>
          <p className={scroll < 300 ? s.descriptionVisible : s.description}>
            {t('Profit from our know-how!')}
          </p>
          <HeroItem visible={scroll < 350} href="#milling">
            <h2 className={s.title2}>{t('milling')}</h2>
          </HeroItem>
          <HeroItem visible={scroll < 430} href="#drehteile">
            <h2 className={s.title2}>{t('turning')}</h2>
          </HeroItem>
          <HeroItem visible={scroll < 500} href="#laser">
            <h2 className={s.title2}>{t('laser cutting')} </h2>
          </HeroItem>
          <p
            className={
              scroll < 600 ? s.descriptionVisibleAccent : s.descriptionAccent
            }
          >
            {t(
              'Our competence, flexibility and discretion make us the ideal partner for the production of your demanding workpieces.'
            )}
          </p>
          {/* 
          <HeroItem visible={scroll < 500}>
            <h2 className={s.title2}>{t('Development')}</h2>
          </HeroItem> */}
        </div>
      </div>
      <div className={s.containerTextMobile}>
        <div className={classNames.title}>
          <div className={s.containerTitleGap}>
            <TitleHero
              svg1={source.title.mobile.svg2}
              svg2={source.title.mobile.svg3}
              svg3={source.title.mobile.svg1}
              visible={scroll < 250}
              left3="-10"
              left1="-50"
              left2="-60"
              top3="-23"
              top="27"
              top2="15"
              isSuspended={isSuspended}
            >
              <h2 className={`${s.title} ${scroll > 150 ? s.textHide : ''}`}>
                {t('We are your reliable partner for')}
              </h2>
              <h2 className={`${s.title} ${scroll > 250 ? s.textHide : ''}`}>
                {t('CNC manufacturing:')}
              </h2>
            </TitleHero>
          </div>
          <p className={scroll < 300 ? s.descriptionVisible : s.description}>
            {t('Profit from our know-how!')}
          </p>
          <HeroItem
            visible={scroll < 350}
            href="#milling_m"
            height={23}
            paddingLeft={27}
            marginLeft={0}
          >
            <h2 className={s.title2}>{t('milling')}</h2>
          </HeroItem>
          <HeroItem
            visible={scroll < 400}
            href="#drehteile_m"
            height={23}
            paddingLeft={27}
            marginLeft={0}
          >
            <h2 className={s.title2}>{t('turning')}</h2>
          </HeroItem>
          <HeroItem
            visible={scroll < 480}
            height={23}
            paddingLeft={27}
            marginLeft={0}
            href="#laser_m"
          >
            <h2 className={s.title2}>{t('laser cutting')}</h2>
          </HeroItem>
          <p
            className={
              scroll < 600 ? s.descriptionVisibleAccent : s.descriptionAccent
            }
          >
            {t(
              'Our competence, flexibility and discretion make us the ideal partner for the production of your demanding workpieces.'
            )}
          </p>
          {/* <HeroItem
            visible={scroll < 550}
            height={23}
            paddingLeft={27}
            marginLeft={0}
          >
            <h2 className={s.title2}>{t('development')}</h2>
          </HeroItem> */}
        </div>
      </div>

      <div className={s.containerTextTablet}>
        <div className={classNames.title}>
          <div className={s.containerTitleGap}>
            <TitleHero
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              visible={scroll < 250}
              left1="-290"
              left2="-292"
              left3="-50"
              top="30"
              top2="16"
              top3="-28"
              isSuspended={isSuspended}
            >
              <h2 className={`${s.title} ${scroll > 150 ? s.textHide : ''}`}>
                {t('We are your reliable partner for')}
              </h2>
              <h2 className={`${s.title} ${scroll > 250 ? s.textHide : ''}`}>
                {t('CNC manufacturing:')}
              </h2>
            </TitleHero>
          </div>
          <p className={scroll < 300 ? s.descriptionVisible : s.description}>
            {t('Profit from our know-how!')}
          </p>
          <HeroItem
            visible={scroll < 350}
            href="#milling_t"
            height={29}
            paddingLeft={40}
            marginLeft={-30}
          >
            <h2 className={s.title2}>{t('milling')}</h2>
          </HeroItem>
          <HeroItem
            visible={scroll < 400}
            href="#drehteile_t"
            height={29}
            paddingLeft={40}
            marginLeft={-30}
          >
            <h2 className={s.title2}>{t('turning')}</h2>
          </HeroItem>
          <HeroItem
            visible={scroll < 480}
            height={29}
            paddingLeft={40}
            marginLeft={-30}
            href="#laser_t"
          >
            <h2 className={s.title2}>{t('laser cutting')}</h2>
          </HeroItem>
          <p
            className={
              scroll < 600 ? s.descriptionVisibleAccent : s.descriptionAccent
            }
          >
            {t(
              'Our competence, flexibility and discretion make us the ideal partner for the production of your demanding workpieces.'
            )}
          </p>
          {/* <HeroItem
            visible={scroll < 550}
            height={29}
            paddingLeft={40}
            marginLeft={-30}
          >
            <h2 className={s.title2}>{t('development')}</h2>
          </HeroItem> */}
        </div>
      </div>
    </>
  );
};

const Container = styled.div`
  & .thumbs-wrapper.axis-vertical {
    margin: 0;
  }
`;
