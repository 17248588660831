import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { isVisible } from 'utils/isVisible';
import Icon from 'components/atoms/icon';
import { ReactComponent as SubTitleSVG } from '../../../images/about/section2itemBackground.svg';
import { ReactComponent as SubTitleSVGTablet } from '../../../images/about/tablet/section2itemBackground.svg';
import { ReactComponent as SubTitleSVGMobile } from '../../../images/about/mobile/section2itemBackground.svg';
import { SVG } from 'components/atoms/svg/svg';

const DescriptionItem = ({
  id = 1,
  title = '',
  message = '',
  margin = 100,
  width = 345,
  height = 'auto',
  icon,
}) => {
  const first = useRef(null);
  const [animated, setAnimated] = useState(false);
  const [enabled, setEnabled] = useState({ state: false });
  const { t } = useTranslation();

  const callBack = () => {
    enabled.state &&
      first.current &&
      !animated &&
      isVisible(first.current) &&
      setAnimated(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', callBack);
    setTimeout(() => {
      enabled.state = true;
      setEnabled(enabled);
      first.current &&
        !animated &&
        isVisible(first.current) &&
        setAnimated(true);
    }, id * 500);

    return () => {
      window.removeEventListener('scroll', callBack);
    };
  }, []);

  return (
    <Layout ref={first} margin={margin}>
      <WrapperCircle opacity={animated ? 1 : 0}>
        <SubTitleSVG />
        <StyledIcon size={44} name={icon} opacity={animated ? 1 : 0} />
      </WrapperCircle>
      <WrapperCircleTablet opacity={animated ? 1 : 0}>
        <SubTitleSVGTablet />
        <StyledIcon size={40} name={icon} opacity={animated ? 1 : 0} />
      </WrapperCircleTablet>
      <WrapperCircleMobile opacity={animated ? 1 : 0}>
        <SubTitleSVGMobile />
        <StyledIcon size={34} name={icon} opacity={animated ? 1 : 0} />
      </WrapperCircleMobile>

      <StyledSVG
        id="about-vector"
        opacity={animated ? 1 : 0}
        top="13"
        left="90"
        translate={animated ? 0 : 100}
      />
      <StyledSVGMobile
        id="about-vector-mobile"
        opacity={animated ? 1 : 0}
        top="19"
        left="70"
        translate={animated ? 0 : 100}
      />
      <StyledSVGTablet
        id="about-vector-tablet"
        opacity={animated ? 1 : 0}
        top="8"
        left="83"
        translate={animated ? 0 : 100}
      />

      <Box>
        <SubTitle opacity={animated ? 1 : 0}>{t(title)}</SubTitle>
      </Box>

      <Message opacity={animated ? 1 : 0} width={width} height={height}>
        {message}
      </Message>
    </Layout>
  );
};

export default DescriptionItem;
const WrapperCircleMobile = styled.div`
  display: none;
  @media screen and (max-width: 833px) {
    margin-top: -56px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ opacity }) => opacity};
    transition: all linear 500ms;
  }
`;
const WrapperCircleTablet = styled.div`
  display: none;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ opacity }) => opacity};
    transition: all linear 500ms;
  }
`;
const WrapperCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ opacity }) => opacity};
  transition: all linear 500ms;
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

const Box = styled.div`
  position: absolute;
  top: 11px;
  left: 108px;
  height: 60px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 834px) {
    top: 7px;
    left: 125px;
    height: 53px;
  }
  @media screen and (min-width: 1300px) {
    top: 16px;
    left: 130px;
  }
`;

const SubTitle = styled.h3`
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 198px;
  height: 37px;
  opacity: ${({ opacity }) => opacity};

  transition: opacity 500ms linear 600ms;

  text-shadow: 1.34858px 2.69716px 5.39432px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 16px;
    width: 214px;
  }
  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 18px;
    width: 250px;
  }
`;

const Message = styled.div`
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 80px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  color: #1d1d1d;
  text-shadow: 1.4px 2.7px 5.39432px rgb(0 0 0 / 15%);
  opacity: ${({ opacity }) => opacity};
  transition: opacity 500ms linear 900ms;
  @media screen and (min-width: 834px) {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-left: 340px;
    margin-top: 0;
    width: ${({ width }) => width}px;
    color: #1d1d1d;
    text-shadow: 1.4px 2.7px 5.39432px rgb(0 0 0 / 15%);
    opacity: ${({ opacity }) => opacity};
    transition: opacity 500ms linear 900ms;
  }
`;

const Layout = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  margin-top: 42px;
  margin-left: ${({ margin }) => margin}px;
  @media screen and (max-width: 834px) {
    margin-top: 0;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 20px;
  top: 25px;
  translate: 0%;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 200ms linear 100ms;
  @media screen and (max-width: 834px) {
    position: absolute;
    left: 31px;
    top: 24px;
  }
  @media screen and (min-width: 834px) {
    position: absolute;
    left: 36px;
    top: 14px;
  }
  @media screen and (min-width: 1300px) {
    position: absolute;
    left: 40px;
    top: 19px;
  }
`;

const StyledSVGMobile = styled(SVG)`
  position: absolute;
  width: 254px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: all 800ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
  @media screen and (min-width: 834px) {
    display: none;
  }
`;
const StyledSVGTablet = styled(SVG)`
  position: absolute;
  width: 298px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: all 800ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
  @media screen and (max-width: 834px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const StyledSVG = styled(SVG)`
  @media screen and (max-width: 1300px) {
    display: none;
  }
  width: 342px;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  transform: translateX(${({ translate }) => translate}%);
  transition: all 800ms cubic-bezier(0.19, 0.49, 0.54, 0.95);
`;
