import { useState } from 'react';
// import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import { Model } from './models/model';
import s from './partsSpectrum.module.scss';
import { useTranslation } from 'react-i18next';
// import Icon from 'components/atoms/icon';
// import Modal from 'components/modal';
import Title from 'components/molecules/title/Title';

const source = {
  title: {
    desktop: {
      svg1: 'partsspectrum-titlevector2',
      svg2: 'partsspectrum-titlevector1',
      svg3: 'partsspectrum-titlevector3',
    },
    tablet: {
      svg1: 'parts-spectrum-title-tablet1',
      svg2: 'parts-spectrum-title-tablet2',
      svg3: 'parts-spectrum-title-tablet3',
    },
    mobile: {
      svg1: 'parts-spectrum-title-mobile1',
      svg2: 'parts-spectrum-title-mobile2',
      svg3: 'parts-spectrum-title-mobile3',
    },
  },
};

const PartsSpectrum = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={s.container} id="partsSpectrum">
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.desktop.svg1}
            svg2={source.title.desktop.svg2}
            svg3={source.title.desktop.svg3}
          >
            <h2 className={s.title}>{t('Parts spectrum')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.containerText}>
            <p className={s.text}>
              {t(
                'We fulfill your high demands to your satisfaction for prototypes, small and medium series, especially in the field of medical technology, electrical engineering, mechanical engineering as well as jewelry industry.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'The spectrum of parts ranges from a size of less than 1 mm up to a length of 1600 mm.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'Our range of services in the field of development, design and documentation is also available to you at any time.'
              )}
            </p>
            <p className={s.textAccent}>
              {t(
                'We are well equipped for just-in-time production as a fire department for your extraordinary requirements.'
              )}
            </p>

            {/* <div className={s.containerPlay}>
              <Modal
                id="Sbs5BjM4wgk"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              ></Modal>
              <button
                className={s.link}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <div className={s.border}></div>
                <div className={s.border1}></div>
                <div className={s.containerIconBack}>
                  <div className={s.containerIcon}>
                    <Icon size={10} name="play" className={s.icon} />
                  </div>
                </div>
              </button>

              <p className={s.textVideo}> {t('Watch the production video')}</p>
            </div> */}
          </div>
          {/* <Canvas
            style={{
              width: '700px',
              height: '700px',
              cursor: 'grab',
            }}
          >
            <ambientLight intensity={0.5} />
            <spotLight position={[20, 20, 20]} angle={0.3} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <pointLight position={[-50, 30, 30]} />
            <pointLight position={[-50, -30, 30]} />
            <Model />
            <Environment
              preset={'forest'}
              blur={{ value: 1, min: 1, max: 1 }}
            />
            <OrbitControls />
          </Canvas> */}
        </div>
      </div>
      <div className={s.containerMobile} id="partsSpectrum_m">
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.mobile.svg2}
            svg2={source.title.mobile.svg1}
            svg3={source.title.mobile.svg3}
            top1={12}
            top2={27}
          >
            <h2 className={s.title}>{t('Parts spectrum')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.containerText}>
            <p className={s.text}>
              {t(
                'We fulfill your high demands to your satisfaction for prototypes, small and medium series, especially in the field of medical technology, electrical engineering, mechanical engineering as well as jewelry industry.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'The spectrum of parts ranges from a size of less than 1 mm up to a length of 1600 mm.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'Our range of services in the field of development, design and documentation is also available to you at any time.'
              )}
            </p>
            <p className={s.textAccent}>
              {t(
                'We are well equipped for just-in-time production as a fire department for your extraordinary requirements.'
              )}
            </p>
            {/* <div className={s.containerPlay}>
              <Modal
                id="Sbs5BjM4wgk"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              ></Modal>
              <button
                className={s.link}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <div className={s.border}></div>
                <div className={s.border1}></div>
                <div className={s.containerIconBack}>
                  <div className={s.containerIcon}>
                    <Icon size={10} name="play" className={s.icon} />
                  </div>
                </div>
              </button>

              <p className={s.textVideo}> {t('Watch the production video')}</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className={s.containerTablet} id="partsSpectrum_t">
        <div className={s.containerWrapper}>
          <Title
            svg1={source.title.tablet.svg1}
            svg2={source.title.tablet.svg3}
            svg3={source.title.tablet.svg2}
            top2={36}
            top1={18}
          >
            <h2 className={s.title}>{t('Parts spectrum')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.containerText}>
            <p className={s.text}>
              {t(
                'We fulfill your high demands to your satisfaction for prototypes, small and medium series, especially in the field of medical technology, electrical engineering, mechanical engineering as well as jewelry industry.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'The spectrum of parts ranges from a size of less than 1 mm up to a length of 1600 mm.'
              )}
            </p>
            <p className={s.text}>
              {t(
                'Our range of services in the field of development, design and documentation is also available to you at any time.'
              )}
            </p>
            <p className={s.textAccent}>
              {t(
                'We are well equipped for just-in-time production as a fire department for your extraordinary requirements.'
              )}
            </p>
            {/* <div className={s.containerPlay}>
              <Modal
                id="Sbs5BjM4wgk"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              ></Modal>
              <button
                className={s.link}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <div className={s.border}></div>
                <div className={s.border1}></div>
                <div className={s.containerIconBack}>
                  <div className={s.containerIcon}>
                    <Icon size={10} name="play" className={s.icon} />
                  </div>
                </div>
              </button>

              <p className={s.textVideo}> {t('Watch the production video')}</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartsSpectrum;
