import React, { useState } from 'react';
import s from './tabs.module.scss';
import Icon from 'components/atoms/icon';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal';

export const Tab = ({ title, imageAlt, items, youTubeId, image, imgWebp }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={s.container}>
      <div className={s.containerLeft}>
        <picture>
          <source srcSet={imgWebp} type="image/webp" />
          <img className={s.img} src={image} alt={imageAlt} />
        </picture>
      </div>
      <div className={s.containerRight}>
        <h2 className={items.length <= 3 ? s.title : s.titleAccent}>
          {t(title)}
        </h2>

        {items.map(item => (
          <div className={s.containerImg} key={item.description}>
            <div className={s.containerSmallIcon}>
              <Icon size={35} name={item.icon} className={s.iconSmall} />
            </div>
            <p className={s.descriptionSmallImg}>{t(item.description)}</p>
          </div>
        ))}

        {youTubeId && (
          <div className={s.containerPlay}>
            <Modal id={youTubeId} setIsOpen={setIsOpen} isOpen={isOpen}></Modal>
            <button
              className={s.link}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <div className={s.border}></div>
              <div className={s.border1}></div>
              <div className={s.containerIconBack}>
                <div className={s.containerIcon}>
                  <Icon size={10} name="play" className={s.icon} />
                </div>
              </div>
              <p className={s.descriptionSmallImg}>{t('View workflow')}</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
