import styled from '@emotion/styled';
import { files } from './source';

const prefix = 'img';

const ids = files.allIds.reduce((res, id) => ({ ...res, [id]: id }), {});

function SVG({
  id,
  isHidden,
  children,
  height: initialHeight,
  width: initialWidth,
  className,
}) {
 
  if (isHidden) {
    return <HiddenSVG>{children}</HiddenSVG>;
  }

  let file = files.byId[id];

  if (!file) return null;

  let width = initialWidth || file.width || null;
  let height = initialHeight || file.height || null;
  let viewBox = file.viewBox
    ? file.viewBox.join(' ')
    : '0 0 ' + width + ' ' + height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMin"
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      <use xlinkHref={'#' + prefix + '-' + id} />
    </svg>
  );
}

function SVGSource(props) {
  return (
    <SVG isHidden={true}>
      <defs>
        {files.allIds.reduce((defs, fileId, fileIndex) => {
          const file = files.byId[fileId];
          return defs.concat(
            <g
              key={fileIndex}
              id={prefix + '-' + fileId}
              dangerouslySetInnerHTML={{ __html: file.data }}
            ></g>
          );
        }, [])}
      </defs>
    </SVG>
  );
}

export { SVG, SVGSource, ids, files };

const HiddenSVG = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
`;
