import react, { useState } from 'react';
import s from './machinery.module.scss';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Tab } from './tabs/tabs';
import Icon from 'components/atoms/icon';
import LaserCutting from 'components/laserCutting';
import Title from 'components/molecules/title/Title';
import Marker from 'components/atoms/marker/Marker';
import img08 from '../../images/machinery/machinery.svg';
import img12 from '../../images/machinery/machinery.svg';
import img15 from '../../images/machinery/machinery.svg';
import img18 from '../../images/machinery/machinery.svg';
import img22 from '../../images/machinery/machinery.svg';
// import img08Webp from '../../images/machinery/img08.webp';
// import img12Webp from '../../images/machinery/img12.webp';
// import img15Webp from '../../images/machinery/img15.webp';
// import img18Webp from '../../images/machinery/img18.webp';
// import img22Webp from '../../images/machinery/img22.webp';
import { MobileTab } from './mobileTab/MobileTab';
import { Carousel } from 'react-responsive-carousel';

const source = {
  title: {
    desktop: {
      svg1: 'turnedparts-titlevector2',
      svg2: 'turnedparts-titlevector1',
      svg3: 'turnedparts-titlevector3',
    },
    tablet: {
      svg1: 'machinery-tablet-titlevector3',
      svg2: 'machinery-tablet-titlevector1',
      svg3: 'machinery-tablet-titlevector2',
    },
    mobile: {
      svg1: 'machinery-mobile-titlevector3',
      svg2: 'machinery-mobile-titlevector1',
      svg3: 'machinery-mobile-titlevector2',
    },
  },
};

const data = [
  '5 Machines FZ 08 S with 4th axis',
  '1 Machine FZ 12 S with 4th axis',
  '1 Machine FZ 12 W Magnum with 4th axis',
  '3 Machines FZ 15 Five Axis',
  '2 Machines FZ 18 S with 4th and 5th axis',
  '1 Machine FZ 18 L 1600 with 4th axis',
  '1 Machine FZ 18 Five Axis',
  '1 Machine FZ 22 S with 4th and 5th axis',
  '1 Machine Mill 800',
];
const data1 = ['2 machines FZ 08 with swivel head and bar magazine'];
const data2 = [
  '1 “Swift” video measuring system from Vision Engineering',
  '1 Zeiss 3D coordinate measuring machine Prismo ST',
];

const tabs = [
  {
    name: 'Series 08',
    image: img08,
    // imgWebp: img08Webp,
    imageAlt: 'series08',
    title: 'Ideal for task solving in:',
    items: [
      { icon: 'medicine', description: 'Medical' },
      { icon: 'settings', description: 'Mechanical Engineering' },
      { icon: 'car', description: 'Jewellery industry' },
    ],
    //youTubeId: 'FBQsAIkpG54',
  },
  {
    name: 'Series 12',
    image: img12,
    // imgWebp: img12Webp,
    imageAlt: 'series12',
    title: 'Ideal for task solving in:',
    items: [
      { icon: 'medicine', description: 'Medical' },
      { icon: 'settings', description: 'Mechanical Engineering' },
      { icon: 'car', description: 'Jewellery industry' },
    ],
    //youTubeId: '5gEYyWjZ8sI',
  },
  {
    name: 'Series 15',
    image: img15,
    // imgWebp: img15Webp,
    imageAlt: 'series15',
    title: 'Ideal for task solving in:',
    items: [
      { icon: 'medicine', description: 'Medical' },
      { icon: 'settings', description: 'Mechanical Engineering' },
      { icon: 'airplane', description: 'Aerospace' },
      { icon: 'car', description: 'Jewellery industry' },
    ],
    //youTubeId: 'VuHXwx7aUkA',
  },
  {
    name: 'Series 18',
    image: img18,
    // imgWebp: img18Webp,
    imageAlt: 'series18',
    title: 'Ideal for task solving in:',
    items: [
      { icon: 'medicine', description: 'Medical' },
      { icon: 'settings', description: 'Mechanical Engineering' },
      { icon: 'airplane', description: 'Aerospace' },
      { icon: 'car', description: 'Jewellery industry' },
    ],
    //youTubeId: '7O1_ByCwb9M',
  },
  {
    name: 'Series 22',
    image: img22,
    // imgWebp: img22Webp,
    imageAlt: 'series22',
    title: 'Ideal for task solving in:',
    items: [
      { icon: 'settings', description: 'Mechanical Engineering' },
      { icon: 'airplane', description: 'Aerospace' },
      // { icon: 'watch', description: 'Precision Technology' },
    ],
    //youTubeId: 'FBQsAIkpG54',
  },
];

const carouselArrows = (name, styles) => (clickHandler, hasPrev) => {
  return (
    <>
      {hasPrev && (
        <div className={styles} onClick={clickHandler}>
          <Icon name={name} size={44} className={s.arrow} />
        </div>
      )}
    </>
  );
};

const Machinery = () => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <>
      <div
        className={i18n.language === 'de' ? s.mainContainerDe : s.mainContainer}
      >
        <LaserCutting />
        <div className={s.wrapper}>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.desktop.svg1}
              svg2={source.title.desktop.svg2}
              svg3={source.title.desktop.svg3}
              top2={58}
              top1={31}
              shift={12}
            >
              <h2 className={s.title}>{t('Machinery')}</h2>
            </Title>
          </div>

          <div className={s.link} id="machinery"></div>
          <div className={s.wrapperBlur}>
            <div className={s.containerLeft}>
              <p className={s.description}>
                {t(
                  'For your complex workpieces and demanding machining, we have the following at our disposal:'
                )}
              </p>
              <div className={s.line}></div>
              <ul className={s.list}>
                {data.map((item, i) => (
                  <div className={s.containerItem} key={item}>
                    <Marker ind={i} />
                    <li className={s.item}>{t(item)}</li>
                  </div>
                ))}
              </ul>
              <div className={s.containerLeftAccent}>
                <p className={s.description}>
                  {t(
                    'FOR MEDIUM AND LARGE SERIES WE HAVE AT OUR DISPOSAL AMONG OTHERS:'
                  )}
                </p>
                <div className={s.line}></div>
                <ul className={s.list}>
                  {data1.map((item, i) => (
                    <div className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <li className={s.itemAccent}>{t(item)}</li>
                    </div>
                  ))}
                </ul>
              </div>
              <p
                className={
                  i18n.language === 'de'
                    ? s.descriptionAccent
                    : s.descriptionAccentEn
                }
              >
                {t(
                  'We are also happy to take over surface treatment such as sandblasting and vibratory finishing '
                )}
              </p>
              <div className={i18n.language === 'de' ? s.lineNew : s.lineNewEn}>
                {' '}
              </div>
            </div>
            <div className={s.containerRight}>
              <div className={s.routes}>
                <div
                  className={
                    i18n.language === 'de' ? s.lineAccent : s.lineAccentEn
                  }
                >
                  {' '}
                </div>
                <ul className={s.listButton}>
                  {tabs.map((tab, i) => (
                    <li key={tab.name} className={s.itemButton}>
                      <button
                        className={`${s.button} ${s['button' + String(i)]} ${
                          selectedTab === i ? s.buttonActive : ''
                        }`}
                        onClick={() => setSelectedTab(i)}
                      >
                        {t(tab.name)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              <Tab
                title={tabs[selectedTab].title}
                image={tabs[selectedTab].image}
                imgWebp={tabs[selectedTab].imgWebp}
                imageAlt={tabs[selectedTab].imageAlt}
                items={tabs[selectedTab].items}
                youTubeId={tabs[selectedTab].youTubeId}
              />
              <p className={s.descriptionBottom}>
                {t(
                  'IN ADDITION TO THE STANDARD MEASURING EQUIPMENT, WE GUARANTEE THE DIMENSIONAL ACCURACY OF YOUR PRODUCTS WITH:'
                )}
              </p>
              <div className={s.line}> </div>
              <ul className={s.list}>
                {data2.map((item, i) => (
                  <div className={s.containerItem} key={item}>
                    <Marker ind={i} />
                    <li className={s.item}>{t(item)}</li>
                  </div>
                ))}
              </ul>
              <p className={s.descriptionAccentText}>
                {t(
                  'Metal and plastic processing is carried out by our modern CNC machining centers of the Chiron company.'
                )}
              </p>
              <div className={s.line}> </div>
            </div>
          </div>
        </div>
        <div className={s.wrapperMobile}>
          <div
            className={
              i18n.language === 'en' ? s.containerWrapper : s.containerWrapperDe
            }
          >
            <Title
              svg1={source.title.mobile.svg1}
              svg2={source.title.mobile.svg2}
              svg3={source.title.mobile.svg3}
              top1={17}
              top2={33}
              left1={38}
            >
              <h2 className={s.title}>{t('Machinery')}</h2>
            </Title>
          </div>

          <div className={s.linkMobile} id="machinery_m"></div>
          <div className={s.wrapperBlur}>
            <div className={s.containerLeft}>
              <Icon
                name="svg1-title"
                width={121}
                height={66}
                className={s.iconTitle}
              />
              <h2 className={s.description}>
                {t(
                  'For your complex workpieces and demanding machining, we have the following at our disposal:'
                )}
              </h2>
              <div className={s.line}></div>
              <ul className={s.list}>
                {data.map((item, i) => (
                  <div className={s.containerItem} key={item}>
                    <Marker ind={i} />
                    <li className={s.item}>{t(item)}</li>
                  </div>
                ))}
              </ul>
              <div className={s.containerLeftAccent}>
                <Icon
                  name="svg1-title"
                  width={121}
                  height={66}
                  className={s.iconTitleDescription}
                />
                <p className={s.description}>
                  {t(
                    'FOR MEDIUM AND LARGE SERIES WE HAVE AT OUR DISPOSAL AMONG OTHERS:'
                  )}
                </p>
                <div className={s.line}></div>
                <ul className={s.list}>
                  {data1.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.itemAccent}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <p className={s.descriptionAccent}>
                {t(
                  'We are also happy to take over surface treatment such as sandblasting and vibratory finishing '
                )}
              </p>
              <div className={i18n.language === 'de' ? s.lineNew : s.lineNewEn}>
                {' '}
              </div>
            </div>
            <div className={s.containerRight}>
              <Icon
                name="svg1-title"
                width={121}
                height={66}
                className={s.iconTitleAccent}
              />
              <p className={s.descriptionBottom}>
                {t(
                  'IN ADDITION TO THE STANDARD MEASURING EQUIPMENT, WE GUARANTEE THE DIMENSIONAL ACCURACY OF YOUR PRODUCTS WITH:'
                )}
              </p>
              <div className={s.line}> </div>
              <ul className={s.list}>
                {data2.map((item, i) => (
                  <li className={s.containerItemAccentModal} key={item}>
                    <div className={s.containerMarkerAccent}>
                      <Marker ind={i} />
                    </div>
                    <div className={s.itemAccent}>{t(item)}</div>
                  </li>
                ))}
              </ul>

              <div className={s.line}> </div>

              <Container className="wrapper">
                <Carousel
                  swipeable={false}
                  emulateTouch={true}
                  showStatus={false}
                  showThumbs={false}
                  renderArrowPrev={(name, styles) =>
                    carouselArrows('left', s.leftArrowWrapper)(name, styles)
                  }
                  renderArrowNext={(name, styles) =>
                    carouselArrows('right', s.rightArrowWrapper)(name, styles)
                  }
                  renderIndicator={() => <></>}
                  onChange={e => setCurrentSlide(e)}
                >
                  {tabs.map(item => (
                    <div key={item.name}>
                      <MobileTab
                        tabName={t(item.name)}
                        title={item.title}
                        image={item.image}
                        imgWebp={item.imgWebp}
                        imageAlt={item.imageAlt}
                        items={item.items}
                      />
                    </div>
                  ))}
                </Carousel>
              </Container>
              <p className={s.descriptionAccentText}>
                {t(
                  'Metal and plastic processing is carried out by our modern CNC machining centers of the Chiron company.'
                )}
              </p>
              <div className={s.line}> </div>
            </div>
          </div>
        </div>
        <div className={s.wrapperTablet}>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.tablet.svg1}
              svg2={source.title.tablet.svg2}
              svg3={source.title.tablet.svg3}
              left1={58}
              left3={0}
              top2={50}
              left2={15}
            >
              <h2 className={s.title}>{t('Machinery')}</h2>
            </Title>
          </div>

          <div className={s.link} id="machinery_t"></div>
          <div className={s.wrapperBlur}>
            <div className={s.containerTabletWrapper}>
              <div className={s.containerLeft}>
                <Icon
                  name="svg1-title"
                  width={121}
                  height={66}
                  className={s.iconTitle}
                />
                <p className={s.description}>
                  {t(
                    'For your complex workpieces and demanding machining, we have the following at our disposal:'
                  )}
                </p>
                <div className={s.line}></div>
                <ul className={s.list}>
                  {data.map((item, i) => (
                    <div className={s.containerItem} key={item}>
                      <Marker ind={i} />
                      <li className={s.item}>{t(item)}</li>
                    </div>
                  ))}
                </ul>
                <Icon
                  name="svg1-title"
                  width={121}
                  height={66}
                  className={s.iconTitleDescription}
                />
                <p className={s.descriptionAccent}>
                  {t(
                    'We are also happy to take over surface treatment such as sandblasting and vibratory finishing '
                  )}
                </p>
              </div>
              <div className={s.containerRight}>
                <div className={s.containerLeftAccent}>
                  <p className={s.description}>
                    {t(
                      'FOR MEDIUM AND LARGE SERIES WE HAVE AT OUR DISPOSAL AMONG OTHERS:'
                    )}
                  </p>
                  <div
                    className={
                      i18n.language === 'de' ? s.lineTablet : s.lineTabletEn
                    }
                  ></div>
                  <ul className={s.list}>
                    {data1.map((item, i) => (
                      <div className={s.containerItemAccent} key={item}>
                        <div className={s.containerMarker}>
                          <Marker ind={i} />
                        </div>
                        <li className={s.itemAccent}>{t(item)}</li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className={s.containerTablet1}>
              <div className={s.lineTablet1}> </div>
              <Icon
                name="svg1-title"
                width={121}
                height={66}
                className={s.iconTitleAccent}
              />
              <p className={s.description}>
                {t(
                  'IN ADDITION TO THE STANDARD MEASURING EQUIPMENT, WE GUARANTEE THE DIMENSIONAL ACCURACY OF YOUR PRODUCTS WITH:'
                )}
              </p>
              <div className={s.line}> </div>
              <ul className={s.list}>
                {data2.map((item, i) => (
                  <div className={s.containerItem} key={item}>
                    <Marker ind={i} />
                    <li className={s.item}>{t(item)}</li>
                  </div>
                ))}
              </ul>
            </div>
            <div className={s.routes}>
              <div
                className={
                  i18n.language === 'de' ? s.lineAccent : s.lineAccentEn
                }
              >
                {' '}
              </div>
              <ul className={s.listButton}>
                {tabs.map((tab, i) => (
                  <li key={tab.name} className={s.itemButton}>
                    <button
                      className={`${s.button} ${s['button' + String(i)]} ${
                        selectedTab === i ? s.buttonActive : ''
                      }`}
                      onClick={() => setSelectedTab(i)}
                    >
                      {t(tab.name)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <Tab
              title={tabs[selectedTab].title}
              image={tabs[selectedTab].image}
              imageAlt={tabs[selectedTab].imageAlt}
              items={tabs[selectedTab].items}
              youTubeId={tabs[selectedTab].youTubeId}
            />
            <p className={s.descriptionAccentText}>
              {t(
                'Metal and plastic processing is carried out by our modern CNC machining centers of the Chiron company.'
              )}
            </p>
            <div className={s.line}> </div>
          </div>
        </div>
        <div className={s.container}></div>
        <div className={s.containerMobile}></div>
        <div className={s.containerTablet}></div>
      </div>
    </>
  );
};

export default Machinery;

const Container = styled.div`
  & .thumbs-wrapper.axis-vertical {
    margin: 0;
  }
`;
