import React from 'react';
import Icon from '../icon';

const colors = [
  '#771D94',
  '#691D90',
  '#5B1E8C',
  '#4D1E87',
  '#401F83',
  '#331F7F',
  '#261F7A',
  '#192076',
  '#192076',
];

const Marker = ({ ind, size = 10, top = 0 }) => (
  <Icon
    name="marker"
    size={size}
    style={{ fill: colors[ind], marginTop: top ? `${top}px` : '0' }}
  ></Icon>
);

export default Marker;
