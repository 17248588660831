import react, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { api } from 'api/client';
import { useTranslation } from 'react-i18next';
import Title from 'components/molecules/title/Title';
import { ReactComponent as LeftTopSVG } from '../../images/orderForm/leftTop.svg';
import { ReactComponent as LeftBottomSVG } from '../../images/orderForm/leftBottom.svg';
import { ReactComponent as RightTopSVG } from '../../images/orderForm/rightTop.svg';
import { ReactComponent as RightBottomSVG } from '../../images/orderForm/rightBottom.svg';
import { useForm, Controller } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import s from './orderForm.module.scss';
import Icon from 'components/atoms/icon';
import Footer from 'components/footer';
import { useNavigate, Link } from 'react-router-dom';
import gif from '../../images/loading.gif';
import { useLocation } from 'react-router-dom';
import { FileInput } from 'components/molecules/fileInput/FileInput';
import { CountryInput } from 'components/molecules/countryInput/CountryInput';
import { QuantitySelector } from 'components/molecules/quantitySelector/QuantitySelector';
import { MaterialSelect } from 'components/molecules/materialSelect/MaterialSelect';
import { DatePicker } from 'components/molecules/datePicker/DatePicker';

const FIELDS = {
  file1: 'fileInput',
  file2: 'file2Input',
  quantity: 'quantity',
  material: 'material',
  info: 'info',
  deliveryTerms: 'deliveryTerms',
  deliveryDate: 'deliveryDate',
  companyName: 'companyName',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  zipCode: 'zipCode',
  city: 'city',
  address: 'address',
  country: 'country',
};

const OrderForm = ({
  setIsActiveCheckbox,
  isActiveCheckbox,
  setModalVisibleForm,
}) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();
  const location = useLocation(useLocation);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [uploadedFile1Name, setUploadedFile1Name] = useState('');
  const [uploadedFile2Name, setUploadedFile2Name] = useState('');

  const watchAllFields = watch();

  useEffect(() => {
    const suggestedDate = dayjs(Date.now() + 4838400000).format('YYYY/MM/DD');
    const items = JSON.parse(localStorage.getItem('form'));
    if (items) {
      if (dayjs(items.deliveryDate).isBefore(dayjs(suggestedDate))) {
        items.deliveryDate = '';
      }
      items && Object.keys(items).forEach(key => setValue(key, items[key]));
    }
    if (!getValues(FIELDS.deliveryDate)) {
      setValue(FIELDS.deliveryDate, suggestedDate);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(watchAllFields).length) {
      const data = { ...watchAllFields, fileInput: [], file2Input: [] };
      localStorage.setItem('form', JSON.stringify(data));
    }
  }, [watchAllFields]);

  useEffect(() => {
    window.scrollTo({
      top: '0px',
      behavior: 'smooth',
    });
  }, [location]);

  const { errors } = formState;

  const onSubmit = data => {
    var formData = new FormData();
    const { fileInput, file2Input, ...fields } = data;

    fileInput && fileInput[0] && formData.append('file1', fileInput[0]);
    formData.append('file2', data[FIELDS.file2][0]);

    Object.keys(fields).forEach(
      id => data[id] && formData.append(FIELDS[id], data[id])
    );

    setIsUploading(true);
    setDataUploaded(false);
    setUploadingError(false);

    api
      .post('api/fileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        reset();
        localStorage.removeItem('form');
        navigate('/');
        setIsUploading(false);
        setDataUploaded(true);
        setModalVisibleForm(true);
      })
      .catch(() => {
        setIsUploading(false);
        setUploadingError(true);
        alert('something went wrong');
      });

    // for (var [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
  };

  const resetFile1 = () => {
    setUploadedFile1Name('');
    setValue(FIELDS.file1, null);
  };

  const resetFile2 = () => {
    setUploadedFile2Name('');
    setValue(FIELDS.file2, null);
  };

  const checkError = name => (errors[name] ? { borderColor: 'red' } : {});

  return (
    <div className={s.container}>
      {isUploading && (
        <div className={s.backdrop}>
          <img alt="gif" src={gif} className={s.gif}></img>
        </div>
      )}
      <LeftTopSVG className={s.leftTopSvg} />
      <LeftBottomSVG className={s.leftBottomSvg} />
      <RightTopSVG className={s.rightTopSvg} />
      <RightBottomSVG className={s.rightBottomSvg} />

      <form id="form" className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.wrapperContainer}>
          <Title
            svg1="ordered-vector2"
            svg2="ordered-vector"
            svg3="ordered-vector3"
            animate={true}
          >
            <h2 className={s.title}>{t('Get quote')}</h2>
          </Title>
        </div>

        <div className={s.titleContainer}>
          <h3 className={s.secondTitle}>
            {t('Please provide us with technical drawings and 3D data files')}
          </h3>
          <h3 className={s.secondTitle}>
            {t('Fields with')}{' '}
            <span className={s.containerAccentTextPink}> * </span>{' '}
            {t('must be filled in')}
          </h3>
        </div>

        <div className={s.containerFile}>
          <div>
            <div className={s.rightContainer}>
              <div className={s.containerRightUploadFile}>
                <p className={s.text}>
                  {t('PDF drawing')} <span className={s.textAccentSpan}>*</span>
                </p>

                <FileInput
                  required
                  id={FIELDS.file2}
                  register={register}
                  error={errors[FIELDS.file2]}
                  uploadedFileName={uploadedFile2Name}
                  setUploadedFileName={setUploadedFile2Name}
                  reset={resetFile2}
                  accept=".pdf"
                />
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\d+$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <QuantitySelector
                      onChange={onChange}
                      value={value}
                      error={errors[FIELDS.quantity]}
                    />
                  )}
                  name={FIELDS.quantity}
                />
                <MaterialSelect hidden />
              </div>
            </div>
          </div>
          <div className={s.leftContainer}>
            <div className={s.containerLeftUploadFile}>
              <p className={s.text}>
                {t('Upload 3D model (.stp, .ste, .step and .stl)')}
              </p>

              <FileInput
                id={FIELDS.file1}
                register={register}
                uploadedFileName={uploadedFile1Name}
                setUploadedFileName={setUploadedFile1Name}
                reset={resetFile1}
                accept=".stp,.ste,.step,.stl"
              />
            </div>
          </div>
        </div>

        <div className={s.wrapperContainer}>
          <Title
            svg1="ordered-vector21"
            svg2="ordered-vector22"
            svg3="ordered-vector23"
            top1={18}
            top2={26}
          >
            <h3 className={s.subTitle}>{t('Additional information')}</h3>
          </Title>
        </div>
        <p className={s.text}>
          {t('Please write an additional information about your parts')}
        </p>
        <textarea className={s.textArea} {...register(FIELDS.info)} />
        <div className={s.wrapperContainer}>
          <Title
            svg1="ordered-vector32"
            svg2="ordered-vector33"
            svg3="ordered-vector31"
            top1={16}
          >
            <h3 className={s.subTitle}>
              {t('Information about the delivery terms')}
            </h3>
          </Title>
        </div>

        <div className={s.contaionerDeliveryTerms}>
          <div className={s.leftContainer}>
            {/* <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomRadioButton onChange={onChange} value={value} />
                )}
                name={FIELDS.deliveryTerms}
              /> */}
            <p className={s.wrapperAccentText}>
              {t(
                'Usually, the average delivery time is of approx. 8 weeks and planned from the order date.'
              )}
            </p>
            <p className={s.wrapperAccentTextBottom}>
              {t(
                'Special conditions for delivery out of the specified delivery term are possible on request at any time.'
              )}
            </p>
          </div>
          <div className={s.rightContainer}>
            <p className={s.containerAccentText}>
              {t('Requested delivery date:')}
            </p>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker onChange={onChange} value={value} />
              )}
              name={FIELDS.deliveryDate}
            />
          </div>
        </div>

        <div className={s.containerAccent}>
          <div className={s.containerDelivery}>
            <div className={s.containerContactsDetails}>
              <div className={s.wrapperContainer}>
                <Title
                  svg1="ordered-vector42"
                  svg2="ordered-vector43"
                  svg3="ordered-vector41"
                >
                  <h3 className={s.subTitle}>{t('Your contact details')}</h3>
                </Title>
              </div>

              <div className={s.containerInputWrapper}>
                <div>
                  <p className={s.containerAccentTextInput}>
                    {' '}
                    {t('Company name')}{' '}
                    <span className={s.containerAccentTextPink}>*</span>
                  </p>
                  <input
                    autoComplete="off"
                    className={s.inputText}
                    type="text"
                    style={checkError(FIELDS.companyName)}
                    {...register(FIELDS.companyName, {
                      required: true,
                    })}
                  />
                </div>

                <div>
                  <p className={s.containerAccentTextInput}>
                    {t('First name')}{' '}
                  </p>
                  <input
                    autoComplete="off"
                    className={s.inputText}
                    type="text"
                    {...register(FIELDS.firstName)}
                  />
                </div>
              </div>
            </div>
            <div className={s.containerInputWrapper}>
              <div>
                <p className={s.containerAccentTextInput}>
                  {t('Email address')}{' '}
                  <span className={s.containerAccentTextPink}>*</span>
                </p>
                <input
                  autoComplete="off"
                  className={s.inputText}
                  type="email"
                  placeholder={t(
                    'Please write your email address to which we should send an offer.'
                  )}
                  style={checkError(FIELDS.email)}
                  {...register(FIELDS.email, {
                    required: true,
                  })}
                />
              </div>
              <div className={s.smallContainerRight}>
                <p className={s.containerAccentTextInput}>
                  {t('Last name')}{' '}
                  <span className={s.containerAccentTextPink}>*</span>
                </p>
                <input
                  autoComplete="off"
                  className={s.inputText}
                  type="text"
                  style={checkError(FIELDS.lastName)}
                  {...register(FIELDS.lastName, {
                    required: true,
                  })}
                />
              </div>
            </div>
          </div>

          <div
            className={`${s.containerEmail} ${
              errors[FIELDS.phone] && s.containerEmailError
            }`}
          >
            <p className={s.containerAccentTextInput}>
              {t('Phone number')}{' '}
              <span className={s.containerAccentTextPink}>*</span>
            </p>
            <PhoneInputWithCountry
              autoComplete="off"
              className={s.inputText}
              name={FIELDS.phone}
              placeholder={t('Phone number')}
              control={control}
              rules={{ required: true }}
              error={errors[FIELDS.phone]}
            />
          </div>

          <div className={s.containerInputWrapper}>
            <div className={s.smallContainerLeft}>
              <p className={s.containerAccentTextInput}>
                {t('Address')}{' '}
                <span className={s.containerAccentTextPink}>*</span>
              </p>
              <input
                autoComplete="off"
                className={s.inputText}
                type="text"
                placeholder={t('Zip code')}
                style={checkError(FIELDS.zipCode)}
                {...register(FIELDS.zipCode, {
                  required: true,
                })}
              />
            </div>
            <div className={s.smallContainerRight}>
              <input
                autoComplete="off"
                className={s.inputText}
                type="text"
                placeholder={t('City')}
                style={checkError(FIELDS.city)}
                {...register(FIELDS.city, {
                  required: true,
                })}
              />
            </div>
          </div>
          <div className={s.containerInputWrapper}>
            <div>
              <input
                autoComplete="off"
                className={s.inputText}
                placeholder={t('Street and number')}
                style={checkError(FIELDS.address)}
                {...register(FIELDS.address, {
                  required: true,
                })}
              />
            </div>

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <CountryInput
                  onChange={onChange}
                  value={value}
                  error={errors[FIELDS.country]}
                />
              )}
              name={FIELDS.country}
            />
          </div>

          <div className={s.containerTextConfirm}>
            {!isActiveCheckbox && (
              <Icon
                onClick={() => setIsActiveCheckbox(true)}
                name="checkbox"
                size={22}
              />
            )}
            {isActiveCheckbox && (
              <Icon
                name="checkbox-fill"
                size={22}
                onClick={() => setIsActiveCheckbox(false)}
              />
            )}
            <p className={s.textConfirm}>
              {t('I confirm that I have read and accepted the')}{' '}
              <Link to="/policy" className={s.textConfirmAccent}>
                {' '}
                {t('Privacy Policy*')}
              </Link>{' '}
              <span className={s.textConfirmDe}>{t('PrivacyDe')}</span>
            </p>
          </div>

          <div className={s.containerButton}>
            <button
              className={isActiveCheckbox ? s.button : s.buttonDisabled}
              type="submit"
              disabled={!isActiveCheckbox}
            >
              {t('get quote')}
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};
export default OrderForm;
