import { Outlet } from 'react-router-dom';
import { SVGSource } from '../atoms/svg/svg';
import Header from '../header';

const Layout = ({ isOpenMenu, setIsOpenMenu, setIsLogoLoaded }) => (
  <>
    <Header
      setIsOpenMenu={setIsOpenMenu}
      isOpenMenu={isOpenMenu}
      setIsLogoLoaded={setIsLogoLoaded}
    />
    <SVGSource />
    <Outlet />
  </>
);

export default Layout;
