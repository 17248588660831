import react from 'react';
import s from './laserCutting.module.scss';
import { useTranslation } from 'react-i18next';
import Title from 'components/molecules/title/Title';

import TitleHero from 'components/molecules/titleHero/titleHero';

const source = {
  title: {
    desktop: {
      svg1: 'laser-vector3',
      svg2: 'laser-vector2',
      svg3: 'laser-vector1',
    },
    tablet: {
      svg1: 'laser-tablet-vector3',
      svg2: 'laser-tablet-vector2',
      svg3: 'laser-tablet-vector1',
    },
    mobile: {
      svg1: 'laser-mobile-vector3',
      svg2: 'laser-mobile-vector2',
      svg3: 'laser-mobile-vector1',
    },
  },
};

const sourceTitle = {
  title: {
    desktop: {
      svg1: 'laser-titlevector11',
      svg2: 'laser-titlevector22',
      svg3: 'laser-titlevector33',
    },
    tablet: {
      svg1: 'laser-tablet-titlevector11',
      svg2: 'laser-tablet-titlevector22',
      svg3: 'laser-tablet-titlevector33',
    },
    mobile: {
      svg1: 'laser-mobile-titlevector11',
      svg2: 'laser-mobile-titlevector22',
      svg3: 'laser-mobile-titlevector33',
    },
  },
};
const LaserCutting = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.mainContainer}>
        <div className={s.container} id="laser">
          <div className={s.wrapperContainer}>
            <Title
              svg1={sourceTitle.title.desktop.svg1}
              svg2={sourceTitle.title.desktop.svg2}
              svg3={sourceTitle.title.desktop.svg3}
              top1={47}
              top2={31}
              left2={61}
              left3={23}
            >
              <h2 className={s.title}>{t('Laser Cutting')}</h2>
            </Title>
          </div>
          <div className={s.containerTitle}>
            <TitleHero
              svg1={source.title.desktop.svg2}
              svg2={source.title.desktop.svg1}
              svg3={source.title.desktop.svg3}
              top="0"
              left1="-400"
              top1="-5"
              top2="-25"
              left2="-397"
              left3="22"
              top3="-135"
            >
              <h2 className={s.title}>
                {t('Our CNC laser cutting and laser welding machining')}{' '}
              </h2>
            </TitleHero>
          </div>

          <div className={s.containerDescription}>
            <p className={s.description}>
              {t('be used for processing materials')}
            </p>
            <p className={s.description}>
              {t('with a thickness of 0.5 to 5 mm')}
            </p>
            <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
          </div>
        </div>
        <div className={s.containerTablet} id="laser_t">
          <div className={s.wrapperContainer}>
            <Title
              svg1={sourceTitle.title.tablet.svg1}
              svg2={sourceTitle.title.tablet.svg2}
              svg3={sourceTitle.title.tablet.svg3}
              top1={46}
              left1={38}
              top2={24}
              left2={72}
              left3={11}
            >
              <h2 className={s.title}>{t('Laser Cutting')}</h2>
            </Title>
          </div>
          <div className={s.containerTitle}>
            <TitleHero
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              top="-30"
              left1="-1040"
              top2="-40"
              left2="-1030"
              left3="-130"
              top3="-80"
            >
              <h2 className={s.title}>{t('Our CNC laser cutting')}</h2>
              <h2 className={s.title}>
                {t('and laser welding machining can')}
              </h2>
            </TitleHero>
          </div>

          <div className={s.containerDescription}>
            <p className={s.descriptionAccent}>
              {t('be used for processing materials')}
            </p>
            <p className={s.description}>
              {t('with a thickness of 0.5 to 5 mm')}
            </p>
            <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
          </div>
        </div>
        <div className={s.containerMobile} id="laser_m">
          <div className={s.wrapperContainer}>
            <Title
              svg1={sourceTitle.title.mobile.svg1}
              svg2={sourceTitle.title.mobile.svg2}
              svg3={sourceTitle.title.mobile.svg3}
              top1={35}
              left1={17}
              top2={16}
              left2={55}
              left3={20}
            >
              <h2 className={s.title}>{t('Laser Cutting')}</h2>
            </Title>
          </div>
          <div className={s.containerTitle}>
            <TitleHero
              svg1={source.title.mobile.svg2}
              svg2={source.title.mobile.svg1}
              svg3={source.title.mobile.svg3}
              top="-10"
              left1="-327"
              top2="-21"
              left2="-350"
              left3="-100"
              top3="-35"
            >
              <h2 className={s.title}>{t('Our CNC laser cutting')}</h2>
              <h2 className={s.title}>
                {t('and laser welding machining can')}
              </h2>
            </TitleHero>
          </div>
          <div className={s.containerDescription}>
            <p className={s.descriptionAccent}>
              {t('be used for processing materials')}
            </p>
            <p className={s.description}>
              {t('with a thickness of 0.5 to 5 mm')}
            </p>
            <p className={s.description}>{t('AND A LENGTH UP TO 800 MM')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaserCutting;
