import react from 'react';
import { useTranslation } from 'react-i18next';
import s from './materials.module.scss';
import Marker from 'components/atoms/marker/Marker';
import Title from 'components/molecules/title/Title';
import Icon from 'components/atoms/icon';

const data = [
  'Rust and acid resistant steels',
  'Spring hard steel',
  'Titanium',
  'Platinum',
  'Aluminum',
  'Brass',
  'Copper',
  'Plastic ( e.g. Teflon, PPSU, Radel, Delrin, Peek )',
  'Hard paper',
];
const source = {
  title: {
    desktop: {
      svg1: 'materials-titlevector2',
      svg2: 'materials-titlevector1',
      svg3: 'materials-titlevector3',
    },
    tablet: {
      svg1: 'materials-title-tablet1',
      svg2: 'materials-title-tablet2',
      svg3: 'materials-title-tablet3',
    },
    mobile: {
      svg1: 'materials-title-mobile2',
      svg2: 'materials-title-mobile1',
      svg3: 'materials-title-mobile3',
    },
  },
};
const Materials = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={s.container} id="materials">
        <div className={s.wrapperContainer}>
          <Title
            svg1={source.title.desktop.svg2}
            svg2={source.title.desktop.svg1}
            svg3={source.title.desktop.svg3}
            top1={56}
            top2={31}
          >
            <h2 className={s.title}>{t('Materials')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.wrapperAccent}>
            <p className={s.text}>
              {t(
                'YOUR PRODUCTS BENEFIT FROM OUR EXTENSIVE EXPERTISE IN MATERIALS.'
              )}
            </p>
            <p className={s.text}>{t('THE FOLLOWING IS A SMALL OVERVIEW:')}</p>
            <ul className={s.list}>
              {data.map((item, i) => (
                <div className={s.containerItem} key={item}>
                  <Marker ind={i} top={10} />
                  <li className={s.item}>{t(item)}</li>
                </div>
              ))}
            </ul>

            <p className={s.textAccent}>
              {t(
                'We would be happy to provide you with the qualified material certificate on request.'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={s.containerMobile} id="materials_m">
        <div className={s.wrapperContainer}>
          <Title
            svg1={source.title.mobile.svg1}
            svg2={source.title.mobile.svg3}
            svg3={source.title.mobile.svg2}
            top2={30}
            top1={16}
          >
            <h2 className={s.title}>{t('Materials')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.wrapperAccent}>
            <Icon
              name="svg2-title"
              width={158}
              height={85}
              className={s.iconTitle}
            />
            <p className={s.text}>
              {t(
                'YOUR PRODUCTS BENEFIT FROM OUR EXTENSIVE EXPERTISE IN MATERIALS.'
              )}
            </p>
            <p className={s.text}>{t('THE FOLLOWING IS A SMALL OVERVIEW:')}</p>
            <ul className={s.list}>
              {data.map((item, i) => (
                <div className={s.containerItem} key={item}>
                  <Marker ind={i} top={5} />
                  <li className={s.item} key={item}>
                    {t(item)}
                  </li>
                </div>
              ))}
            </ul>

            <p className={s.textAccent}>
              {t(
                'We would be happy to provide you with the qualified material certificate on request.'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={s.containerTablet} id="materials_t">
        <div className={s.wrapperContainer}>
          <Title
            svg1={source.title.tablet.svg3}
            svg2={source.title.tablet.svg2}
            svg3={source.title.tablet.svg1}
            top1={24}
            top2={40}
          >
            <h2 className={s.title}>{t('Materials')}</h2>
          </Title>
        </div>
        <div className={s.wrapper}>
          <div className={s.wrapperAccent}>
            <p className={s.text}>
              {t(
                'YOUR PRODUCTS BENEFIT FROM OUR EXTENSIVE EXPERTISE IN MATERIALS.'
              )}
            </p>
            <p className={s.text}>{t('THE FOLLOWING IS A SMALL OVERVIEW:')}</p>
            <ul className={s.list}>
              {data.map((item, i) => (
                <div className={s.containerItem} key={item}>
                  <Marker ind={i} top={5} />
                  <li className={s.item} key={item}>
                    {t(item)}
                  </li>
                </div>
              ))}
            </ul>

            <p className={s.textAccent}>
              {t(
                'We would be happy to provide you with the qualified material certificate on request.'
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Materials;
